import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from "../CourseImages/Logo.png";
import './Nav.css'
import { Link } from "react-router-dom";

const TeacherNav = ({ username, onLogout ,onCourseSelect}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSelectedCourse(null);
  };

  const handleCourseSelection = (course) => {
    setIsOpen2(!isOpen2);
    setSelectedCourse(course.Course_id);
    onCourseSelect(course.coursename);
  };
  useEffect(() => {  
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherTaughtCourses`)
      .then(response => { 
        setCourses(response.data.courseInfo);
        console.log(response)
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  }, []); 
    const [courses, setCourses] = useState([]);
  
  
  return (
    <div style={{ width: "100%" }}>
      <nav
        class="navbar navbar-expand-lg navbar-light "
        style={{ backgroundColor: "#edffff" }}
      >
        <div class="container-fluid" >
        <img src={Logo} alt="Logo" className='pageLogo' />
          <button
            style={{ marginLeft: "auto" }}
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse mx-3 Navbar-Right-Aligned"
            id="navbarSupportedContent"
            style={{marginLeft: "57%"}}
          >
            <ul class="navbar-nav" style={{ marginLeft: "10%" }}>
              <li class="nav-item active  ">
              <Link class="nav-link navLinks" to="/">Home
                <span class="sr-only">
                (current)
                </span>
              </Link>
              </li>
              <li className="dropdown-container">
              <a className="nav-link dropdown-toggle navLinks"  onClick={toggleDropdown}>
               My Courses
              </a>
              {/* <h1  style={{color:"black"}}>{isOpen ? 'Open' : 'Closed'}</h1>  */}

              {isOpen && (
                <li className="dropdown-menu_">
                  {courses.map((course) => (
                    <li key={course.Course_id}>
                      <a
                        className={`nav-link navLinks dropdown-item_ ${selectedCourse === course.Course_id ? 'active' : ''}`}
                        onClick={() => handleCourseSelection(course)}
                        style={{padding:"0"}}
                      >
                        {/* {course} */}
                        <span style={{width:"30vh",padding:"1vh"}} className="dropdown-toggle course-text">
                          {course.coursename}
                        </span>
                      </a>
                      {isOpen2 &&  selectedCourse === course.Course_id && (
                        <li className="nested-dropdown-menu_">
                          <Link to="/TeacherAttendClass"  style={{color:"#03b4c6",paddingLeft:"4vh"}}className=" dropdown-item_ ">Take Class</Link> <br/>
                          <Link to="#" style={{color:"#03b4c6" ,paddingLeft:"4vh"}} className="dropdown-item_ ">Students DashBoard</Link><br/>
                          <Link to="#" style={{color:"#03b4c6" ,paddingLeft:"4vh"}} className="dropdown-item_ ">Grade Projects</Link><br/>
                        </li>
                      )}
                    </li>
                  ))}
                </li>
              )}
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {username}
                  </a>
                  <div class="dropdown-menu user-profile-dropdown"  aria-labelledby="navbarDropdownMenuLink">
                    <Link class="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
                    <Link class="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
                    <Link class="dropdown-item UserDropdown" to="/TeacherEditProfile" >Edit Profile</Link>
                    
                  </div>
                </li>   

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );

};

export default TeacherNav;
