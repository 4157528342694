import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './EditLesson.css';

const EditLesson = (props) => {
  const [lesson, setLesson] = useState([
    {lessonname:'lesson', lessondescription:'desc', lessonno:'no', learningobjective:'obj', materialandpreparation:'mat', keyterms:'key'}
  ]);

  const Lesson_id = props.lessonid;

  const [allLessons, setAllLessons] = useState(null);

  useEffect(() => {
    fetchLesson();
  },[]);

  const fetchLesson = () => {
    axiosInstance.post('/getSpecificLesson', { Lesson_id })
      .then(response => {
        setAllLessons(response.data);
        const specificLesson = response.data.find(l => l.Lesson_id === Lesson_id);
        setLesson(specificLesson);
      })
      .catch(error => {
        console.log(error);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setLesson(prevLesson => ({
      ...prevLesson,
      [name]: value
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();

    // Make an API call to save the lesson changes
    axiosInstance.post('/saveLessonChanges', lesson)
      .then(response => {
        console.log('Lesson changes saved successfully');
      })
      .catch(error => {
        console.log('Error saving lesson changes:', error);
      });
  };

  if (!lesson) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-lesson">
      <h2>Edit Lesson</h2>
      <form>
        <div className="form-group">
          <label className="edit-lesson">Title:</label>
          <input type="text" className="edit-lesson"  required name="lessonname" value={lesson.lessonname} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="edit-lesson">Description:</label>
          <textarea className="edit-lesson" name="lessondescription"  required value={lesson.lessondescription} onChange={handleChange}></textarea>
        </div>
        <div className="form-group">
          <label className="edit-lesson">Lesson No:</label>
          <input type="text" className="edit-lesson" name="lessonno"  required value={lesson.lessonno} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="edit-lesson">Lesson Objective:</label>
          <input type="text" className="edit-lesson" name="lessonobjective"  required value={lesson.learningobjective} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="edit-lesson">Material and Preparation:</label>
          <input type="text" className="edit-lesson" name="materialandpreparation" required value={lesson.materialandpreparation} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="edit-lesson">Key Terms:</label>
          <input type="text" className="edit-lesson" name="keyterms" required value={lesson.keyterms} onChange={handleChange} />
        </div>
        <button className="edit-lesson" type="button" onClick={handleSave}>Save</button>
      </form>
    </div>
  );


};

export default EditLesson;
