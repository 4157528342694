import React from "react";
import { useState, useEffect ,useContext} from "react";
import { Link } from "react-router-dom";

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav' 
import GuestNav from '../Navbars/GuestNav';

import { UserContext } from '../UserContext';

import Footer from '../Footer'
import "./QuranCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./QuranCourseImages/Yassarnal.png";
import pic2 from "./QuranCourseImages/recitation.png";
import pic3 from "./QuranCourseImages/tafseerAlQuran.png";
import pic4 from "./QuranCourseImages/learnSuras.png";
import pic5 from "./QuranCourseImages/islamicKnowledge.png";
import pic6 from "./QuranCourseImages/learnSuras.png";
import TeacherNav from "../Navbars/TeacherNav";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';


const QuranCourseDetails = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
  return (
   
    <div  >
      
     {/* Navbar */}
     {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
      

    <div className="QuranPortalBackground">
       <section className="section">
         <p className="PageHead">Quran Portal</p>
         <div className="PageDesc">
           <p>
           Being a parent is a blessing! With this blessing, comes the greatest responsibility of shaping your child behavior and guide them to be a good mannered, kind, wise and noble Muslim. The best gift a parent can offer to their child is to guide them towards the obedience and recognition of Allah Al-Mighty and teach them Quran. As Narrated by `Uthman (رَضِیَ اللہُ عَنْہ), The Prophet (ﷺ) said, “The best among you (Muslims) are those who learn the Qur’an and teach it.” (Bukhari)
        Polymath-Kids offer online fun and engaging Quran Classes for kids aged 5 to 16 years with certified Quran Aalims and Aalimas.

           </p>
           <div className="btn-row d-flex btn-row-resp btn-row-resp1">

             {/* <div className="btn-row-resp"> */}
             <div style={{color:"#e2e872", width: "auto", height: "50px" }}>


               <a className="btn-row-resp button " href="#course">Individual Courses</a>
             </div>
             <div style={{ width: "auto", height: "50px" }}>


               <a className="btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>
             </div>
             
             <div style={{ width: "auto", height: "50px" }}>

               <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
             </div>
             <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


             </div>
             <div style={{ width: "auto", height: "50px" }}>
             <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
              

             </div>


        
           </div>
         </div>

       </section>
       <div className="overlay"></div>
     </div>
       {/* Intro text */}
    <div className="scratch-background">
      <section className="section">
        <div className="PageDesc">
          <p
            style={{
              color: "#8b0194",
              marginTop: "2vh",
              fontSize: "4vh",
              fontFamily: "sans serif",
            }}
          >
           Are you worried about having an AUTHENTIC and CERTIFIED Quran teacher for your child? But not sure how to find one? Polymath-Kids reviews and verifies the qualification and certifications of each Quran teacher before engaging them with the kids, to ensure best understanding of Quran Tajweed and Tafseer. Our teachers ensure that each child not only understand the content but also ponder upon it and make it a part of their life InshAllah.
          </p>
        </div>
      </section>
    </div>

{/* Individual courses */}
<div style={{ backgroundColor: '#d8f2fb' }} id="course">
       <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
       Quran Individual Courses 
       </p>

       <div className="container"  >

         <MDBRow>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Yassarnal Qur'an</h3>
               <div>
                     <img src={pic1} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Qur'an Recitation
                   </h3>
               <div>
                     <img src={pic2} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Tafseer-ul-Qur'an
                   </h3>
               <div>
                     <img src={pic3} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Learn Suras by Heart
                   </h3>
               <div>
                     <img src={pic4} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="/IslamicKnowledgeCourse">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Islamic Knowledge Course
                   </h3>
               <div>
                     <img src={pic5} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Hifz-ul-Qur'an
                   </h3>
               <div>
                     <img src={pic6} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
         
          
        
         </MDBRow>
       </div>


     </div>


 {/* Fee Structure */}
 <section className="section fee-structure my-3" id="fee">
        <div>
          <p className="PageHead" style={{ color: "#8b0194" }}>
            Fee Structure
          </p>
          <div>
            <p
              style={{
                color: "#8b0194",
                marginTop: "3vh",
                fontSize: "2em",
                fontFamily: "VanillaMilk",
              }}
            >
            Learn Qur'an privately from qualified Aalims and Aalimas
            </p>
          </div>
        </div>

        <div className="d-flex mx-4 feeTableContainer" style={{ marginLeft: "20vh" }}>
          <div className="columns" style={{ marginTop: "34vh" }}>
            <ul className="price">
              <li>
                <b> Class duration </b>
              </li>
             
            </ul>
          </div>
          <div className="columns">
            <ul className="price">
              <li className="header">Private 1-on-1 Class 5 Days - 20 min Lessons</li>

              <li className="grey">
              Our 1-on-1 classes for kids are suitable for children who want a full personalized and effective learning experience.
              </li>
              <li>20 minutes</li>
             
              <li className="grey">
                <a href="#" className="button">
                  Buy Course
                </a>
              </li>
            </ul>
          </div>
          <div className="columns">
            <ul className="price">
              <li className="header">Private 1-on-1 Class
                    5 Days - 40 min Lessons
              </li>

              <li className="grey">
              Our 1-on-1 classes for kids are suitable for children who want a full personalized and effective learning experience.
              </li>
              <li>40 minutes</li>
              <li className="grey">
                <a href="#" className="button">
                  Buy Course
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

     {/* Testimonals */}
     <div class="  background" id="testimonal">
       <div class="row" style={{ width: "100%" }}>
         <div class="col-lg-8 mx-auto">
           <h2 className="Testimonal_Heading">Testimonials</h2>
           <div id="myCarousel" class="carousel slide" data-ride="carousel">
             {/* <!-- Carousel indicators --> */}
             <ol class="carousel-indicators">
               <li
                 data-target="#myCarousel"
                 data-slide-to="0"
                 class="active"
               ></li>
               <li data-target="#myCarousel" data-slide-to="1"></li>
             </ol>
             {/* <!-- Wrapper for carousel items --> */}
             <div class="carousel-inner">
               <div class="carousel-item active">
                 <div class="img-box">
                   <div className=" img-box1"></div>
                 </div>
                 <p
                   class="testimonial"
                   style={{ color: "black", fontSize: "3vh" }}
                 >
                   I enjoyed the learning experience with EduTechSage-Abacus.
                   The teacher was very friendly. I highly recommend
                   EduTechSage-Abacus.
                 </p>
                 <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                   <b>Mujtaba Khawaja Sahaf</b>
                 </p>
               </div>

               <div class="carousel-item">
                 <div class="img-box">
                   <div className="img-box2 "></div>
                 </div>
                 <p
                   class="testimonial"
                   style={{ color: "black", fontSize: "3vh" }}
                 >
                   I feel so proud when my class fellows call me ’BRAINIAC ’,
                   because I can effortlessly do mental calculations much
                   faster and accurate than my class mates. Thank you
                   Polymath-Kids.
                 </p>
                 <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                   <b>Wahaab Omer</b>, Age 9 Years
                 </p>
               </div>
             </div>
             {/* <!-- Carousel controls --> */}
             <a
               class="carousel-control-prev"
               href="#myCarousel"
               data-slide="prev"
             >
               <i class="fa fa-angle-left"></i>
             </a>
             <a
               class="carousel-control-next"
               href="#myCarousel"
               data-slide="next"
             >
               <i class="fa fa-angle-right"></i>
             </a>
           </div>
         </div>
       </div>
     </div>

     {/* Faqs */}
     <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

       <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
         <MDBAccordion alwaysOpen initialActive={1} >
           <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
           <MDBAccordionItem collapseId={1} headerTitle="Q: When should a child start learning Quran?" >
             <strong>A: </strong>
             At Polymath-Kids, we have children as young as 5 years, who are engaged and are able to finish a task and sit for the entire class. But this may vary from one child to another. In our opinion, parents should never force the child. Therefore our Quran tutors try their best to teach kids in a friendly environment to keep them interested in learning.
            </MDBAccordionItem>
           <MDBAccordionItem collapseId={2} headerTitle="Q: Can kids learn Quran online">
             <strong>A: </strong>
             Absolutely Yes! Kids can easily learn Quran online. Technology offers unique solutions for teaching Quran online without any disruptions.  Rather it provides extra benefits such as learning with comfort from home, avoiding traffic and all other hassles involved. Currently we have online students learning to read Quran as young as 5 years old.
           </MDBAccordionItem>


           <MDBAccordionItem collapseId={3} headerTitle="Q: How long is each Quran session on Polymath-Kids Quran Portal?">
             <strong>A: </strong>
             Each session is 15 minutes long. Considering the attention span of young kids, 15 minutes of Quran lessons provides a good opportunity for students to learn with focus and zeal.
           </MDBAccordionItem>

           <MDBAccordionItem collapseId={3} headerTitle="Q: How often can kids take Quran Lesson on Polymath-Kids Quran Portal?">
             <strong>A: </strong>
             Polymath-Kids offers tw solutions for taking 1 on 1 Quran lesson with Aalim/ Aalima . Students can opt for 15 minutes class either 4 days per week or 5 days per week. The charges are slightly different and can be viewed in fee section.
           </MDBAccordionItem>

           <MDBAccordionItem collapseId={3} headerTitle="What can my child learn reading Quran along with Islamic knowledge?">
             <strong>A: </strong>
            
             Polymath-Kids offers a comprehensive Islamic Knowledge course for kids which provides in depth information about the following topics
            a.	Five pillars of Islam and their importance in daily life
            b.	Performing Wudu’ the right way and its significance
            c.	How to offer prayer with Do’s and Don’ts
            d.	Islamic stories to keep kids engaged
            e.	Masnoon Dua’s for Kids along with their significance
            f.	Manners in everyday life
            If a child wishes to learn about these important aspects of life along with learning to read Quran then they can enroll for the Islamic Knowledge course as well.  Their class will be of 30 minutes (comprising of 15 minutes Tajweed and Quran reading and 15 minutes dedicated for Islamic Knowledge). The fee has to be paid for both the courses to get enrolled in both of them.
           </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>

     </div>

{/* Footer */}
<Footer/>

 </div>
  )
}

export default QuranCourseDetails
