import React from 'react';
import { useState,useEffect } from 'react';
import Logo from '../CourseImages/Logo.png';
import './Nav.css';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import  instance  from '../ManageContent/managecontentforms/axiosInstance';

const GuestNav = ({ onLogin, onSignup }) => {
  const [popup, setPop] = useState(false);
  const [signup, setSignUp] = useState(false);

  const [countries, setCountries] = useState([]);
  const [fileError, setFileError] = useState('');
  
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [parentName, setParentName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [address, setAddress] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loginAfterSignup, setLoginAfterSignup] = useState(false);

  const [values,setValues]=useState({
    usertype:'Student',
    signupEmail:'',
    signupPassword:'',
    confirmPassword:'',
    firstName:'',
    lastName:'',
    gender:'',
    dateOfBirth:'',
    parentName:'',
    whatsappNumber:'',
    address:'',
    schoolCode:'',
    deactivated:0,
    selectedCountry:'',
    selectedFile:null,
})
const [loginValues,setLoginValues]=useState({
  loginEmail:'',
  loginPassword:''
})
 

//for Login Input handling
const handleLoginInput=(event)=>{
  setLoginValues(prev =>({...prev,[event.target.name]:[event.target.value]}))
}
//for signup Input handling
const handleInput=(event)=>{
    setValues(prev =>({...prev,[event.target.name]:[event.target.value]}))
}
useEffect(() => {
  if (loginAfterSignup) {
    handleLogin2();
  }
}, [loginAfterSignup]);

const handleSubmit = async (event) => {
 // console.log("In handleSubmit.")
    event.preventDefault();
    axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/register`,values)
    .then(res=>
        {
         // console.log(res);
          setSignUp(false);
          // console.log("res.User_id",res.User_id);
          const insertId = res.data.insertId; 
          
          const nestedValues={
            user_id:insertId,
            pointsearned:0,
            pointsredeemed:0
          }
          // fetch('registerInStudentTable', nestedValues)
          axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/registerInStudentTable`,nestedValues)
            .then((res) => {    
             
            })
            .catch((err) => console.log(err));
      
          toast.success('Successfully Signup', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          });
          
        })
    .catch((err)=>{
      toast.error('Signup UnSuccessfull!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
          setLoginAfterSignup(true);

    setLoginValues({
      loginEmail: values.signupEmail,
      loginPassword: values.signupPassword,
    });
    // if(loginAfterSignup === true)
    // {
    //   console.log(loginAfterSignup,loginValues)
    //   handleLogin(event);
    // }
     

    // console.log('GuestNav Email:', values.signupEmail);            
    // console.log('GuestNav Password:', values.signupPassword);
    // console.log('Confirm Password:', values.confirmPassword);
    // console.log('First Name:', values.firstName);
    // console.log('Last Name:', values.lastName);
    // console.log('Gender:', values.gender);
    // console.log('Date of Birth:', values.dateOfBirth);
    // console.log('Parent Name:', values.parentName);
    // console.log('Whatsapp Number:', values.whatsappNumber);
    // console.log('Address:', values.address);
    // console.log('School Code:', values.schoolCode);
    // console.log('Country:', values.selectedCountry);
    // console.log('Avatar:', values.selectedFile);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

      if (allowedExtensions.includes(fileType)) {
        setSelectedFile(file);
        setValues(prev =>({...prev,[event.target.name]:[event.target.files[0]]}));
        console.log("IN handlefile change",selectedFile);
        setFileError('');
      } else {
        setSelectedFile(null);
        setFileError('Please upload a .png, .jpeg, or .jpg file.');
      }
    }
  };
  axios.defaults.withCredentials=true;
  const navigate=useNavigate();
  const handleLogin2 = async () => {
    // event.preventDefault();
    var U_type;
    // console.log('loginValues:::',loginValues);
    await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/login`,loginValues)
    .then(res=>
        {
          if(res.data.Login)
          {
            U_type=res.data.usertype;
           
            setPop(false);
          
          }
          
        })
    .catch(err=>console.log("Error:",err))

    // console.log("U_type just before:",U_type)
    onLogin(U_type);

    // console.log('GuestNav Email:', email);
    // console.log('GuestNav Password:', password);
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    var U_type;
    // console.log('loginValues:::',loginValues);
    await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/login`,loginValues)
    .then(res=>
        {
            // console.log("res in login:",res)
          if(res.data.Login)
          {
            U_type=res.data.usertype;
            // console.log("U_type:",U_type)
            // console.log("res.data.usertype:",res.data.usertype)
            // U_type='student';
            setPop(false);
            toast.success('Successfully Login', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close the toast automatically after 3000 milliseconds (3 seconds)
            });
            
          }
          else if(!res.data.Login)
          { 
            toast.error('Credentials are incorrect or You might have been deactivated!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000, // Close the toast automatically after 3000 milliseconds (3 seconds)
              style: { zIndex: 10000 }, // Set the desired z-index value
            });
          } 
          setLoginValues({
            loginEmail: '',
            loginPassword: ''
          });
          
        })
    .catch(err=>console.log("Error:",err))

    // console.log("U_type just before:",U_type)
    onLogin(U_type);

    // console.log('GuestNav Email:', email);
    // console.log('GuestNav Password:', password);
  };
 

  const signUp = () => {
    setSignUp(!signup);
    setLoginValues({
      loginEmail: '',
      loginPassword: ''
    });
    
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country) => ({
          code: country.cca2,
          name: country.name.common,
        }));
        const sortedCountries = countryList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  };

  const closeSignUp = () => {
    setSignUp(false);
    setPop(false);

  };

  const handleClickOpen = () => {
    setPop(!popup);
  };

  const closePopup = () => {
    setPop(false);
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <nav class="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: '#edffff' }}>
          <div class="container-fluid">
          <img src={Logo} alt="Logo" className='pageLogo' />
            <button
              style={{ marginLeft: 'auto' }}
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse mx-3 Navbar-Right-Aligned" id="navbarSupportedContent">
              <ul class="navbar-nav" style={{ marginLeft: '10%' }}>
                <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">
                    Home
                    <span class="sr-only">(current)</span>
                  </Link>
                </li>

                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle navLinks"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Life Skill Courses
                  </a>
                  <div
                    class="dropdown-menu"
                    style={{ border: '1px  solid #03b4c6', backgroundColor: '#edffff' }}
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link class="dropdown-item UserDropdown" to="/MathCourseDetails">
                      Math Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/CodingCourseDetails">
                    Coding Portal 
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/BusinessCourseDetails">
                     Business Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/QuranCourseDetails">
                      Quran Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/GraphicCourseDetails">
                      Graphic Designing Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/EngineeringCourseDetails">
                      Engineering Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/SpaceSciencesCourseDetails">
                      Space Sciences Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/ArchitectureCourseDetails">
                      Architecture Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/CommunicationCourseDetails">
                      Communication Skills Portal
                    </Link>
                  </div>
                </li>

                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/PolymathKidSchool">
                    Polymath-kid @ School
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/AboutUs">
                    About Us
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/ContactUs">
                    Contact Us
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/Faq">
                    FAQs
                  </Link>
                </li>

                <li class="nav-item ">
                  <a class="nav-link navLinks" href="#" onClick={handleClickOpen}>
                    Sign In
                  </a>
                  <div>
                    {popup ? (
                      <div className="main">
                        {/* <div  className="popup"> */}
                        <form action="" onSubmit={handleLogin}>
                        <div className={`popup ${popup ? 'overlay1122 ' : ''}`}>
                          <div className="popup-header">
                            <h5 style={{ padding: '8% 4% 4% 4%' }}>Log in to Polymath-Kids.com</h5>
                            <h3 onClick={closePopup} style={{ cursor: 'pointer' }}>
                              x
                            </h3>
                          </div>

                          <input
                            type="email"
                            placeholder="Enter Email"
                            className='inp'
                            name='loginEmail'
                            required
                            value={loginValues.loginEmail}
                            onChange={handleLoginInput}
                           
                          />

                          <input
                            type="password"
                            placeholder="Enter Password"
                            name='loginPassword' 
                            className='inp'
                            required 
                            onChange={handleLoginInput}
                            value={loginValues.loginPassword}
                          />

                          <br />
                          <span style={{ padding: '5% 5% 5% 3%' }}>
                            <input type="checkbox" />
                            Remember me
                          </span>
                          <br />
                          <button className="btn" >
                            Log in
                          </button>
                          <br />
                          <button className="btn" onClick={signUp}>
                            Sign up
                          </button>
                          <br />
                          <a href="" className="link" style={{ marginLeft: '30%' }}>
                            Reset Password
                          </a>
                        </div>
                        </form>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    {signup ? (
                      <form action="" onSubmit={handleSubmit}>
                      <div className="main"> 
                        <div className={`popup ${signup ? 'overlay1133' : ''}`}>
                          <div className="popup-header">
                            <h5 style={{ padding: '8% 2% 2% 2%' }}>Sign Up to Polymath-Kids.com</h5>
                            <h3 onClick={closeSignUp} style={{ cursor: 'pointer' }}>
                              x
                            </h3>
                          </div>

                          <input type="email" name='signupEmail'  required onChange={handleInput} placeholder="Email" className="inp" />
                          <input type="password" name='signupPassword' required onChange={handleInput} placeholder="Password" className="inp" />
                          <input type="password" name='confirmPassword' required onChange={handleInput} placeholder="Confirm Password" className="inp" />
                          <input type="text" name='firstName' required onChange={handleInput} placeholder="First Name" className="inp" />
                          <input type="text" name='lastName' required onChange={handleInput} placeholder="Last Name" className="inp" />
                          <select name="gender" className="inp" required onChange={handleInput}>
                          <option value="">Select gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            
                          </select>
                         
                          <input type="date" name='dateOfBirth' required onChange={handleInput} placeholder="Date of Birth" className="inp" />
                          <input type="text" name='parentName' required onChange={handleInput} placeholder="Parent name" className="inp" />
                          <input type="number" name='whatsappNumber' required onChange={handleInput} placeholder="WhatsApp Number" className="inp" />
                          <input type="text" name='address' required onChange={handleInput} placeholder="Address" className="inp" />
                          <input type="number" name='schoolCode' onChange={handleInput} placeholder="School Code" className="inp" />

                          <select name="selectedCountry" className="inp" required onChange={handleInput}>
                            <option value=''>Choose country</option>
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>

                          <br />
                          <label htmlFor="avatar-upload" className="SignUP-avatar-upload">
                            {selectedFile ? (
                              <img src={URL.createObjectURL(selectedFile)} alt="Avatar" />
                            ) : (
                              'Upload avatar'
                            )}
                            <input
                              type="file"
                              id="avatar-upload"
                              accept=".png, .jpeg, .jpg"
                              onChange={(e)=>{handleFileChange(e)}}
                              className="Signupbtn"//;handleInput(e)
                              name='selectedFile'
                              style={{display:"none"}}
                              required
                            />
                          </label>
                          {fileError && <p>{fileError}</p>}

                          <p>
                            By signing up, I agree to the <br />
                            <Link to="/TermsOfUse" className="link">
                              Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link to="/Policy" className="link">
                              Privacy Policy
                            </Link>
                          </p>
                          <button className="btn" style={{ marginLeft: '10%', padding: '3% 24% 3% 25%' }} >
                            Sign Up
                          </button>
                        </div>
                      </div>
                      </form>

                    ) : (
                      ''
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default GuestNav;
