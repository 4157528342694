import React, { useState, useContext, useEffect } from "react";
import "./ManageClasses.css";
import { FaTrash } from "react-icons/fa";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import {toast} from 'react-toastify';
import axios from 'axios';

const ManageClasses = () => {
  const [courseName, setCourseName] = useState("");
  const [courseType, setCourseType] = useState("group");
  const [classLink, setClassLink] = useState("");
  const [classList, setClassList] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance.post("/getAllCourses")
    .then((response) => {
      setCourses(response.data);
    })
    .catch((error) => {
      console.log(error);
    });

  };

  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    fetchTeacher();
  }, []);

  const fetchTeacher = () => {
    axiosInstance.post("/getAllteachers")
    .then((response) => {
      setTeacherList(response.data);
    })
    .catch((error) => {
      console.log(error);
    });

  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = () => {
    axiosInstance.post("/getAllclasseswithstudent")
    .then((response) => {
      setClassList(response.data);
    })
    .catch((error) => {
      console.log(error);
    });

  };

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
  };
  const fetchAllCourses=()=>{
    // Fetch all courses
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
    .then(response => {
      const coursesData = response.data.courses;
      setCourses(coursesData);
    })
    .catch(error => {
      console.log('Error fetching courses:', error);
      // Handle the error as needed
    });
}
  
  const handleCreateClass = () => {
    const newClass = {
      courseName,
      courseType,
      classLink,
      teacher: "",
      schedule: "",
      numOfStudents: 0,
    };

    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear().toString().substr(-2)}`;

    newClass.schedule = formattedDate;
    const courseid1 = courses.find(
      (c) => c.coursename === courseName
    )?.Course_id;

    const payload = {
      Course_id: courseid1,
      classname: newClass.courseName,
      ClassID: "ClassID" + courseid1,
      ClassLink: newClass.classLink,
      ClassNo: "1",
      ClassType: newClass.courseType,
    };


    axiosInstance.post("/createclass", payload)
    .then(() => {
      fetchClasses();
    })
    .catch((error) => {
      console.log("Internal server error i", error);
    });


    setClassList([...classList, newClass]);
    setCourseName("");
    setCourseType("group");
    setClassLink("");
  };

  const handleClassLinkChange = (e, index) => {
    const updatedClassList = [...classList];
    updatedClassList[index].classLink = e.target.value;
    setClassList(updatedClassList);
  };

  function ManageClassRow({ classinfo, index, teacherList }) {
    const [assignedTeacher, setAssignedTeacher] = useState([]);

    useEffect(() => {
      fetchAssignedTeacher();
      fetchassignedSchedule();
    }, []);

    async function fetchAssignedTeacher() {
      try {
        const response = await axiosInstance.get(`/getAssignedTeachers/${classinfo.Class_id}`);

        if (response.status === 200) {
          setAssignedTeacher(response.data.assignedTeachers);
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    }

    async function fetchassignedSchedule() {
      try {
        const response = await axiosInstance.get(`/getAssignedSchedule/${classinfo.Class_id}`);

        if (response.status === 200) {
          const assignedSchedules = response.data.assignedSchedules;

          // Assuming you want to create a list of schedules in the format "Day - Time"
          const scheduleList = assignedSchedules.map(schedule => `${schedule.day} - ${schedule.time}`);

          // Set the scheduleList to the new list of schedules
          setScheduleList(scheduleList);
        }
      } catch (error) {
        console.error('API request error:', error);
      }
    }


    async function handleAssignTeacher() {
      if (!classinfo.teacher) {
        alert('Please select a teacher');
      } else {
        const payload = {
          Class_id: classinfo.Class_id,
          Teacher_id: classinfo.teacher.teacher_id,
        };

        try {
          const response = await axiosInstance.post('/assignTeachertoClass', payload);
          if (response.status === 200) {
            setAssignedTeacher([...assignedTeacher, classinfo.teacher.username]);
            toast.success("Teacher Assigned", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            alert('Failed to assign teacher');
          }
        } catch (error) {
          console.error('API request error:', error);
          alert('Failed to assign teacher');
        }
      }
    }

    const [teacherList1, setTeacherList1] = useState([]);
    const teachers = teacherList.teachers;

    useEffect(() => {
      setTeacherList1(teachers);
    }, [teachers]);

    const handleTeacherChange = (e) => {
      const selectedTeacher = teacherList1.find((teacher) => teacher.teacher_id === parseInt(e.target.value,10));
      classinfo.teacher = selectedTeacher;
    };
    const [days, setDays] = useState("");
    const [time, setTime] = useState("");
    const [scheduleList, setScheduleList] = useState([]);

    const dayList = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const timeList = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hh = (hour % 12).toString().padStart(2, "0");
        const mm = minute.toString().padStart(2, "0");
        const period = hour < 12 ? "am" : "pm";

        timeList.push(`${hh}:${mm}${period}`);
      }
    }

    const handleAddSchedule = async () => {
      if (!days || !time) {
        alert("Please select both day and time");
        return;
      }

      const newSchedule = `${days} - ${time}`;

      // Create a payload to send to the server
      const payload = {
        Class_id: classinfo.Class_id, // Replace with your class info
        ClassDay: days,
        ClassTime: time,
      };

      try {
        const response = await axiosInstance.post('/addSchedule', payload);
        if (response.status === 201) {
          // Schedule added successfully
          setScheduleList([...scheduleList, newSchedule]);
          setDays("");
          setTime("");
          toast.success("Schedule added successfully", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } catch (error) {
        console.error('API request error:', error);
        alert('Failed to add the schedule');
      }
    };

    const handleDayChange = (e) => {
      setDays(e.target.value);
    };

    const handleTimeChange = (e) => {
      setTime(e.target.value);
    };

    const handleDeleteRow = (index) => {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this row?"
      );
      if (shouldDelete) {
        const updatedClassList1 = [...classList];
        const updatedClassList2 = [...filteredClassList];
        updatedClassList1.splice(index, 1);
        updatedClassList2.splice(index, 1);
        setClassList(updatedClassList1);
        setFilteredClassList(updatedClassList2);
      }
    };

    return (
      <>
        <tbody id="table-rows">
          <tr
            className="CourseRegistrationTable-tr"
            style={{ border: "1px solid gray" }}
          >
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              <FaTrash
                className="ManageClassDeleteIcon"
                onClick={() => handleDeleteRow(index)}
              />

              {classinfo.classname}
            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              {classinfo.ClassType}
            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              <input
                type="text"
                placeholder="Enter Class Link"
                value={classinfo.ClassLink}
                onChange={(e) => handleClassLinkChange(e, index)}
              />
            </td>
            <td
              className="CourseRegistrationTable-td"
              style={{ border: "1px solid gray" }}
            >
              {classinfo.noofstudents}
            </td>

            <td className="CourseRegistrationTable-td" style={{ border: '1px solid gray' }}>
            <select className="ManageClassDropdown" onChange={handleTeacherChange}>
              <option value="">Select a teacher</option>
              {teacherList1.map((teacher, index) => (
                <option key={index} value={teacher.teacher_id}>
                  {teacher.username}
                </option>
              ))}
            </select>
            <button className="AssignTeacherButton" type="button" onClick={handleAssignTeacher}>
              Assign to class
            </button>
            <br />
            <span>{assignedTeacher}</span>
          </td>

            <td className="CourseRegistrationTable-td" style={{ border: "0" }}>
              <div>
                <select
                  className="ManageClassDropdown"
                  value={days}
                  onChange={handleDayChange}
                >
                  <option value="">Select a day</option>
                  {dayList.map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <select
                  className="ManageClassDropdown"
                  value={time}
                  onChange={handleTimeChange}
                >
                  <option value="">Select a time</option>
                  {timeList.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <button
                  className="AssignTeacherButton"
                  type="button"
                  onClick={handleAddSchedule}
                >
                  Add Schedule
                </button>
                <br />
                {scheduleList.map((schedule, index) => (
                  <span key={index}>
                    {schedule}
                    <br />
                  </span>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole } =
    useContext(UserContext);
  const determineUserRole = (email, password) => {
    return "admin";
  };
  const onLogin = (email, password) => {
    const userRole = determineUserRole(email, password);
    setUserRole(userRole);
  };
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedClassType, setSelectedClassType] = useState("");
  const [filteredClassList, setFilteredClassList] = useState([]);

const handleCourseFilterChange = (e) => {
    setSelectedCourse(e.target.value);
  };
  
  const handleClassTypeFilterChange = (e) => {
    setSelectedClassType(e.target.value);
  };
  useEffect(() => {
    renderClassList();
  }, [selectedCourse, selectedClassType]);
  useEffect(() => {
    fetchAllCourses();
  }, []);
  const renderClassList = () => {
    let filteredList = classList;
  
    // Apply filters based on selected values
    if (selectedCourse) {
      filteredList = filteredList.filter((classInfo) =>
        classInfo.courseName === selectedCourse
      );
    }
    if (selectedClassType) {
      filteredList = filteredList.filter((classInfo) =>
        classInfo.courseType === selectedClassType
      );
    }
    
  console.log("rendering filter list...",filteredList.length)
    // Render the filtered list
    // {filteredList.map((classInfo, index) => (
    //   <ManageClassRow key={index} classInfo={classInfo} index={index} />
    // ))};
    
    setFilteredClassList(filteredList);
  };
  return (
    <div>
      {isGuest && <GuestNav onLogin={onLogin} />}

            {isAdmin && <AdminNav />}
            {isTeacher && <TeacherNav />}
            {isStudent && <StudentNav />}
            <h3 className='ManageClassHeading'>
                   Manage Classes
            </h3>
        <div className='ManageClassesFormDiv'>
            <form className='ManageClassesForm'>  
                    <select className='ManageClassDropdown' style={{margin:"2%"}} value={courseName} onChange={handleCourseNameChange}>
                        <option >Select a course</option>
                        {courses.map((course) => (
                          <option key={course.Course_id} value={course.Course_id}>
                            {course.coursename}
                          </option>
                        ))}
                    </select>
            
                
                    <select className='ManageClassDropdown' style={{margin:"2%"}} value={courseType} onChange={handleCourseTypeChange}>
                        <option value="group">Group</option>
                        <option value="one-on-one">One-on-One</option>
                    </select>

          <input
            type="text"
            placeholder="Enter Class Link"
            value={classLink}
            onChange={(e) => setClassLink(e.target.value)}
          />

          <button
            className="ManageClassCreateButton"
            style={{ marginLeft: "30%" }}
            type="button"
            onClick={handleCreateClass}
          >
            Create Class
          </button>
        </form>
      </div>

        <div className='ManageClassesFilter'>
            <select value={selectedCourse} onChange={handleCourseFilterChange}>
                <option value="">All Courses</option>
                {courses.map((course) => (
                  <option key={course.Course_id} value={course.Course_id}>
                    {course.coursename}
                  </option>
                ))}
            </select>

        <select
          value={selectedClassType}
          onChange={handleClassTypeFilterChange}
        >
          <option value="">All Class Types</option>
          <option value="group">Group</option>
          <option value="one-on-one">One-on-One</option>
        </select>
      </div>

      <table
        className="CourseRegistrationTable ManageClassesTable"
        style={{ border: "0" }}
      >
        <thead>
          <tr className="CourseRegistrationTable-tr">
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Course Name
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Type
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Link
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              No. of Student
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class teacher
            </th>
            <th className="CourseRegistrationTable-th" style={{ border: "0" }}>
              Class Schedule
            </th>
          </tr>
        </thead>
        {filteredClassList.length === 0 &&
          classList.map((classinfo, index) => (
            <ManageClassRow
              key={index}
              classinfo={classinfo}
              teacherList={teacherList}
              index={index}
            />
          ))}
        {filteredClassList.length > 0 &&
          filteredClassList.map((classinfo, index) => (
            <ManageClassRow
              key={index}
              classinfo={classinfo}
              teacherList={teacherList}
              index={index}
            />
          ))}
      </table>
      <Footer />
    </div>
  );
};

export default ManageClasses;
