import React, { useState,useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './ManageLessonPlans.css';

const ManageLessonPlans = (props) => {
  const [plans, setPlans] = useState([
    { itemname: 'Lesson Plan 1', itemdetails: 'Description 1'}
  ]);

  const lesson = props.lessonid;

  useEffect(() => {
    fetchLessonPlan();
  }, []);

  const fetchLessonPlan = () => {
    axiosInstance.post('/getLessonPlanNameDescription', { lesson })
      .then(response => {
        setPlans(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = (planId) => {
    const updatedPlans = plans.filter((plan) => plan.id !== planId);
    setPlans(updatedPlans);
  };

  const handleUpdate = (planId) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === planId) {
        return { ...plan, editing: true };
      }
      return plan;
    });
    setPlans(updatedPlans);
  };

  const handleSave = (planId) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === planId) {
        return { ...plan, editing: false };
      }
      return plan;
    });
    setPlans(updatedPlans);
  };

  return (
    <div className="manage-lesson-plans">
      <h2>Manage Lesson Plans</h2>
      <div className="plan-list">
        {plans.map((plan) => (
          <div className="plan-card" key={plan.id}>
            {plan.editing ? (
              <div className="edit-form">
                <input type="text" defaultValue={plan.itemname} />
                <textarea defaultValue={plan.itemdetails}></textarea>
                <button className='aqua-button1' onClick={() => handleSave(plan.id)}>Save</button>
              </div>
            ) : (
              <>
                <h3>{plan.itemname}</h3>
                <p>{plan.itemdetails}</p>
                <div className="buttonsinz">
                  <button className="aqua-button1" onClick={() => handleUpdate(plan.id)}>Update</button>
                  <button className='aqua-button1' onClick={() => handleDelete(plan.id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageLessonPlans;
