import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { Form, FormGroup, Label, Input, Button} from 'reactstrap';
import './managecontent.css';
import {toast} from 'react-toastify';

function CreateLessonActivity() {
  const [course, setCourse] = useState('');
  const [module, setModule] = useState('');
  const [lesson, setLesson] = useState('');
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [activityName, setActivityName] = useState('');
  const [description, setDescription] = useState('');
  const [activityUrl, setActivityUrl] = useState('');
  const [activityType, setActivityType] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [instruction, setInstruction] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [remarks, setRemarks] = useState('');
  const [score, setScore] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance.post('/getAllCourses')
      .then(response => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance.post('/getAllModule', { courseId })
      .then(response => {
        const filteredModules = response.data.filter(module => module.Course_id ===  parseInt(courseId, 10));
        setModules(filteredModules);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance.post('/getAllLesson', { moduleId })
      .then(response => {
        const filteredLessons = response.data.filter(lesson => lesson.Module_id === parseInt(moduleId));
        setLessons(filteredLessons);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLesson(lessonId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      Lesson_id:lesson,
      ActivityDescription:description,
      ActivityLink:activityUrl,
      ActivityName:activityName,
      ActivityType:activityType,
      CompletionDate:completionDate,
      Instructions:instruction,
      OrderBy:orderBy,
      TotalTime:totalTime,
      Remarks:remarks,
      Score:score,
    };

    axiosInstance.post('addLessonActivity', payload)
      .then(response => {
        console.log(response.data);
        setCourse('');
        setModule('');
        setLesson('');
        setActivityName('');
        setDescription('');
        setActivityUrl('');
        setActivityType('');
        setCompletionDate('');
        setInstruction('');
        setOrderBy('');
        setTotalTime('');
        setRemarks('');
        setScore('');

        toast.success("Lesson Activity has been Added", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log(error);
      });

  };

  return (
    <div className="create-lesson-activity">
      <h2>Create Lesson Activity</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="course-select">Course</Label>
          <Input
            type="select"
            name="select"
            id="course-select"
            value={course}
            required
            onChange={(event) =>handleCourseSelect(event.target.value)}
          >
            <option>Select a course</option>
            {courses.map(course => (
              <option key={course.id} value={course.Course_id}>{course.coursename}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="module-select">Module</Label>
          <Input
            type="select"
            name="select"
            id="module-select"
            value={module}
            required
            onChange={(event) =>handleModuleSelect(event.target.value)}
          >
            <option>Select a Module</option>
            {modules.map(module => (
              <option key={module.id} value={module.Module_id}>{module.modulename}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="lesson-select">Lesson</Label>
          <Input
            type="select"
            name="select"
            id="lesson-select"
            value={lesson}
            required
            onChange={(event) => handleLessonSelect(event.target.value)}
          >
            <option>Select a Lesson</option>
            {lessons.map(lesson => (
              <option key={lesson.id} value={lesson.Lesson_id}>{lesson.lessonname}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="activity-name-input">Activity Name</Label>
          <Input
            type="text"
            name="activityName"
            id="activity-name-input"
            placeholder="Enter activity name"
            value={activityName}
            required
            onChange={(e) => setActivityName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description-input">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description-input"
            placeholder="Enter description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="activity-url-input">Activity URL</Label>
          <Input
            type="text"
            name="activityUrl"
            id="activity-url-input"
            placeholder="Enter activity URL"
            value={activityUrl}
            required
            onChange={(e) => setActivityUrl(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="activity-type-input">Activity Type</Label>
          <Input
            type="select"
            name="activityType"
            id="activity-type-input"
            placeholder="Select activity type"
            value={activityType}
            required
            onChange={(e) => setActivityType(e.target.value)}
          >
            <option>Select activity type</option>
            <option>Oral Activity</option>
            <option>Video Activity</option>
            <option>Written Activity</option>
            <option>MCQ Activity</option>
            <option>Speed Quiz Activity</option>
            <option>Mental Activity</option>
            <option>Game Activity</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="instruction-input">Instruction</Label>
          <Input
            type="textarea"
            name="instruction"
            id="instruction-input"
            placeholder="Enter instruction"
            value={instruction}
            required
            onChange={(e) => setInstruction(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="orderby-input">Order By</Label>
          <Input
            type="text"
            name="orderby"
            id="orderby-input"
            placeholder="Enter order by"
            value={orderBy}
            required
            onChange={(e) => setOrderBy(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="totaltime-input">Total Time</Label>
          <Input
            type="text"
            name="totaltime"
            id="totaltime-input"
            placeholder="Enter total time"
            value={totalTime}
            required
            onChange={(e) => setTotalTime(e.target.value)}
          />
        </FormGroup>
        <Button variant="primary" type="submit" className="save-button">
          Save
        </Button>
      </Form>
    </div>
  );
}

export default CreateLessonActivity;
