import React, { useState,useContext,useEffect } from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './TermsOfUse.css'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';


const TermsOfUse = ({selectedCourse}) => {
   
   const handleCourseSelect = (course) => {
      console.log("Selected Course in Home:", course);
      selectedCourse(course);
    };
  
   const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
   
      useEffect(() => {
        const fetchData = async () => {
         await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
            credentials: 'include',
          }).then(response => {
              console.log("Response:",response);
            response.json().then(userInfo => {
              console.log("userinfo:",userInfo);
              setUserInfo(userInfo);
  
              if(userInfo?.usertype)
              {
                const uType=userInfo?.usertype;
                setUserRole(uType);
              }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar; // Store the avatar in a separate variable
            console.log("Uname: ", uname);
            console.log("Avatar: ", avatar);
  
            })
            .catch(err=> console.log("Error in useeffect:",err))
          });
        }
        fetchData();
      }, [isAdmin, isTeacher, isStudent, isGuest]);
    const determineUserRole = (usertype) => {
          console.log("In home.js:",usertype)
          if(usertype === 'Student')
            return "Student";
          else if(usertype === 'Teacher')
            return "Teacher";
          else if(usertype === 'Admin')
            return "Admin";
          else
            return "guest";
    };
    
    
   const navigate=useNavigate();
    const onLogin = (usertype) => {
      const userRole = determineUserRole(usertype);
      console.log("Calling setUserRole.")
      setUserRole(userRole);
    };
  
    function onLogout() {
        console.log("inside Onlogout.");
        fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
          credentials: 'include',
          method: 'POST',
        });
        setUserInfo(null);
        setUserRole('guest');
      }
        
        const username = userInfo?.username;
     
  return (
    <>
    
       {/* Navbar */}
       {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

     <div className='School'>
<div className='innerVision'>     
    <h1 style={{color:"black",fontSize:"20px",paddingTop:"4%"}}>TERMS OF SERVICE</h1>
    <p className='para'>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY USING THIS WEBSITE AND/OR PLATFORM YOU AGREE TO BE BOUND BY ALL OF THE BELOW MENTIONED TERMS AND CONDITIONS.
<br />
<br />
POLYMATH-KIDS (including its affiliates) (“Company”,” We” or “Us” and their connotations) operates a website (URL: https://www.polymath-kids.com) (together, with its mobile/tablet application, called the “Platform”) which is engaged in the service of online tutoring of various subjects to children.
<br />
These terms and conditions (“Terms”) describe the terms on which the Company grants end users access to the Platform (hereinafter referred to as “Services”) and shall be read with the privacy policy available on https://www.polymath-kids.com). Anyone below 18 years of age is assumed to be a child (“Child”) and requires parental consent to use the Platform. The parents of the Child or users above 18 years of age are hereinafter referred to as “you”, “your” or “yours”. The term “Users” for the purposes of these Terms shall be read as You and/ or Child. Users also include all persons who access, browse, or sign up on the Platform for applying to teach on the Platform (“Applicant(s)”) and those selected to teach on the Platform (“Instructor(s)”).
<br />
The Company reserves the right, at its discretion, to change, modify, add, or remove portions of these Terms at any time by posting the amended Terms. Please check these Terms periodically for changes. Your continued use of the Platform or Services after the posting of changes constitutes your binding acceptance of such changes. In addition, when using any particular services or availing any promotional offer, you may be subject to additional terms and conditions, posted guidelines or rules, as may be applicable to such services and offers. All such guidelines, rules, product requirements or sometimes additional terms are hereby incorporated by reference into the Terms.

    </p> 
    <br/>
    <br/>

    <h3 style={{color:"black",fontSize:"20px"}}>1. Terms of Service</h3>
    <p className='para'>
    By choosing to visit the Platform and/or avail any Services provided by Company, you agree to be bound by these Terms. Please read the following information carefully. If you are a parent or guardian and you provide your consent for your child's use of the Platform, you agree to be bound by these Terms in respect to their use of the Platform. By your continued access or use of the Platform, you signify your agreement to be legally bound by the Terms set forth herein. If you do not agree to the Terms of this agreement, promptly exit this page and stop accessing the Services.
    <br />
    
 
    </p>
    <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>2. Description of Services</h3>
     <p className='para'>The Company facilitates online education services to children in various subjects and 21st Century life skills. The concept is to create a virtual classroom, which helps the Child to learn without any restrictions on time and place.
   
    </p>

    <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>3. User ID and Password</h3>
     <p className='para'>
     In order to access the Company’s Platform and its services, You may have to create an account and disclose information including, but not limited to, (i) name, e-mail ID, photograph, location and other contact information (ii) gender and other demographics (iii) birth date and year to validate the current age of the Child (iv) your email address to acquire the parental consent. You must be at least 18 years old to register on the Platform. If you are under 18 years old, you are not permitted to register on this site unless such registration is completed by a parent or legal guardian. You acknowledge that your user ID and password (“Participant Account”) is for your exclusive use only. Use or sharing of your Participant Account with another user or person is not permitted and is cause for immediate blocking of your access to the Platform, the Services and the content provided by the Company and shall lead to termination of this Agreement without any notice.
     <br />
You are solely responsible for maintaining the confidentiality of your Participant Account and for all activities that occur under it. You agree to immediately notify to the Company if you become aware of or have reason to believe that there is any unauthorized use of your Participant Account. You also agree to take all reasonable steps to stop such unauthorized use and to cooperate with the Company in any investigation of such unauthorized uses. The Company shall not under any circumstances be held liable for any claims related to the use or misuse of your Participant Account due to the activities of any third party outside of your control or due to your failure to maintain the confidentiality and security of your Participant Account.
   
    </p>


    <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>4. Trial Classes</h3>
     <p className='para'>
     We offer One (1) free trial class to our new members (if member wishes to avail it) so that you get an opportunity to experience the services provided by us. Only one (1) free trial class is permitted per new student. Availing multiple trial classes by an individual either through his/her own account or through someone else's accounts, email IDs and/or in any other manner whatsoever, without the prior written permission of the Company is not allowed and shall amount to a breach of the present Terms.
Any free trial class provided by the Company shall also be governed by these Terms.
    </p>

    <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>5. Modules and Curriculum</h3>
     <p className='para'>
     The Company will have its sets of modules according to which the Child is expected to complete his/her levels. The Company has tutors who will be assisting the Child with their modules.
The Company shall also grant you access to its material, content, curriculum, documents and other information and data (“Curriculum”) which may be in video, audio, written, graphic, recorded, photographic, or any other format in relation to the modules for which you have registered for. The Company reserves the right to amend, revise or update the Curriculum at any time.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>6. Use of the Platform by Applicants and Instructors</h3>
     <p className='para'>
     Applicants interested in teaching on the Platform may apply to do so by providing their full name, mobile number, and email address. A member of the Polymath-Kids’ team may reach out to you on your registered contact details and conduct an evaluation. If the Applicant is chosen, then she/he may be brought onboard as an Instructor on the Platform. Please note, your application on the Platform does not guarantee your selection as an Instructor.
     <br />
The Terms and Privacy Policy shall be applicable to you in addition to the Instructor engagement agreement provided to you as an Instructor.
You acknowledge and understand that children or their parents may leave ratings and reviews of your classes and that we are not responsible for these ratings and reviews.
       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    7. License to Use
    </h3>
     <p className='para'>
     The Company hereby grants You and the Child, the limited, non-transferable, non-exclusive, and revocable license to access, view and use the Platform only for the purposes of accessing, viewing, posting or submitting user material, using the embedded link function, placing store orders or for accessing information, applications and services. The Company reserves the right to suspend or deny, in its sole discretion, your access to all or any portion of the Platform. This license is limited to personal and non-commercial uses by You and your Child. Any rights not expressly granted to You herein are reserved to Company.
     <br />
You are not permitted to reproduce, transmit, distribute, sub-license, broadcast, disseminate, or prepare derivative works of the Curriculum, or any part thereof, in any manner or through any communication channels or means, for any purpose other than the limited purpose mentioned above, without the Company’s prior written consent.

       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    8. Intellectual Property Rights
    </h3>
     <p className='para'>
     You acknowledge that the Company is the sole and exclusive owner of the Platform, the services provided by the Company, the curriculum, and its content and as such the Company is vested with all the Intellectual Property Rights and other proprietary rights in the Platform, the Services, content and the curriculum. Further, you agree and acknowledge that:
     <br />
The Company shall have the right to record the classes, discussions, processes, events, conversations, feedback, pertaining to the participation of the Users in the courses, offered in online or any other format.
The Company shall have an exclusive right including in the nature of intellectual properties throughout the world to use, publish, display, exhibit, transmit, broadcast, disseminate, market, advertise, license, transfer, modify, and create derivative works from such recorded contents mentioned hereinabove, for any purpose whatsoever, through various medium including but not limited to social media, webpages, electronic or print media, news articles, blogs etc.
You agree and acknowledge that the Company may from time-to-time conduct events, contests, championships, webinars/seminars, and award ceremonies, as it may deem fit (“Events”) on or in connection with its courses which shall be the exclusive property of the Company. Further, the Company may from time-to-time (during Events or otherwise), create/record and upload/disseminate videos, audios/sound recordings, voice, image, achievements, testimonials, narratives, success stories, content and other materials pertaining to Users experiences at the Platform (Success Stories), on the Platform or any other websites or related pages or any other social media platforms in, on or in connection with its courses or its success which shall be the exclusive property of the Company. You undertake not to reproduce, transmit, retransmit, distribute, publish, post, share or make available the said videos, audios/ sound recordings, content and other materials or any part thereof which are available on the Platform in any manner whatsoever.
You agree that you shall be solely responsible for any participation in/submissions of entries/applications (as the case may be) to the Events, including its authenticity, originality and genuineness. Any responsibility for any claims, objections, and/or issues raised regarding such authenticity, originality or legality including by any third party shall be solely yours. You release the Company from any claim arising out of the use of Event related content including through its publication, exhibition, transmission, broadcast, dissemination or advertisement in any form or mode.
<br />
You unconditionally, irrevocably release the Company from any claim arising out of the usage of Success Stories, class interactions or publications of Events, by Company through various means, including advertising or publication in any form or mode.
<br />
You agree that the Company shall have no obligation towards User and shall not be held responsible with respect to any advertising or promotional material which the Company may create and disseminate based upon live class interactions or Success Stories.
<br />
You agree that you shall not be entitled to any compensation or other rights or benefits against such promotional usage by the Company.
Additionally, the Company also retains all rights (including copyrights, trademarks, patents, designs, logos, trade-dress, trade-secrets, know-how as well as any other intellectual property right) in relation to all information provided on or via this Platform, including but not limited to all texts, graphics, photos, illustrations, apps and logos except the content, reading material, curriculum, tests and exercises made available to you from organizations or individuals that the Company may partner with/be licensed by, from time to time. You shall not copy, download, publish, distribute or reproduce any of the information contained on this Platform or social media in any form without the prior written consent of the Company.
The Company retains all the rights in the video recordings, sound/audio recordings, images, photos, pictures/ images clicked during the classes, lectures delivered by the Company’s tutors, text and other material posted on the Platform and shall be the sole owner of the same. You undertake that you shall not record, make videos or sound/ audio recordings, take screen shots, click pictures and shall not download, publish, transmit, display, reproduce, transmit, distribute, post, share or make copies of any of the classes/ lectures that are conducted by the Company (including the trial classes), video recordings, sound/audio recordings, images, photos, pictures/ images, text or other material, whether in full or in part, unless you obtain prior written approval from the Company. Any recordings, videos, sound/audio recordings, screen shots, pictures, images, material or content which is obtained in any manner without the prior written consent of the Company shall amount to breach of the instant terms and conditions and you shall be solely liable for the said breach under the applicable laws. All other rights are reserved.

       </p>


       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    9. Use of the Platform by the Child
    </h3>
     <p className='para'>
     You expressly acknowledge and undertake that:
• You are competent and have all the necessary legal rights to enter into this agreement on behalf of the child.
• You grant your consent to the Company for your child to attend and participate in the classes, courses, tests, sessions and/or any other program conducted and/or organized by the Company on its Platform and in relation to the services provided by the Company. You undertake that the participation of the Child and all the activities done by the Child will be under your direct and constant supervision. You further accept full and complete liability arising out of the child’s acts, whether direct or indirect.

       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    10. Payment and Refund
    </h3>
     <p className='para'>
     The Platform is a paid service and the payments made by You shall be according to the plans opted by You through the Platform. You explicitly agree to pay the fees for the courses/ plans that you purchase, and you authorize the Company to charge you as per the applicable payment mode opted by you. All payments shall be through the payment mechanism put in place by the Company and You shall be responsible for paying all fees and applicable taxes in a timely manner as per the mechanism associated with the Plan availed by You. Users have been given various options to choose and proceed with the payment for the Services. With reference to Section 10, the payment gateway mechanisms are governed by the terms and conditions of the third-party providers as listed on the Platform and the User agrees to be bound by those terms. <br />
Valid Credit / Debit/ Cash Card/ online bank transfers and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms agreed to between the Users and the respective issuing bank and payment instrument issuing company. We shall not be liable for any unauthorized use, fraud, payment refunds, lost amount etc. in the transaction. The amount of refund payable shall only be limited to the amount paid by the User for Services which were not rendered to the User by Us. In case of refund of EMI transactions, User will be charged interest as per bank’s regulations. <br />
We attempt to process and complete customer refund requests within 15-30* Business days** from the time we receive a refund request. Our refund process may include a phone call to you, where we request course feedback and/or validation of key information needed to process your refund. If you have any questions about your refund request or the refund process, please contact us at info@Polymath-Kids.com.
We reserve the right to change any fees at any time at our sole discretion and any change, update, or modification in the fee shall become effective immediately upon the same being posted/uploaded or notified on our website.
We reserve the right to deduct the value of any cashback, discounts or rewards, including vouchers, received by You from the amount of a refund payable, should You request a refund prior to full course completion.
*If you have financed your course(s) by obtaining a loan or financing through a third party financing entity, then the refund timeline and process will be in accordance with the terms and conditions that you agreed to with that financing entity. The Company cannot and shall not be held responsible for any delay or other issues on part of the third party loan financing entity in the processing and completion of such refund requests.
**Business Day” shall mean any day excluding Saturday, Sunday and any bank holiday;

       </p>


       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    11. Linking
    </h3>
     <p className='para'>
     You or the Child may establish a link to one or more site(s), provided that: <br />
• the link is legal and not detrimental or damaging to and/or does not take unfair advantage of our reputation or business;
• such linking is not for advertising or promotional purposes (unless We have expressly agreed to it);
• the link is not from any website which promotes any political or religious views, or promotes or depicts intolerance, hatred, discrimination, violence, pornography or illegal activity;
• the link does not falsely or misleadingly imply or suggest that We endorse, approve of or are associated with the linked website, its web pages or any of its contents; and
• framing of any site on any other website is not allowed and You must not provide access to the site or part of it under any other URL.
The Company may withdraw your or the Child’s right to link to any site without notice and at any time (acting in our sole discretion).
Where any site and/or application contain links to other websites, web pages, resources, or mobile services which are proprietary to third-parties, other users, advertisers or sponsors, such websites, web pages, resources and mobile services are provided for your information only and You access them at your own risk. The Company will not be held liable or responsible for the content or operation of third-party websites, web pages, resources or mobile services.

       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    12. Third Party Services
    </h3>
     <p className='para'>
     You acknowledge that the Services provided by the Company uses and/or contain certain softwares, products and services which are developed and owned by third parties, the use of which is governed by terms and conditions of such third parties. Please read the User Agreement and Privacy Policy for these sites separately before using the said third party websites. By accessing the said third party websites, you agree to be bound by the respective user agreement and privacy policy of these third parties. Accordingly, you agree that the Company will not be responsible for such Third-Party software, products and services nor for any error, malfunction or defect in the Service resulted therefrom.”

       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    
13. Third Party Permission
    </h3>
     <p className='para'>
     The Company has subscribed to various third-party service providers and you agree and acknowledge that, while accepting these terms, you explicitly grant permission to these service providers to use your information.
We do not disclose Personal Data to third parties for the purpose of allowing them to send marketing material to you. However, we may share non-personal, de-identified or aggregated information with selected third parties for research, development, analytics or promotional purposes other than direct marketing.
In some cases, the organizations that we may disclose your personal information to may be based outside the location where the information is collected. Where we do this, we require these parties to take appropriate measures to protect that information and to restrict how they can use that information.
In the event of any dispute between the third party and you the company shall not be held liable in any matter whatsoever.

       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    14. User Content

    </h3>
     <p className='para'>
     The Company offers You and the Child the opportunity to submit, post, display, transmit, perform, publish, distribute or broadcast content and materials, including, without limitation, articles, commentaries, photographs, text, music, video, audio recordings, computer graphics, pictures, data, questions, comments, suggestions or personally identifiable information. <br />
The Company may remove content at its discretion, subject only to its express obligations in respect of peer-reviewed articles.
The Child may choose to write and post reviews on the Platform. We do not encourage your Child to post any Personal Information on the Platform and the ill effects of such revelation shall be borne by you. However, you can request the Company via e-mails to delete any such information posted by the Child. For the purpose of verification, the Company will review and scrutinize such emails before responding.
You warrant and represent that your content including any content developed by your Child during the provision of Services by the Company using any third-party platform and/or published outside of the Platform, and the content of any website from which You include a link to any site, or to which You post a link from a site, will not be inappropriate. Without limitation, content (and the content of third-party websites) may be considered inappropriate if: <br />
• it is misleading in any way, and/or it gives a false impression as to its origins or approvals;
• it is defamatory, plagiarized (including plagiarism from your own work), abusive, malicious, threatening, false, misleading, offensive, insulting, discriminatory, profane, harassing, racist, sexist, indecent, obscene, pornographic, hateful or it advocates violence;
• it is in breach of confidentiality or another person’s privacy or other rights, or of any duty owed by You;
• it prejudices any active or pending legal proceedings of which You are aware;
• it contains accusations of impropriety or personal criticism of our personnel, editors or reviewers;
• it infringes any intellectual property rights proprietary to the Company or any third party;
• it is technically harmful (including content containing, without limitation, computer viruses, logic bombs, trojan horses, worms, harmful components, corrupted data or other malicious software, harmful data or conduct and/or contains any other element which is intended to harm the Company or any third party, or to carry out or facilitate any fraudulent or dishonest transaction);
• it advertises or promotes any product or service or makes any requests for donations or financial support;
• it is spam or junk content;
• it impersonates another person or otherwise misrepresents your identity, affiliation or status;
• it would be considered a criminal or statutory offense in any jurisdiction, or gives rise to civil liability, or is otherwise unlawful; and/or
• it is in breach of these Terms and/or of any Additional Terms.
You hereby explicitly consent and grant permission to the Company to record, make videos, audio/sound recordings, take screenshots during the classes/ lectures that are conducted by the Company on its Platform to the extent required to improve the services offered by the Company. <br />
All rights, ownership, and intellectual property in the ‘User Content’ created by your child on or by using the Platform, during the period of the tutorial classes or the course, shall vest in the Company. You hereby explicitly consent and transfer all such rights, ownership, and intellectual property in the ‘User Content’ to the Company for a worldwide, royalty-free, and perpetual use. The Company shall have the sole and exclusive right to use, copy, modify, adapt, prepare derivative works from, distribute, perform, publish, post and display any/all such ‘User Content’.
You and your Child undertake to use the Company’s platform, any third party platform and content Company may provide access to in rendering of its Services and respective intellectual properties only for the purposes envisaged in the instant terms and shall not use the same for any unauthorized or unlawful purpose. Should you and your Child use the Platform, third party platform/content and/or associated intellectual properties for any purpose other than expressly permitted, then you and your Child will be solely responsible to obtain all necessary permissions, authorizations and licenses from the said third party. In the event you and your Child use the Platform, third party platform/content and/or associated intellectual properties beyond the limited rights granted to you and your Child under the corresponding terms, then you and your Child shall be solely responsible for the same and shall indemnify the Company for all losses, claims and damages in this regard. <br />
The Company will not be held responsible or liable for any of the User Content provided, published or disseminated by You and your Child on the Platform or any other website. You must not attempt to avoid or undermine any protections that the Company may put in place for the security and operation of its Platform or any other website. You and your Child shall indemnify the Company for all losses, claims and damages in this regard.
You and your child will be solely responsible to ensure that any content or intellectual property created by the child shall not contain any bugs, virus and malware or infringe any third party’s intellectual property rights or violate any applicable laws (including data protection and privacy laws) in any manner.
By uploading, submitting, creating, or publishing your User Content to or through the Services, you represent, and warrant that:
• you are the creator and owner of your User Content and that you have the authority to use or have the necessary licenses, rights, consents, and permissions to publish the User Content you submit
• your User Content does not and will not
o infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right
o slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person
• your User Content does not contain any viruses, adware, spyware, worms, or other malicious code.
       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    15. Intended Purpose of Use
    </h3>
     <p className='para'>
     Any application, code or content created using the Company’s platform are intended to be used solely as prototypes and for evaluation of validity and practicability of ideas. All such applications, codes and/or content may be solely created for educational purposes and gathering feedback by the students and are in no way fit for or meant to be used for any commercial use. Any other use of such apps, codes or content, other than the use specified herein is prohibited by the Company and the Company shall not be liable for the same and you and your child shall indemnify the company for any loss, claims or damages suffered by the Company in this regard.

       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    16. Fitness of Use
    </h3>
     <p className='para'>
     Any and/or all apps built using the Company platform by the users during the period of their permitted use of the platform are solely meant for educational and evaluation purposes as part of the users training program, and hence the same are expected to have limited functionality and use and are thus not fit to be used commercially or to be adopted for any use as opposed to the intended use prescribed in these terms. The apps might have unresolved technical bugs and security concerns including but not limited to viruses, data safety, account protection, insufficiency of security protocols and non-encryption. Any personal information, passwords and/ or any other details or sensitive data provided might become available to other users of the app.
Any such commercial or non-authorized use of these apps shall be at the sole risk and discretion of the users and third parties and the Company in no way whatsoever promotes or authorizes the same. You and your child shall be solely responsible for any such unauthorized use, promotion and/or commercialization of the app/code/content created by your child or any other user of the Company platform and shall indemnify the Company for all losses, claims and damages in this regard.
       </p>
 
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    17. Rule of Conduct
    </h3>
     <p className='para'>
     Users must comply with the laws that apply to You in the location that You access Company’s Services from. If any laws applicable to You restrict or prohibit You from using Services of Company, You must comply with those legal restrictions or, if applicable, stop accessing and/or using the Services of Company. You promise that all the information You provide to Company on accessing and/or using the Services of Company is and shall remain true, accurate and complete at all times. <br />
Notwithstanding any other provision of these Terms You agree and undertake not to:
• Hack, attempt to hack, modify, adapt, merge, translate, decompile, disassemble, reverse engineer or create derivative works out of the Service or any part of them (save to the extent which expressly cannot be prohibited in accordance with the applicable mandatory law in your jurisdiction);
• Remove, disable, modify, add to or tamper with any program code or data, copyright, trademark or other proprietary notices and legends contained on or in the Service;
• Create software which mimics any data or functionality in the Service;
• Use or deal in the Service except as permitted by these Terms;
• Include contact details intended to enable communication outside of the Service, in any Communication;
• Use your access to the Service, or information gathered from it, for the sending of unsolicited bulk email;
• Make any public, business or commercial use of the Service or any part of them;
• Provide hypertext links, URL links, graphic links, hyperlinks or other direct connection for profit or gain to the Service without prior written permission of Company;
• Make the Service or any part of it available to any third party (please note this does not stop you from fairly and honestly providing links to the Platform, or showing either to other people);
• Use or process the Service or any part of them unfairly or for any illegal or immoral purpose; or
• Delete or obscure any copyright or other proprietary notice on the Service.
The Service may only be accessed and used via authorized servers. You must take no action to bypass authorized servers and/or use third party software to modify any aspect of the Service, whether for the purpose of securing an unfair advantage over other users.
       </p>

       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    18. Limited Liability
    </h3>
     <p className='para'>
     You are held personally liable for any violation of a third party's rights by You and your Child. You agree to reimburse Company for all damages resulting from the culpable non- observance of the obligations of these Terms. You release the Company from all eligible claims that other users or third parties may file against Company due to a violation of their rights by content posted by the user or due to a violation of other obligations. You shall assume the costs of Company legal defense, including all court and legal fees. This condition does not apply if you are not responsible for the infringement. <br />
Company ensures that the information and the training facilitated by the tutors on the Platform is accurate but does not guarantee or warrant its accuracy, adequacy, correctness, validity, completeness, or suitability for any purpose, and accepts no responsibility with respect to the information and coaching given by the tutors on the Platform. <br />
You agree and understand that Company does not states or claims any warranty for the quality of lectures delivered by the tutors listed on our Platform.
The Company undertakes the scrutiny of the curriculum delivered by the tutors; however, the Company does not guarantee the quality of lessons delivered to the Child. At any instance of live interactive classes, if the tutor uses any sexual or abusive language or depicts any forced sexual acts, or pornographic images or does any unethical, unlawful or immoral act, then in such cases, please immediately inform the Company at the contact provided below in Section 24. The Company will make all efforts to take any and all necessary actions as per the applicable law. However, the Company explicitly disclaims any liability or responsibility in the event of such a circumstance. <br />
Company will not be held responsible for any unethical, illegal acts performed by the Child on the advice of tutor and it shall be your responsibility to closely monitor the activities of your Child while accessing the Platform.
In no event shall the Company be liable for any direct, indirect, incidental, special or consequential damages, or damages for loss of profits, revenue, data or data use, incurred by you or any third-party, whether in an action in contract or tort, arising from your access to, or use of, the Platform or any content provided on or through the Platform. <br />
The Company facilitates educational and informational on an "as is" basis and is liable only to provide its services with reasonable skill and care.
The Company's liability for any and all claims in the aggregate, arising out of or related to your use of the Platform, shall not under any circumstances exceed the amounts actually paid by You to the Company for its Services.
External Sites have not been verified or reviewed by Company and all use and access of External Sites is made at your own risk. “External Sites“ means third party websites and online services to which the Service links. The Company gives no other warranty in connection with the Service and to the maximum extent permitted by law, Company excludes liability for:
• any loss or damage of any kind howsoever arising, including any direct, indirect, special, punitive or consequential loss whether or not such arises out of any problem which Company have been made aware of;
• the accuracy, currency or validity of information and material contained within any communications or the Service;
• any interruptions to or delays in updating the Service;
• any incorrect or inaccurate information on the Service;
• the infringement by any person of any copyright or other intellectual property rights of any third party through any communication or use of the Service;
• the availability, quality, content or nature of External Sites;
• any transaction involving External Sites;
• any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing, using or downloading the Service, or any Communication (save that, where digital content supplied to You by Company or on behalf of Company through the Service causes damage to your digital content or devices You may be entitled to compensation or repair or replacement, in which case kindly inform to Company); and <br />
• all representations, warranties, conditions and other terms and conditions which but for this notice would have an effect
The Company does not warrant that the operation of the Service will be uninterrupted or error-free. The Company will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen contingency beyond the reasonable control of Company including Internet or electricity outages, communications outages, fire, flood, war or act of God. <br />
Except as provided above there are no other warranties, conditions or other terms and conditions, express or implied, statutory or otherwise, and all such terms and conditions are hereby excluded to the maximum extent permitted by law.
You agree that in relation to your use of the Service You will not in any way conduct yourself in a manner which is unlawful, or which gives rise to civil or criminal liability or which might call Company or the Service into disrepute. You agree that You are and shall remain responsible for maintaining the confidentiality of your password and username and for all activities that occur under your account.
No amendment will take place if such amendment would substantially disrupt the contractual balance between the parties. Users will be informed of any amendments to the general Terms via the Platform or via notice by email or in writing.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    19. Termination
    </h3>
     <p className='para'>
     Without limiting any other rights that Company may have, Company may remove, restrict, cancel or suspend access to and/or use of the Platform, Services provided by the Company and any part of it, if Company considers (in the sole discretion of Company) that You have breached any of these Terms.
You may also terminate your agreement with the Company by ceasing to access the Platform, Service, deleting all copies of the Service or part thereof within your control. Termination shall not affect any rights or remedies, which have accrued up to the time of termination.
       </p>
    
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    20. Severability
    </h3>
     <p className='para'>
     If any court or competent authority finds that any provision of these Terms (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these Terms shall not be affected.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    21. Assignment
    </h3>
     <p className='para'>
     Any rights and licenses granted hereunder shall not be transferred or assigned by you but, shall be assigned by the Company at its sole discretion. Any attempted transfer or assignment by you in violation hereof shall be considered as null and void.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    22. Waiver
    </h3>
     <p className='para'>
     No waiver of any terms of this agreement shall be treated as a further or continuing waiver of such term or any other term and the Company’s failure to assert any right or provision under this agreement shall not constitute a waiver of such right or provision.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    23. Entire Agreement
    </h3>
     <p className='para'>
     This Agreement, along with the Privacy Policy, Terms of Use, and any additional guidelines, rules, and/or disclaimers posted on the Platform constitutes the entire agreement governing your use of our Platform and supersedes any prior agreements, if any, relating to any matter dealt within this Agreement.
       </p>
       <br/>
    <h3 style={{color:"black",fontSize:"20px"}}>
    24. Contact
    </h3>
     <p className='para'>
     If you have any questions about these Terms, please contact us by email or postal mail on the following address: <br />
Name: Polymath-kids.com
<br />
E-mail: info@Polymath-Kids.com <br />

       </p>
</div>
   
 
   </div>
    {/* Footer */}
    <Footer/>
    </>
  )
}

export default TermsOfUse
