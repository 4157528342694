import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {Link} from 'react-router-dom'
import Logo from '../CourseImages/Logo.png'
import './Nav.css'

const AdminNav = ({username,onLogout}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // setIsOpen2(!isOpen2);

    setSelectedCourse(null);
  };

  const handleCourseSelection = (Course_id) => {
    setIsOpen2(!isOpen2);
    setSelectedCourse(Course_id);
  };
  useEffect(() => {  
  axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
    .then(response => { 
      setCourses(response.data.courses);
      // console.log(response)
    })
    .catch(error => {
      console.error('Error fetching courses:', error);
    });
}, []); 
  const [courses, setCourses] = useState([]);


  return (
    <div style={{width:"100%"}} >

    <nav class="navbar navbar-expand-lg navbar-light "style={{backgroundColor:"#edffff"}} >
      <div class="container-fluid">
  <img src={Logo} alt="Logo" className='pageLogo'/>
        <button style={{marginLeft:"auto"}} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse mx-3 Navbar-Right-Aligned" id="navbarSupportedContent" >
        <ul class="navbar-nav" style={{marginLeft:"10%"}}>
          <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">Home
                    <span class="sr-only">
                    (current)
                    </span>
                  </Link>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Users
            </a>
            <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
              <Link class="dropdown-item UserDropdown" to="/ManageUser">Manage Users</Link>
              <Link class="dropdown-item UserDropdown" to="/CourseRegistration">Course Registration</Link>
              <Link class="dropdown-item UserDropdown" to="">Potential Customers</Link>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Fees
            </a>
            <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item UserDropdown" href="#">Fee Config</a>
              <a class="dropdown-item UserDropdown" href="#">Student Fee</a>
            </div>
          </li>

      <li className="dropdown-container">
              <a className="nav-link dropdown-toggle navLinks"  onClick={toggleDropdown}
              >
               Courses
              </a>
              {/* <h1  style={{color:"black"}}>{isOpen ? 'Open' : 'Closed'}</h1>  */}
              {isOpen && (
                <li className="dropdown-menu_ ">
                  {courses.map((course) => (
                    <li key={course.Course_id}>
                      <a
                        className={`nav-link navLinks dropdown-item_ ${selectedCourse === course.Course_id ? 'active' : ''}`}
                        onClick={() => handleCourseSelection(course.Course_id)}
                        style={{padding:"0"}}
                      >
                        {/* {course} */}
                        <span style={{width:"30vh",padding:"1vh"}} className="dropdown-toggle course-text">{course.coursename}</span>
                      </a>
                      {isOpen2 &&  selectedCourse === course.Course_id && (
                        <li className="nested-dropdown-menu_">
                          <Link to="/ManageContent"  style={{color:"#03b4c6",paddingLeft:"4vh"}}className=" dropdown-item_ ">Manage Content</Link> <br/>
                          <Link to="/ManageClasses" style={{color:"#03b4c6" ,paddingLeft:"4vh"}} className="dropdown-item_ ">Manage Classes</Link><br/>
                          <Link to="#" style={{color:"#03b4c6" ,paddingLeft:"4vh"}} className="dropdown-item_ ">Student Progress</Link><br/>
                          <Link to="/TeacherAttendClass" style={{color:"#03b4c6" ,paddingLeft:"4vh"}} className="dropdown-item_ ">Attend Class</Link><br/>
                        </li>
                      )}
                    </li>
                  ))}
                </li>
              )}
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Teachers
        </a>
        <div class="dropdown-menu" style={{border:"1px  solid #03b4c6",backgroundColor:"#edffff"}} aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item UserDropdown" href="#">Profile</a>
          <a class="dropdown-item UserDropdown" href="#">Rating</a>
          <a class="dropdown-item UserDropdown" href="#">Salary</a>
        </div>
      </li>

      <li class="nav-item ">
              <a class="nav-link navLinks" href="#">Gift Redeem</a>
      </li>

      <li class="nav-item ">
              <a class="nav-link navLinks" href="#">Communication</a>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {username}
        </a>
        <div class="dropdown-menu user-profile-dropdown" aria-labelledby="navbarDropdownMenuLink">
          <Link class="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
          <Link class="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
          <Link class="dropdown-item UserDropdown" to="/AdminEdit" >Edit Profile</Link>

        </div>
      </li>
      </ul>

        </div>
      </div>
    </nav>

    </div>
  )
}

export default AdminNav
