import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import "./CodingCourseDetails.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./WebDev.css";
import "./ScratchProgramming.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CodingCourseImages/webdev.png";
import pic2 from "./CodingCourseImages/menus.gif";
import pic3 from "./CodingCourseImages/dropdownMenu.png";
import pic4 from "./CodingCourseImages/signUp.png";
import pic5 from "./CodingCourseImages/jsForm.png";
import pic6 from "./CodingCourseImages/HTML.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';

const WebDev = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
   
  return (
   
    <div >
      
     {/* Navbar */}
     {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 
      <div className="Webdev-Background">
        <section className="section">
          <p className="PageHead">Web Development Course</p>
          <div className="PageDesc">
            <p>
            A comprehensive course for kids to learn remarkable, Interactive websites using HTML, CSS and Java Script. Polymath-Kids offers the Basic Web Development Course for kids from Grade 1 to 3 and Responsive Web Development Course for Grade 4+. Students will learn the principles of Website creation through website building Projects and can also share their websites with others.
            </p>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">
              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className="btn-row-resp button btn-color-course"
                  href="#course"
                >
                  Course Structure
                </a>
              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">
                  Fee Structure
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-Testimonal"
                  href="#testimonal"
                >
                  Testimonals
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                  Faqs
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-registered"
                  href="#"
                >
                  Get Registered
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="overlay"></div>
      </div>

      {/* Intro text */}
      <div className="scratch-background">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>
          What is Web Development?
          </p>
          <div className="PageDesc">
            <p
              style={{
                color: "#8b0194",
                marginTop: "2vh",
                fontSize: "4vh",
                fontFamily: "sans serif",
              }}
            >
            We live in a world where information is generated instantly through various websites. Kids are using a variety of websites such as Google, YouTube and gaming sites for educational and entertainment purposes. Knowing how these websites are created and maintained can be beneficial for kids in a variety of ways.

Polymath-Kids offer two courses for Web Development for kids, to help them create fun and informative websites. The two courses offered are Web Development Beginner Course and Responsive Web Development Course.

Students in Web Development Beginner Course will learn how to create their own simple Web page. There are 5 billion internet users around the globe, but only a small percentage of them can create their own websites. Polymath-Kids help kids to create and manage their own websites with the help of trained Expert Instructors. Polymath-Kids' Web Development Beginner Course is suitable for students of Grade 1 to 3, where they will learn the basics of HTML and CSS to create a simple Webpage.

Responsive Web Development course is suitable for students, Grade 4 and above, interested in designing their own websites. Students in Web Interfaces learn how to create stunning, interactive websites utilizing a variety of tools and techniques such as HTML, CSS and Java Scripts. They will learn different techniques to customize, organize, and style their web pages through engaging projects. By the end of the course, students will have a strong grip over web development in three fundamental programming languages HTML, CSS and Java Scripts.
            </p>
          </div>
        </section>
      </div>

      {/* Course Structure and Syllabus */}
      <section className="section course-structure" id="course">
        <p className="PageHead" style={{ color: "#8b0194" }}>
          Course Structure and Syllabus
        </p>
        <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Course Objectives</b>
        </h4>
        <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <li>Website creation conventions and principles</li>
          <li>Basic HTML and CSS </li>
          <li> Advanced HTML and CSS </li>
          <li>Adding text, images, audio, videos</li>
          <li>JavaScript and JavaScript Object Model  </li>
          <li> Hands-on website building projects </li>
        </ul>

        <div>
          <p className='moduleSection' >
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            Web Development Beginner Course
          </p>
        </div>

        {/* Module one */}
        <div className="modules">
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
            Introduction to HTML 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                Youngsters will learn about the basics of Web Development interface. They will use different tags and elements to design the webpage. After the completion of this module, they will be able to create a simple web page.
              </p>
            </p>
          </div>
        </div>

        {/* Module  two */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>11</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            Introduction to CSS 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                Students will be introduced with the styling of web page. They will learn to add the esthetics to their sites including text, background, tables etc. After the completion of this module, they will be able to apply the formatting to a simple web page.
              </p>
            </p>
          </div>
        </div>

        {/* Module one */}
        <div>
          <p className='moduleSection' style={{color: "#485cd9"}}>
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            Responsive Web Development Course
          </p>
        </div>

        <div className="modules">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Fundamentals of HTML
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                Module 1 introduces the basics of Web Development interface. Kids will learn to use different tags and elements to design the webpage. At the completion of this module, kids will be able to create a simple web page.
              </p>
            </p>
          </div>
        </div>

        {/* Module two */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            ADVANCE HTML 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
               In this module students will learn the advance concepts of HTML such as forms, button, hyperlinks, Inline and block elements. At the completion of this module, they will be able to create a website with different links.
              </p>
            </p>
          </div>
        </div>

        {/* Module three */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              3
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Introduction to CSS 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module introduces the styling of web page. Kids will learn to add esthetics to their sites by including text, background and tables. At the completion of this module, they will be able to apply formatting to a simple web page  
              </p>
            </p>
          </div>
        </div>
        {/* Module four */}
        <div className="modules my-3">
                {/* round box */}
                <div className="round-box round-box1 ">
                    <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                    {" "}
                    Module
                    <br />
                    4
                    </p>
                </div>
                {/* small boxes */}
                <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                    <p style={{ margin: "auto" }}>
                    Activities
                    <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
                    </p>

                    <div
                    className="small-box small-box1 sm-box"
                    >
                    <p style={{ margin: "auto" }}>
                        No.of Lessons
                        <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
                    </p>
                    </div>
                </div>

                <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                    <p style={{ margin: "auto" }}>
                    ADVANCE CSS 
                    <p
                        style={{
                        fontWeight: "lighter",
                        fontSize: "15px",
                        marginBottom: "0px",
                        }}
                    >
                        In this module kids will learn the Advance CSS styling. They will work with Box model and Hyperlinks, navigation bars and dropdowns. Also, they will learn the advance concepts of animation and transition in websites.
                    </p>
                    </p>
                </div>
        </div>
        {/* Module five */}
        <div className="modules my-3">
                        {/* round box */}
                        <div className="round-box round-box1 ">
                            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                            {" "}
                            Module
                            <br />
                            5
                            </p>
                        </div>
                        {/* small boxes */}
                        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                            <p style={{ margin: "auto" }}>
                            Activities
                            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
                            </p>

                            <div
                            className="small-box small-box1 sm-box"
                            >
                            <p style={{ margin: "auto" }}>
                                No.of Lessons
                                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                            </p>
                            </div>
                        </div>

                        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                            <p style={{ margin: "auto" }}>
                            Introduction to JavaScript 
                            <p
                                style={{
                                fontWeight: "lighter",
                                fontSize: "15px",
                                marginBottom: "0px",
                                }}
                            >
                            This module is all about JavaScript. Students will learn the concepts of Variables; they will get familiar with the term “Global and local variable”. They will use different conditions and loops.
                            </p>
                            </p>
                        </div>
        </div>
        {/* Module six */}
        <div className="modules my-3">
                        {/* round box */}
                        <div className="round-box round-box1 ">
                            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                            {" "}
                            Module
                            <br />
                            6
                            </p>
                        </div>
                        {/* small boxes */}
                        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
                            <p style={{ margin: "auto" }}>
                            Activities
                            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
                            </p>

                            <div
                            className="small-box small-box1 sm-box"
                            >
                            <p style={{ margin: "auto" }}>
                                No.of Lessons
                                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
                            </p>
                            </div>
                        </div>

                        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
                            <p style={{ margin: "auto" }}>
                            JavaScript Object Model 
                            <p
                                style={{
                                fontWeight: "lighter",
                                fontSize: "15px",
                                marginBottom: "0px",
                                }}
                            >
                            In this module, students will learn the JavaScript Object Models. They will work with arrays and event handlers. They will be able to create and validate forms in JavaScript. 
                            </p>
                            </p>
                        </div>
        </div>
        <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Learn Web Development with 70+ Fun Activities</b>
        </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className="mx-3">FM Radio App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages' />
              <p className="mx-3">Light Bulb App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className="mx-3">Walking Step App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className="mx-3">Animal Sound App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic6} alt="" className='ActivityImages'/>
              <p className="mx-3">Dictionary</p>
            </div>

          </div>
        </div>
      </section>

      {/* Fee Structure */}
      <FeeStructure subject="Coding"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with EduTechSage-Abacus.
                    The teacher was very friendly. I highly recommend
                    EduTechSage-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
        <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            <div className="text-center PageHead" style={{ color: "#8b0194" }}>
              {" "}
              FAQs
            </div>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="Q: What is web development?"
            >
              <strong>A: </strong>
              Web development is an important skill and refers to how websites are designed, created, build, and maintained over the internet. Web development involves using different technologies such as HTML, CSS and JavaScript. The process of learning Web Development involves the web designing, web programming, and database management.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Q: What is web development for kids?"
            >
              <strong>A: </strong>
              
              Web developments for Kids include learning to build interactive and responsive websites with a range of functionalities. Kids can create useful websites such as blogging sites, sites for providing information (school project or personal interest) or websites just for fun. They can reach the world through their websites.  
            The three aspects of web development are: front-end, back-end, full-stack development.
            <li>

                Front-end development involves the visuals of the website across devices – such as fonts, colors, images and icons
            </li>
            <li>

            Back-end development involves writing and maintaining the code that runs the website. It connects the website with the servers to ensure smooth flow of data and transactions. 
            </li>
            <li>

                Full-stack development involves both responsibilities of building and maintaining both front-end and back-end code of the website.
            </li>
            Polymath-Kids offer an interactive and fun way of learning Web Development for kids. The platform offers  a range of activities and projects to help kids learn the foundations and build onto it. Our expert staff ensures that kids are able to reap maximum benefit that technology has to offer.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Can a child be a web developer?"
            >
              <strong>A: </strong>
              Yes, Ofcourse! A child can be a web developer. Children as young as age 5 can begin coding, using helpful tools. As they progress and become proficient in HTML, CSS, and Javascript, they can make their own websites and share their expressions with the world.
                <br />
                To help a child take interest in Web development, let the child choose a topic that they are passionate about such as sports, movies, favorite cartoon character. Help them select a domain name, writing codes using HTML and decorate using CSS.
                <br />
                At Polymath-Kids, we ensure that each child gets the in-depth knowledge of Web Development and is able to apply the knowledge gained through online classes. Therefore we use a variety of STEM related activities and projects to help kids learn in a creative fun way. 
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What are the two different courses of Web Development offered by Polymath-Kids?"
            >
              <strong>A: </strong>
             
              Polymath-Kids offer Web Development Beginner Course for students from grade 1 to 3. In this course, young students will learn to create and manage their own simple webpage.
            Whereas Responsive Web Development Course id suitable for Grade 4 and above students. It is an detailed course that help students learn how to create and manage their interactive websites using HTML, CSS and JavaScript.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Is there a pre- requisite course for taking the web development courses offered by Polymath-Kids?"
            >
              <strong>A: </strong>
              There is no pre-requisite for taking any of the two courses offered by Polymath-Kids. The complete knowledge of creating a web page is given to the students from scratch.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What are the benefits of web development courses for kids?"
            >
              <strong>A: </strong>
              There are numerous reasons why it is valuable for kids to learn web development at an early age.
              <li>
            Kids are watching various websites for entertainment and study purposes, learning to build a website on their own increases their confidence.
                    </li>
                    <li>
            It teaches them how to think like a coder, think creatively and solve problems.
                    </li>
                    <li>
            It boosts their communication skills a coding involves giving clear instruction to computer in order to carry out a task. Kids learn the value of communicating clearly and impact of miscommunication.
                    </li>
                    <li>
            It nurtures the child creativity in visualizing different elements, topics, colors of the website that synchronize to give a perfect look for their website.
                    </li>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What are the best web development courses for kids?"
            >
              <strong>A: </strong>
              You will find many free platforms and videos which can be helpful in teaching Web Development to kids. But the lack of mentorship and direct guidance can hinder their progress.
              Polymath-Kids offers age appropriate Web Development courses to help your child build and maintain their websites through a structured approach. Kids can show case their creativity to their friends, teachers and others.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Where to learn HTML, CSS and JavaScript?              "
            >
              <strong>A: </strong>
              Kids aged 6 to 16 years can learn HTML, CSS and JavaScript through the finest platform offered by Polymath-Kids, where each child is provided special attention to ensure smooth learning and development. 
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What is HTML, CSS and JavaScript?"
            >
              <strong>A: </strong>
              
              HTML, or HyperText Markup Language, is the foundation of all websites. It’s the main file type that is loaded in your browser when you look at a website. The HTML file contains all the content on a particular page, and it uses tags to denote different types of content.
            <br />
            CSS, or Cascading Style Sheets, lets you style the HTML content so it looks elegant. You can add colors, custom fonts, and layout the elements of your website any way you like. You can also create animations and shapes with CSS.
            <br />
            JavaScript is a programming language that was designed to run in the browser. Using JavaScript, you can make your website dynamic, meaning it will respond to different inputs from the user, or other sources.
            </MDBAccordionItem>


           
          </MDBAccordion>
        </MDBContainer>
      </div>
      
{/* Footer */}
<Footer/>

    </div>
  )
}

export default WebDev
