import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router,Switch,Route,Navigate} from 'react-router-dom';
import { Routes  } from 'react-router-dom';
import { useEffect } from "react";
import './App.css';
import Home from './MyComponents/Home';
import MathCourseDetails from './MyComponents/MathPortal/MathCourseDetails';
import BenefitDetail from './MyComponents/MathPortal/BenefitDetail';
import HighlightsDetail from './MyComponents/MathPortal/HighlightsDetail';
import CodingCourseDetails from './MyComponents/CodingPortal/CodingCourseDetails';
import ScratchProgramming from './MyComponents/CodingPortal/ScratchProgramming';
import AppDev from './MyComponents/CodingPortal/AppDev';
import WebDev from './MyComponents/CodingPortal/WebDev';
import GameDev from './MyComponents/CodingPortal/GameDev';
import PHPDev from './MyComponents/CodingPortal/PHPDev';
import BusinessCourseDetails from './MyComponents/BusinessPortal/BusinessCourseDetails';
import KidPreneur from './MyComponents/BusinessPortal/KidPreneur';
import QuranCourseDetails from './MyComponents/QuranPortal/QuranCourseDetails';
import GraphicCourseDetails from './MyComponents/GraphicDesignPortal/GraphicCourseDetails';
import AdobeXD from './MyComponents/GraphicDesignPortal/AdobeXD';
import CorelDraw from './MyComponents/GraphicDesignPortal/CorelDraw';
import PhotoShop from './MyComponents/GraphicDesignPortal/PhotoShop';
import Canva from './MyComponents/GraphicDesignPortal/Canva';
import Illustrator from './MyComponents/GraphicDesignPortal/Illustrator';
import Vision from './MyComponents/FooterPages/OurVision';
import PolymathKidSchool from './MyComponents/FooterPages/PolymathKidSchool';
import AboutUs from './MyComponents/FooterPages/AboutUs';
import TermsOfUse from './MyComponents/FooterPages/TermsOfUse';
import Cancellation from './MyComponents/FooterPages/Cancellation';
import Policy from './MyComponents/FooterPages/Policy';
import ContactUs from './MyComponents/FooterPages/ContactUs';
import EngineeringCourseDetails from './MyComponents/EngineeringPortal/EngineeringCourseDetails';
import CivilEngineering from './MyComponents/EngineeringPortal/CivilEngineering';

// import { UserProvider } from './MyComponents/UserContext';

import UserContextProvider from './MyComponents/UserContext';
import CourseContext from './MyComponents/CourseContext';

import ArchitectureCourseDetails from './MyComponents/ArchitecturePortal/ArchitectureCourseDetails';
import SpaceSciencesCourseDetails from './MyComponents/SpaceSciencePortal/SpaceSciencesCourseDetails';
import Astronomy from './MyComponents/SpaceSciencePortal/Astronomy';


import RegisterInCourse from './MyComponents/Actions/RegisterInCourse';
import AttendClass from './MyComponents/Actions/AttendClass';
import MyProgress from './MyComponents/Actions/MyProgress';
import TeacherAttendClass from './MyComponents/Actions/TeacherAttendClass';
import { useState } from 'react';
import ManageClasses from './MyComponents/AdminActions/ManageClasses';
import User from './MyComponents/ManageUser/User';
import CourseRegistration from './MyComponents/AdminActions/CourseRegistration';


import WrittenActivity from './MyComponents/Activities/WrittenActivity/WrittenActivity';
import MCQActivity from './MyComponents/Activities/MCQActivity/MCQActivity';
import SpeedQuizActivity from './MyComponents/Activities/SpeedQuizActivity/SpeedQuizActivity';
import MentalActivity from './MyComponents/Activities/MentalActivity/MentalActivity';
import OralActivity from './MyComponents/Activities/OralActivity/OralActivity';
import VideoActivity from './MyComponents/Activities/VideoActivity/VideoActivity';

import AdminRegisterInCourse from './MyComponents/AdminActions/AdminRegisterInCourse';
import ActivitiesAndProjects from './MyComponents/Actions/ActivitiesAndProjects';
import IslamicKnowledgeCourse from './MyComponents/QuranPortal/IslamicKnowledgeCourse';
import ABCHome from './MyComponents/ABCHome';
import ABCTest from './MyComponents/ABCTest';
import ProtectedRoute from './MyComponents/ProtectedRoute';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Password from './MyComponents/Actions/Password';
import AdminEdit from './MyComponents/Actions/AdminEdit';
import EditProfile from './MyComponents/Actions/EditProfile';
import Teacher from './MyComponents/Actions/Teacher';
import Managecontent from './MyComponents/ManageContent/managecontent';
import Faqs from './MyComponents/FooterPages/Faqs';
import AIDataScience from './MyComponents/CodingPortal/AIDataScience';
import Python from './MyComponents/CodingPortal/Python';
import CPP from './MyComponents/CodingPortal/CPP';
import Unity from './MyComponents/CodingPortal/Unity';
import ML from './MyComponents/CodingPortal/ML';
import CommunicationCourseDetails from './MyComponents/CommunicationPortal/CommunicationCourseDetails';
import CPS from './MyComponents/CommunicationPortal/CPS';
import ScrollToTop from './MyComponents/components/ScrollToTop';

function App() {
  const [Course, setCourse] = useState('');
  const handleCourseSelect = (course) => {
    // Do something with the selected course
    console.log("Selected Course in App:", course);
    setCourse(course);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      });
      const userInfo = await response.json();
      const uType = userInfo?.usertype;
      return uType;
    } catch (error) {
      console.log('Error in fetchData:', error);
      return null;
    }
  };


  const ProtectedRoute = ({ path, allowedRoles, component }) => {
    const [userType, setUserType] = useState(null);

    useEffect(() => {
      fetchData().then((type) => {
        setUserType(type);
      });
    }, []);


    if (userType === null)
    {
      return <div style={{textAlign:"center"}}>Loading...</div>;
    }
    console.log('userType1:', userType);

    const isAllowed = allowedRoles.includes(userType);

    console.log(isAllowed);
    console.log('userType:', userType);
    console.log(allowedRoles);
    console.log("Component:",component)
    return isAllowed ? (
      <>
      {/* console.log({component}); */}
      <div>{component}</div>

      </>
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  };

  return (
    <>
       <ToastContainer />
    <Router >
       <UserContextProvider>
       <ScrollToTop />
        <Routes>
    {/* <Route path='/' element={<ABCTest/>}/> */}
    <Route path="/" element={<Home   selectedCourse={handleCourseSelect}/>}/>
    <Route path='/ManageContent' element={<Managecontent/>}/>
    {/* Student Actions */}
    <Route path="/MyProgress" element={<MyProgress/>}/>
    <Route path="/AttendClass" element={<AttendClass/>}/>
    <Route path="/RegisterInCourse" element={<RegisterInCourse/>}/>
    <Route path="/ActivitiesAndProjects" element={<ActivitiesAndProjects/>}/>

    {/* teacher Actions */}
    <Route path="/TeacherAttendClass" element={<TeacherAttendClass selectedCourse={Course}  />}/>

    {/* Admin Actions */}
    <Route
      path="/ManageClasses"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<ManageClasses/>}/>}
    />
    <Route
      path="/ManageUser"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<User/>}/>}
    />
    <Route
      path="/CourseRegistration"
      element={<ProtectedRoute allowedRoles={['Admin']} component={<CourseRegistration/>}/>}
    />
    <Route
      path="/ChangePassword"
      element={<ProtectedRoute   allowedRoles={['Admin','Student','Teacher']} component={<Password/>}/>}
    />
    <Route
      path="/AdminEdit"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<AdminEdit/>}/>}
    />
    <Route
      path="/EditProfile"
      element={<ProtectedRoute   allowedRoles={['Student']} component={<EditProfile/>}/>}
    />
    <Route
      path="/TeacherEditProfile"
      element={<ProtectedRoute   allowedRoles={['Teacher']} component={<Teacher/>}/>}
    />
    <Route
      path="/AdminRegisterInCourse"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<AdminRegisterInCourse/>}/>}
    />
    <Route
      path="/CourseRegistration"
      element={<ProtectedRoute   allowedRoles={['Admin']} component={<CourseRegistration/>}/>}
    />


    {/* Math  Portal */}
    <Route path="/MathCourseDetails" element={<MathCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/BenefitDetail" element={<BenefitDetail selectedCourse={handleCourseSelect}/>}/>
    <Route path="/HighlightsDetail" element={<HighlightsDetail selectedCourse={handleCourseSelect}/>}/>

    {/* Coding Portal */}
    <Route path="/CodingCourseDetails" element={<CodingCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/ScratchProgrammingDetails" element={<ScratchProgramming selectedCourse={handleCourseSelect}/>}/>
    <Route path="/AppDev" element={<AppDev  selectedCourse={handleCourseSelect}/>}/>
    <Route path="/WebDev" element={<WebDev  selectedCourse={handleCourseSelect}/>}/>
    <Route path="/GameDev" element={<GameDev selectedCourse={handleCourseSelect}/>}/>
    <Route path="/PHPDev" element={<PHPDev selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Python" element={<Python selectedCourse={handleCourseSelect}/>}/>
    <Route path="/CPP" element={<CPP selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Unity" element={<Unity selectedCourse={handleCourseSelect}/>}/>
    <Route path="/ML" element={<ML selectedCourse={handleCourseSelect}/>}/>
    <Route path="/AIDataScience" element={<AIDataScience selectedCourse={handleCourseSelect}/>}/>

    {/* Business Portal */}
    <Route path="/BusinessCourseDetails" element={<BusinessCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/KidPreneur" element={<KidPreneur selectedCourse={handleCourseSelect}/>}/>

    {/* Quran Portal */}
    <Route path="/QuranCourseDetails" element={<QuranCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/IslamicKnowledgeCourse" element={<IslamicKnowledgeCourse selectedCourse={handleCourseSelect}/>}/>

    {/* Graphic Designing Portal */}
    <Route path="/GraphicCourseDetails" element={<GraphicCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/AdobeXD" element={<AdobeXD selectedCourse={handleCourseSelect}/>}/>
    <Route path="/CorelDraw" element={<CorelDraw selectedCourse={handleCourseSelect}/>}/>
    <Route path="/PhotoShop" element={<PhotoShop selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Canva" element={<Canva selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Illustrator" element={<Illustrator selectedCourse={handleCourseSelect}/>}/>

    {/* Engineering Portal */}
    <Route path="/EngineeringCourseDetails" element={<EngineeringCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/CivilEngineering" element={<CivilEngineering selectedCourse={handleCourseSelect}/>}/>


    {/* Architecture Portal */}
    <Route path="/ArchitectureCourseDetails" element={<ArchitectureCourseDetails selectedCourse={handleCourseSelect}/>}/>


    {/* Space Science Portal */}
    <Route path="/SpaceSciencesCourseDetails" element={<SpaceSciencesCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Astronomy" element={<Astronomy selectedCourse={handleCourseSelect}/>}/>


    {/* communication Portal */}
    <Route path="/CommunicationCourseDetails" element={<CommunicationCourseDetails selectedCourse={handleCourseSelect}/>}/>
    <Route path="/CPS" element={<CPS selectedCourse={handleCourseSelect}/>}/>

    {/*Footer Pages  */}

    <Route path="/ourVision" element={<Vision selectedCourse={handleCourseSelect}/>}/>


    <Route path="/PolymathKidSchool" element={<PolymathKidSchool selectedCourse={handleCourseSelect}/>}/>
    <Route path="/AboutUs" element={<AboutUs selectedCourse={handleCourseSelect}/>}/>
    <Route path="/TermsOfUse" element={<TermsOfUse selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Cancellation" element={<Cancellation selectedCourse={handleCourseSelect}/>}/>
    <Route path="/Policy" element={<Policy selectedCourse={handleCourseSelect}/>}/>
    <Route path="/ContactUs" element={<ContactUs selectedCourse={handleCourseSelect}/>}/>


    <Route path="/Faq" element={<Faqs selectedCourse={handleCourseSelect}/>}/>


    {/* Mohsin Routes */}
    <Route path="/WrittenActivity" element={<WrittenActivity />}/>
      <Route path="/MCQActivity" element={<MCQActivity activityId={4}/>}/>
      <Route path="/SpeedQuizActivity" element={<SpeedQuizActivity activityId={4}/>}/>
      <Route path="/MentalActivity" element={<MentalActivity activityId={4}/>}/>
      <Route path="/OralActivity" element={<OralActivity activityId={4}/>}/>
      <Route path="/VideoActivity" element={<VideoActivity activityId={1}/>}/>
      
        </Routes>
    </UserContextProvider>
  </Router>

    </>
  );
}

export default App;
