
import React,{useContext} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import TeacherNav from "../Navbars/TeacherNav";

import './MyProgress.css'
import pic1 from "../CourseImages/progress.png";


const MyProgress = () => {
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole } = useContext(UserContext);
    const determineUserRole = (email, password) => {
      // console.log('Email:', email);
      // console.log('Password:', password); 
  
          return "teacher";
    };
    const onLogin = (email, password) => {
      const userRole = determineUserRole(email, password);
      setUserRole(userRole);
    };
  
    return (
      <>
        {/* Navbar */}
        {isGuest && <GuestNav onLogin={onLogin} />}
        {isAdmin && <AdminNav />}
        {isTeacher && <TeacherNav />}
        {isStudent && <StudentNav />}
 
    <div style={{backgroundColor:"#edffff",height:"70vh"}}>

    <div className='coursesDropDown'>
        <select name="" id="" className='coursesOption'>
            <option value="">Coding - scratch programming</option>
        </select>
    </div>

    <div className='progress'>
        <span className='progressHead'>
            <img src={pic1} alt="" style={{width:"10%",height:"10%",margin:"2%"}} />
            <h3 style={{fontSize:"5vh"}}>Student - Progress </h3> <br />
        </span>
            <h6 style={{marginLeft:"25%"}} >Coding Scratch Programming</h6>
<br />
        <div className='progressNumbers'>
            <div className='labels'>
                <label htmlFor="" >Certificate(s)</label>
                <label htmlFor="" >Remarks</label>
                <label htmlFor="" >Avg. score Activities</label>
                <label htmlFor="" >Avg. score Project</label>
                <label htmlFor="" >Points Earned</label>
                <label htmlFor="" >Course Completion</label>
            </div>
           
        </div>
 <div className='progressNumbers'>
                <div className='MyProgressRemarks'> 
                </div>
                <div className='MyProgressRemarks'>
                <i style={{color:"#da831e",fontSize:"4vh"}} class="fa-regular fa-message"></i>

                </div>
                <div className='MyProgressRemarks'>
                <b style={{color:"#da831e"}}>0%</b>

                </div>

                <div className='MyProgressRemarks' style={{marginLeft:"5%"}}>
                <b style={{color:"#da831e"}}>0%</b>

                </div>
                <div className='MyProgressRemarks' style={{marginLeft:"3%"}}>
                    <b style={{color:"#da831e"}}>0%</b>
                </div>
                <div className='MyProgressRemarks' style={{marginLeft:"5%"}}>
                <b style={{color:"#da831e"}}>0%</b>

                </div>
            </div>

    </div>

    </div>
    
    <Footer/>
    </>
  )
}

export default MyProgress
