import './Teacher.css'
import React,{useContext,useState,useEffect} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import TeacherNav from "../Navbars/TeacherNav";
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import axios from 'axios';

const AvailabilityForm = ({ days ,teacherID,availabilityTimes}) => {
  const [selectedTimes, setSelectedTimes] = useState({});
  // console.log("AV",availabilityTimes);
  const [selectedAvailabilityTimes, setSelectedAvailabilityTimes] = useState([]);

  useEffect(() => {
    if (availabilityTimes && availabilityTimes.length > 0) {
      setSelectedAvailabilityTimes(availabilityTimes);
    }
  }, [availabilityTimes]);
  
  const handleTimeSelect = (day, time) => {
    const updatedTimes = [...selectedAvailabilityTimes];
  
    const existingTimeIndex = updatedTimes.findIndex((item) => item.day === day && item.time === time);
  
    if (existingTimeIndex > -1) {
      // Time already selected, remove it from the selected times
      updatedTimes.splice(existingTimeIndex, 1);
    } else {
      // Time not selected, add it to the selected times
      updatedTimes.push({ day, time });
    }
  
    setSelectedAvailabilityTimes(updatedTimes);
    // console.log(updatedTimes);
  };
  
  const handleSave = async() => {
    // Prepare the data to send in the API body
    const data = {
      availability: selectedAvailabilityTimes, // Object of selected times for each day
    };
    // console.log("selectedAvailabilityTimes:",selectedAvailabilityTimes);
    // console.log(teacherID);
    
   await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/editTeacherAvailability/${teacherID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedAvailabilityTimes),
    })
      .then((response) => response.json())
      .then((responseData) => {
        toast.success('Preferences has changed.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        // console.log(responseData);
      })
      .catch((error) => {
        toast.error('Preferences has not changed. Something went wrong', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        console.error('Error making API call:', error);
      });
  };
   
  return (
    <div className='courses'>
      <label>
        <b>Please select your availability below and click the Save button on the right:</b>
      </label>
      
      <br />
      {days.map((day) => (
        <div key={day} style={{ float: 'left', backgroundColor: '#72c6ca' }} className="monday">
          {/* <input type="checkbox" id='bg1' 
          // value={day}
           checked={selectedAvailabilityTimes.some((time) => time.day === day )}
          
          /> */}
          <label>{day}</label> <br />

          <input type="checkbox" id='bg1' 
          // value='6am - 9am'
          value={day}
           onChange={() => handleTimeSelect(day, '6am - 9am')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '6am - 9am')}
          />
          <label>6am - 9am</label> <br />

          <input type="checkbox" id='bg1'  
          // value='9am - 12pm'
          value={day}

           onChange={() => handleTimeSelect(day, '9am - 12pm')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '9am - 12pm')}

          />
          <label>9am - 12pm</label> <br />

          <input type="checkbox" id='bg1' 
          value={day}
          // value='12pm - 3pm'
           onChange={() => handleTimeSelect(day, '12pm - 3pm')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '12pm - 3pm')}

          />
          <label>12pmm - 3pm</label> <br />

          <input type="checkbox" id='bg1'
          // value='3pm - 6pm'
          value={day}

           onChange={() => handleTimeSelect(day, '3pm - 6pm')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '3pm - 6pm')}

          />
          <label>3pm - 6pm</label> <br />

          <input type="checkbox" id='bg1'
          // value='6pm - 9pm'
          value={day}

           onChange={() => handleTimeSelect(day, '6pm - 9pm')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '6pm - 9pm')}

          />
          <label>6pm - 9pm</label> <br />

          <input type="checkbox" id='bg1' 
          // value='9pm - 12am'
          value={day}

           onChange={() => handleTimeSelect(day, '9pm - 12am')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '9pm - 12am')}
          />
          <label>9pm - 12am</label> <br />

          <input type="checkbox" id='bg1' 
          // value='12am - 3am'
          value={day}

           onChange={() => handleTimeSelect(day, '12am - 3am')}
           checked={selectedAvailabilityTimes.some((time) => time.day === day && time.time === '12am - 3am')}
           />
          <label>12am - 3am</label> <br />
        </div>
      ))}

      <button className='btn2' style={{ padding: "1% 6% 1% 6%", marginTop: "15%" }} onClick={handleSave}>Save</button>
    </div>
  );
};

const Teacher = () => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];  
  const [user, setUser] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [teacherID, setTeacherId] = useState();
  const [availabilityTimes, setAvailabilityTimes] = useState([]);


  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
  useEffect(() => {
    const fetchData = async () => {
     await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
          // console.log("Response in TeacherEditProfile:",response);
        response.json().then(userInfo => {
          // console.log("userinfo in TeacherEditProfile:",userInfo);
          setUserInfo(userInfo);
          // setuser_id(userInfo?.User_id);
          // console.log("user_id :",user_id);
          fetchUser(userInfo?.User_id); 
          fetchTeacherId(userInfo?.User_id);  
          fetchCoursesData();
          

          if(userInfo?.usertype)
          {
            const uType=userInfo?.usertype;
            setUserRole(uType);
          }
        const uname = userInfo?.username;
        // const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        // console.log("Uname TeacherEditprofile: ", uname);
        // console.log("Avatar: ", avatar);
 
        })
        .catch(err=> console.log("Error in useeffect:",err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
 
 
  const navigate=useNavigate();

  async function onLogout() {
    
  await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    navigate('/');
    setUserRole('guest');
  }
  const fetchUser = async (userId) => {
    // console.log("USERINFO: ",userInfo);
    // console.log("userId: ",userId);
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUser/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data:",data)
        // console.log("data.user:",data.user)
        setUser(data.user);
        // console.log(formattedDateOfBirth);
        setFormValues((prevValues) => ({
          ...prevValues,
          firstName: data.user.firstName || '',
          lastName: data.user.lastName || '',
          address: data.user.address || '',
          phonenumber: data.user.phonenumber || '',
          parentname: data.user.parentname || '',
          dateOfBirth: data.user.dateofbirth || '',
          gender: data.user.gender || '',
          profileImage: null,
        }));
        setDate(data.user.dateofbirth);
        // formattedDateOfBirth = new Date(data.user.dateofbirth).toLocaleDateString('en-CA');
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
   };
   
  const [formattedDateOfBirth, setFormatedDate] = useState();
   const setDate = (DOB) => {
    // console.log("DOB",DOB);
    setFormatedDate(new Date(DOB).toLocaleDateString('en-CA'));
    // console.log("formattedDateOfBirth",formattedDateOfBirth);

   };

   const handlePersonalUpdate = async (e) => {
    e.preventDefault();
  //   console.log("formValues",formValues)
    // console.log("userid:",userInfo?.User_id)

    const updatedData = {
      username:'',
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      address: formValues.address,
      phonenumber: formValues.phonenumber,
      parentname: formValues.parentname,
      dateOfBirth: formValues.dateOfBirth,
      gender: formValues.gender,
      avatar: formValues.profileImage,
    };
  
    // Make the PUT request to the API
  await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/editUser/${userInfo?.User_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        // console.log('Update successful:', data);
        toast.success('Successfully Updated Personal Details.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        // Handle errors
        console.error('Update failed:', error);
        toast.error('Personal details are not correct.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });
  };
  /////////////////////////Bank Details fetch ///////////////////////////////////////
  const fetchTeacherId = async (userId) => {
    // console.log("In fetchTeacherId.");
    // console.log("userId: ",userId);
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherId/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data:",data)
        // console.log("data.teacherid:",data.teacherId);

        fetchBankDetails(data.teacherId);
        fetchAvailability(data.teacherId);
        fetchEducationData(data.teacherId);
        fetchExperienceData(data.teacherId);
        fetchTeacherCourses(data.teacherId);

        // console.log("fetchTeacherCourses:",teacher_courses);

        
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
   };
   const fetchBankDetails = async (teacherId) => {
    // console.log("In fetchBankDetails.");
    // console.log("teacherId: ",teacherId);
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getBankDetails/${teacherId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data:",data.bankDetails)
        setBankDetails(data.bankDetails);
        setTeacherId(teacherId);
        setBankFormValues((prevValues) => ({
          ...prevValues,
          BankAccountIBAN: data.bankDetails.BankAccountIBAN || '',
          MobileWalletName: data.bankDetails.MobileWalletName || '',
          MobileWalletNo: data.bankDetails.MobileWalletNo || '',
          preference: data.bankDetails.Preference || '',
          BankName: data.bankDetails.BankName || '',
        }));
        // console.log(bankFormValues);
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
  };
   
  const handleBankDetailUpdate = async (e) => {
    e.preventDefault();
      const updatedData = {
        BankAccountIBAN: bankFormValues.BankAccountIBAN,
        MobileWallletName: bankFormValues.MobileWalletName,
        MobileWalletNo: bankFormValues.MobileWalletNo ,       
        preference: bankFormValues.preference,
        BankName:  bankFormValues.BankName 
    };
  // console.log(teacherID);
    // Make the PUT request to the API
  await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/editBankDetails/${teacherID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        // console.log('Update successful:', data);
        toast.success('Successfully Updated Bank Details.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        // Handle errors
        // console.error('Update failed:', error);
        toast.error('Personal details are not correct.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });
  };

  // Availability update/////////////////////////////////////////////////////
  const fetchAvailability = async (teacherId) => {
    // console.log("teacherID:",teacherId);
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherAvailability/${teacherId}`);
      const data = await response.json();
      // console.log(data); // Handle the response data as needed
      setAvailabilityTimes(data.availabilityData);
    } catch (error) {
      console.error('Error fetching teacher availability:', error);
    }
  };
  //Education Details Save//////////////////////////////////////////////////
 
  const [degreeName, setDegreeName] = useState('');
  const [instituteName, setInstituteName] = useState('');
  const [duration, setDuration] = useState('');
  const [yearStart, setYearStart] = useState();
  const [yearEnd, setYearEnd] = useState();
  const [degreeFile, setDegreeFile] = useState('');
  const [educationData, setEducationData] = useState([]);

  // Function to fetch education details from the API
  const fetchEducationData = (teacherId) => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getEducationDetails/${teacherId}`)
      .then(response => {
        const data = response.data;
        // console.log("educationData:",data);
        setEducationData(data.educationDetails);
      })
      .catch(error => {
        console.log('Error fetching education details', error);
      });
    }
  const handleEducationSave = () => {
    // Create an object with the education details
    const educationData = {
      degreeName,
      instituteName,
      duration,
      yearStart,
      yearEnd,
      degreeFile
    };

    // Call the API function to save education details
    saveEducationDetails(educationData);

    // Reset the form fields
    setDegreeName('');
    setInstituteName('');
    setDuration('');
    setYearStart('');
    setYearEnd('');
    setDegreeFile('');
  };

  const saveEducationDetails = (data) => {
    
    // console.log(data,teacherID);
    if(!degreeName || !instituteName || !duration || !yearStart || !yearEnd)
    {
      toast.error('All fileds are required.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    else
    {
      axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveEducationDetails/${teacherID}`, data)
      .then(response => {
        toast.success('Education data has been saved.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        fetchEducationData(teacherID);
        // console.log('Education details saved successfully');
      })
      .catch(error => {
        toast.error('Something went wrong.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        console.log('Error saving education details', error);
      });
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const filePath = URL.createObjectURL(file); // Create a temporary object URL for the file

    setDegreeFile(filePath);
  };
  
  //edit teachere courses//////////////////////////////////////////////////////////
  const [Courses, setCourses] = useState([]);
  const [teacher_courses, setTeacherCourses] = useState({});
  const [selectedTeacherCourses, setSelectedTeacherCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);


  useEffect(() => {
    if (teacher_courses && teacher_courses.length > 0) {
      setSelectedTeacherCourses(teacher_courses);
    }
  }, [teacher_courses]);
  const fetchTeacherCourses = (teacherId) => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherCourses/${teacherId}`)
      .then(response => {
        const data = response.data;
        // console.log('Teacher courses:', data,data.courseData);
        setTeacherCourses(data.courseData);
      })
      .catch(error => {
        console.log('Error fetching teacher courses:', error);
      });
  };
  
  const fetchCoursesData = () => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
      .then(response => {
        const data = response.data;
        // console.log('Courses data:', data.coursesData);
        setCourses(data.courses);
        // Process the courses data as needed
      })
      .catch(error => {
        console.log('Error fetching courses data', error);
      });
  };
  const handleCheckboxChange = (event, courseId, courseName) => {
    // console.log("selectedTeacherCourses:::",selectedTeacherCourses);
    const updatedCourses = [...selectedTeacherCourses];
  
    const existingCourseIndex = updatedCourses.findIndex(
      (course) => course.course_id === courseId
      );
      // console.log("courseId:",courseId)
      // console.log("existingCourseIndex:",existingCourseIndex);
  
    if (existingCourseIndex > -1) {
      // Course already selected, remove it from the selected courses
      updatedCourses.splice(existingCourseIndex, 1);
    } else {
      // Course not selected, add it to the selected courses
      updatedCourses.push({ course_id: courseId, coursename: courseName });
    }
  
    // console.log("updatedCourses:::",updatedCourses);
    setSelectedTeacherCourses(updatedCourses);

  };
  
  const handleCoursesSave = () => {
    // Call the API via axios and send the selectedCourses data
    // console.log(selectedCourses);
    axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/editCoursesChoice/${teacherID}`, selectedTeacherCourses)
      .then(response => {
        toast.success('Courses choice has been changed.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        // console.log('Data saved successfully');
      })
      .catch(error => {
        toast.error('Something went wrong.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        console.log('Error saving data:', error);
      });
  };

  //Experience Details Save//////////////////////////////////////////////////
 
  const [CompanyName, setCompanyName] = useState('');
  const [Designation, setDesignation] = useState('');
  const [DurationOfService, setDurationOfService] = useState('');
  const [YearStart, setYearStart_] = useState();
  const [YearEnd, setYearEnd_] = useState();
  const [ExperienceLetterFile, setExperienceLetterFile] = useState('');
  const [experienceData, setExperienceData] = useState([]);

  // Function to fetch education details from the API
  const fetchExperienceData = (teacherId) => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getExperienceDetails/${teacherId}`)
      .then(response => {
        const data = response.data;
        // console.log("educationData:",data);
        setExperienceData(data.experienceDetails);
      })
      .catch(error => {
        console.log('Error fetching education details', error);
      });
    }
  const handleExperienceSave = () => {
    // Create an object with the education details
    const experienceData = {
      CompanyName,
      Designation,
      DurationOfService,
      YearStart,
      YearEnd,
      ExperienceLetterFile
    };
     // console.log(data,teacherID);
     if(!experienceData.CompanyName || !experienceData.Designation
       || !experienceData.DurationOfService || !experienceData.YearStart
       || !experienceData.YearEnd || !experienceData.ExperienceLetterFile)
     {
       toast.error('All fileds are required.', {
         position: toast.POSITION.TOP_CENTER,
         autoClose: 2000,
       });
     }
     else
     {
       // Call the API function to save education details                             
       saveExperienceDetails(experienceData);
     }

    // Reset the form fields
    setCompanyName('');
    setDesignation('');
    setDurationOfService('');
    setYearStart_('');
    setYearEnd_('');
    setExperienceLetterFile('');
  };

  const saveExperienceDetails = (data) => {
    
    // console.log(data,teacherID);
    if(!Designation || !CompanyName || !YearStart || !YearEnd || !DurationOfService)
    {
      toast.error('All fileds are required.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
    else
    {
      axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveExperienceDetails/${teacherID}`, data)
      .then(response => {
        toast.success('Experience data has been saved.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        fetchExperienceData(teacherID);
        // console.log('Education details saved successfully');
      })
      .catch(error => {
        toast.error('Something went wrong.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        console.log('Error saving education details', error);
      });
    }
    
  };
  const handleFileChange_ = (e) => {
    const file = e.target.files[0];
    const filePath = URL.createObjectURL(file); // Create a temporary object URL for the file

    setExperienceLetterFile(filePath);
  };
    const onLogin = (usertype) => {
    setUserRole(usertype);
    };
    
  const username = userInfo?.username;
  const User_id = userInfo?.User_id;
  const [formValues, setFormValues] = useState({  });
  const [bankFormValues, setBankFormValues] = useState({  });

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}
            {isTeacher && <TeacherNav    username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
    <div className='edit-bar'>
        <h3 className='edit-heading'>
            <i class="fa fa-gear fa-spin"></i>
            
            Edit Profile
        </h3>
      </div>

    <div className='TeacherComponents'>
      <div >
            <div className='TeacherBankDetails'>
              <h2 className="Testimonal_Heading"> Bank Details</h2>
              <label htmlFor="">Bank Name</label>
              <input type="text" 
              placeholder='Bank Name' 
              className='TeacherINP' 
              style={{marginLeft:"10%"}}
              value={bankFormValues.BankName}
              onChange={(e) =>
                setBankFormValues({ ...bankFormValues, BankName: e.target.value })
              }
              /> 
              
              <br />
              <label htmlFor="">IBAN</label>
              <input type="text" 
              placeholder='IBAN' 
              className='TeacherINP' 
              style={{marginLeft:"25%"}}
              value={bankFormValues.BankAccountIBAN}
              onChange={(e) =>
              setBankFormValues({ ...bankFormValues, BankAccountIBAN: e.target.value })
            }
              /> <br />
              
              <label htmlFor="">Mobile Wallet</label>
              <input type="text" 
              placeholder='Mobile Wallet' 
              className='TeacherINP'
              value={bankFormValues.MobileWalletNo}
              onChange={(e) =>
                setBankFormValues({ ...bankFormValues, MobileWalletNo: e.target.value })
              }
              />
              <label htmlFor="">Prefernces</label> 
              <div style={{marginLeft:"25%"}}>

              <input type="radio" id='bg1' 
              value="Bank"
              checked={bankFormValues.preference === 'Bank'}
              onChange={(e) => setBankFormValues({ ...bankFormValues, preference: e.target.value })}
              />
              <label htmlFor="">Bank Transfer</label> <br />
              <input type="radio" id='bg1' 
              value="Mobile"
              checked={bankFormValues.preference === 'Mobile'}
              onChange={(e) => setBankFormValues({ ...bankFormValues, preference: e.target.value })}
              />
              <label htmlFor="">Mobile Wallet</label>
              </div>

              <br />
              <button className='BankBtn' onClick={handleBankDetailUpdate}>Save</button>
              <br />
            
            </div>
      </div>
      <div className='meetLink'>
        <label htmlFor="">Meeting Link:</label>
        <input type="text" placeholder='Meeting Link' className='TeacherINP' style={{width:"50%"}}/>
        <button className='btn1' style={{padding: "1% 6% 1% 6%"}}>Save</button>
     
      </div>

      <div className='courses'>
        <label htmlFor="">
            <b>
            Select the Course(s) you want to teach and click the Save button on the right:
            </b> 
        </label>
        <br />
        <div style={{ float: "left" }}>
          {Courses.map((course) => (
            <div key={course.id}>
              <input type="checkbox" id={course.Course_id} 
              onChange={event => handleCheckboxChange(event, course.Course_id, course.coursename)}
              // checked={selectedTeacherCourses.includes(course.Course_id)}
              checked={selectedTeacherCourses.some((teacher_course) => teacher_course.course_id === course.Course_id)}
          //  checked={selectedTeacherCourses.some((teacher_course) => teacher_course. === day )}

              />
              <label htmlFor={course.Course_id}>{course.coursename}</label>
              <br />
            </div>
          ))}
        </div>

        <button className='btn2' style={{padding: "1% 6% 1% 6%"}} onClick={handleCoursesSave}>Save</button>
     </div>

      <AvailabilityForm days={daysOfWeek} teacherID={teacherID} availabilityTimes={availabilityTimes}/>

     <div className='TeacherPersonalDetails ' style={{marginTop:"10%"}}>
        <h2 className="Testimonal_Heading"> Personal Info</h2>
        <label htmlFor="">First Name</label>
        <input type="text" 
        placeholder='First Name' 
        className='TeacherINP' 
        style={{marginLeft:"10%"}}
        value={formValues.firstName}
         onChange={(e) =>
          setFormValues({ ...formValues, firstName: e.target.value })
        }
        /> <br />
        <label htmlFor="">Last Name</label>
        <input type="text" 
        placeholder='Last Name' 
        className='TeacherINP' 
        style={{marginLeft:"10%"}}
        value={formValues.lastName}
        onChange={(e) =>
         setFormValues({ ...formValues, lastName: e.target.value })
       }
        /> <br />
        <h4 style={{textAlign:"center"}}>Gender</h4>
        <label htmlFor="">Male</label>
        <input type="radio" 
        placeholder='Gender' 
        className='TeacherINP' 
        style={{marginLeft:"17%"}}
        value="Male"
        checked={formValues.gender === 'Male'}
        onChange={(e) => setFormValues({ ...formValues, gender: e.target.value })}
    
        />
        <label htmlFor="">Female</label>
         <input type="radio" 
        placeholder='Gender' 
        className='TeacherINP' 
        style={{marginLeft:"12%"}}
        value="Female"
        checked={formValues.gender === 'Female'}
        onChange={(e) => setFormValues({ ...formValues, gender: e.target.value })}
        />
        <br />
        
        <label htmlFor="">Birthday</label>
        <input type="date" 
        placeholder='Birthday' 
        className='TeacherINP' 
        style={{marginLeft:"16%"}}
        value={formattedDateOfBirth}
          onChange={(e) =>{
            setFormValues({ ...formValues, dateOfBirth: e.target.value });
            setDate(e.target.value);
         
        } }
        />
    <br />
        
        <label htmlFor="">Address</label>
        <input type="text" 
        placeholder='Address' 
        className='TeacherINP' 
        style={{marginLeft:"16%"}}
        value={formValues.address}
        onChange={(e) =>
         setFormValues({ ...formValues, address: e.target.value })
        }
        />

        <br />
        <label htmlFor="">WhatsApp No.</label>
        <input type="text" 
        placeholder='WahstApp No.' 
        className='TeacherINP' 
        style={{marginLeft:"1vh"}}
        value={formValues.phonenumber}
        onChange={(e) =>
         setFormValues({ ...formValues, phonenumber: e.target.value })
       }
        />
        <br />
        
        <label htmlFor="">Parent Name</label>
        <input type="text" 
        className='TeacherINP' 
        style={{marginLeft:"1vh"}}
        placeholder="GuardianName"
        value={formValues.parentname}
        onChange={(e) =>
         setFormValues({ ...formValues, parentname: e.target.value })
       }
        />


        <button className='BankBtn' onClick={handlePersonalUpdate}>Save</button>
        </div>
<br />
     <div className='courses'>
        <label htmlFor="">
            <b>
           Education
            </b>
            
        </label>
        <br />
        <div style={{ float: 'left' }} className="education">
        <input
          type="text"
          className='EditTecherInp'
          placeholder='Degree name'
          value={degreeName}
          onChange={(e) => setDegreeName(e.target.value)}
        />
        <input
          type="text"
          className='EditTecherInp'
          placeholder='Institute Name'
          value={instituteName}
          onChange={(e) => setInstituteName(e.target.value)}
        />
        <input
          type="text"
          className='EditTecherInp'
          placeholder='Duration(4 Years)'
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
        <input
          type="number"
          className='EditTecherInp'
          placeholder='Year Start'
          value={yearStart}
          onChange={(e) => setYearStart(e.target.value)}
        />
        <input
          type="number"
          className='EditTecherInp'
          placeholder='Year End'
          value={yearEnd}
          onChange={(e) => setYearEnd(e.target.value)}
        />
        <input
          type="file"
          className='EditTecherInp'
          placeholder='Upload Degree'
          // onChange={(e) => setDegreeFile(e.target.files[0])}
          onChange={handleFileChange}
        />
      </div>
        <br />
        <br />
       
        <button className='btn2' style={{padding: "1% 6% 1% 6%"}} onClick={handleEducationSave}>Save</button>
        <br />
        <br />
        <div className='tableInfo' style={{marginLeft:"5%"}}>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",marginLeft:"5%",fontWeight:"bold"}}>Degree Name</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Institute Name</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Duration of course</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Year</label>
        </div>
        <div>
      {educationData.map((educationItem, index) => (
        <div key={index} style={{marginLeft:'10%',width:"94%",display:"flex",alignItems:"center",justifyContent:'space-evenly'}}>
          <p style={{width:'22%'}}>{educationItem.degreeName}</p>
          <p style={{width:'22%'}}>{educationItem.instituteName}</p>
          <p style={{width:'22%'}}>{educationItem.duration}</p>
          <p style={{width:'22%'}}>{educationItem.yearStart}</p>
           
          <br />
          <hr />

        </div>
      ))}
    </div>

<br />
        <label htmlFor="">
            <b>
           Experience
            </b>
            
        </label>
        <br />
        <div  style={{float:"left"}} className="education">
        <input type="text" className='EditTecherInp' placeholder='Designation' 
        value={Designation} required
          onChange={(e) => setDesignation(e.target.value)}
        />
        <input type="text" className='EditTecherInp' placeholder='Company Name' 
        value={CompanyName}
          onChange={(e) => setCompanyName(e.target.value)}
          />
        <input type="text" className='EditTecherInp' placeholder='Duration of Service'
        value={DurationOfService}
          onChange={(e) => setDurationOfService(e.target.value)}
        />
        <input type="text" className='EditTecherInp' placeholder='Year Start'
        value={YearStart}
          onChange={(e) => setYearStart_(e.target.value)}
          />
        <input type="text" className='EditTecherInp' placeholder='Year End' 
        value={YearEnd}
          onChange={(e) => setYearEnd_(e.target.value)}
          />
        <input type="file" className='EditTecherInp' placeholder='Experience letter'
          onChange={handleFileChange_}
          />
        <br />
        </div>
        <br />
        <br />
       
        <button className='btn2' style={{padding: "1% 6% 1% 6%"}} onClick={handleExperienceSave}>Save</button>
        <br />
        <br />
        {/* <div className='tableInfo'>
        <label htmlFor="" style={{fontSize:"15px",color:"white",padding:"1%",marginLeft:"5%",fontWeight:"bold"}}>Designation</label>
        <label htmlFor="" style={{fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Company Name</label>
        <label htmlFor="" style={{fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Duration of service</label>
        <label htmlFor="" style={{fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Year</label>
        </div> */}

        <div className='tableInfo' style={{marginLeft:"5%"}}>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",marginLeft:"5%",fontWeight:"bold"}}>Designation</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}> Company Name</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Duration of service</label>
        <label htmlFor="" style={{marginRight:"8%",fontSize:"15px",color:"white",padding:"1%",fontWeight:"bold"}}>Year</label>
        </div>
        <div>
      {experienceData.map((experienceItem, index) => (
        <div key={index} style={{marginLeft:'10%',width:"94%",display:"flex",alignItems:"center",justifyContent:'space-evenly'}}>
          <p style={{width:'22%'}}>{experienceItem.Designation}</p>
          <p style={{width:'22%'}}>{experienceItem.CompanyName}</p>
          <p style={{width:'22%'}}>{experienceItem.DurationOfService}</p>
          <p style={{width:'22%'}}>{experienceItem.YearStart}</p>
           
          <br />
          <hr />

        </div>
      ))}
    </div>

     </div>
    </div>
    {/* Footer */}
<Footer/>

    </>
  )
}

export default Teacher
