import React, { useState,useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './ManageLessonProject.css';

const ManageLessonProject = (props) => {
  const [projects, setProjects] = useState([
    { projectassignmentname: 'Lesson 1', projectassignmentdescription: 'Description 1' }
  ]);

  const lesson = props.lessonid;

  useEffect(() => {
    fetchLessonProject();
  }, []);

  const fetchLessonProject = () => {
    axiosInstance.post('/getLessonProjectNameDescription', { lesson })
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = (projectId) => {
    const updatedProjects = projects.filter((project) => project.id !== projectId);
    setProjects(updatedProjects);
  };

  const handleUpdate = (projectId) => {
    const updatedProjects = projects.map((project) => {
      if (project.id === projectId) {
        return { ...project, editing: true };
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  const handleSave = (projectId) => {
    const updatedProjects = projects.map((project) => {
      if (project.id === projectId) {
        return { ...project, editing: false };
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  return (
    <div className="manage-lesson-project">
      <h2>Manage Lesson Project</h2>
      <div className="project-list">
        {projects.map((project) => (
          <div className="project-card" key={project.id}>
            {project.editing ? (
              <div className="edit-form">
                <input type="text" defaultValue={project.projectassignmentname} />
                <textarea defaultValue={project.projectassignmentdescription}></textarea>
                <button className="aqua-button1" onClick={() => handleSave(project.id)}>Save</button>
              </div>
            ) : (
              <>
                <h3>{project.projectassignmentname}</h3>
                <p>{project.projectassignmentdescription}</p>
                <div className="mybuttons">
                  <button className="aqua-button1" onClick={() => handleUpdate(project.id)}>Update</button>
                  <button className="aqua-button1" onClick={() => handleDelete(project.id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageLessonProject;
