import React, { useState,useEffect } from 'react';
import Logo from '../CourseImages/Logo.png';
import './Nav.css';
import { Link } from 'react-router-dom';

const StudentNav = ({ username, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSelectedCourse(null);
  };

  const handleCourseSelection = (course) => {
    setIsOpen2(!isOpen2);
    setSelectedCourse(course);
  }; 
  const [courses, setCourses] = useState([]);

  const fetchStudentRegisteredCourses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/fetchStudentRegisteredCourses`,
      {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setCourses(data.courseNames);
      } else {
        console.error('Failed to fetch courses:', response.status);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    // Replace '123' with the actual student ID or pass the student ID as a prop to this component
    fetchStudentRegisteredCourses();
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <nav class="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: "#edffff" }}>
          <div class="container-fluid">
          <img src={Logo} alt="Logo" className='pageLogo' />
            <button style={{ marginLeft: "auto" }} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse mx-3  Navbar-Right-Aligned" id="navbarSupportedContent">
              <ul class="navbar-nav" style={{ marginLeft: "10%" }}>
                <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">
                    Home
                    <span class="sr-only">(current)</span>
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/RegisterInCourse">
                    Register in a course
                  </Link>
                </li>
                <li className="dropdown-container">
                  <a className="nav-link dropdown-toggle navLinks" onClick={toggleDropdown}>
                    My Courses
                  </a>
                  {isOpen && (
                    <li className="dropdown-menu_">
                      {courses.map((course) => (
                        <li key={course}>
                          <a
                            className={`nav-link navLinks dropdown-item_ ${selectedCourse === course ? 'active' : ''}`}
                            onClick={() => handleCourseSelection(course)}
                            style={{ padding: "0" }}
                          >
                            <span style={{ width: "30vh", padding: "1vh" }} className="dropdown-toggle course-text">
                              {course}
                            </span>
                          </a>
                          {isOpen2 && selectedCourse === course && (
                            <li className="nested-dropdown-menu_">
                              <Link to="/AttendClass" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                Attend Class
                              </Link>{' '}
                              <br />
                              <Link to="/MyProgress" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                My Progress
                              </Link>
                              <br />
                              <Link to="/ActivitiesAndProjects" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                Activities & Projects
                              </Link>
                              <br />
                            </li>
                          )}
                        </li>
                      ))}
                    </li>
                  )}
                </li>
                <li class="nav-item ">
                  <a class="nav-link navLinks" href="#">
                    My Rewards
                  </a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link navLinks" href="#">
                    My Fees
                  </a>
                </li>
                <i class="fa-regular fa-bell mx-3 my-auto" style={{ cursor: "pointer", color: "blue" }}></i>
                <i class="fa-solid fa-sack-dollar mx-3 my-auto" style={{ cursor: "pointer", color: "green" }}></i>
                 
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle navLinks" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {username}
                  </a>
                  <div class="dropdown-menu user-profile-dropdown" aria-labelledby="navbarDropdownMenuLink">
                    <Link class="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
                    <Link class="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
                    <Link class="dropdown-item UserDropdown" to="/EditProfile" >Edit Profile</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default StudentNav;
