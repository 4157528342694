var GetData = (function () {

    var KeepAliveCourse;
    var KeepAliveModule;
    var KeepAliveLesson;
    var KeepAliveActivity;
    var KeepData;

    var getCurrentStatusCourse = function () {
        return KeepAliveCourse;    // Or pull this from cookie/localStorage
    };

    var setCurrentStatusCourse = function (temp) {
        KeepAliveCourse = temp;
        
        // Also set this in cookie/localStorage
    };

    var getCurrentStatusModule = function () {
        return KeepAliveModule;    // Or pull this from cookie/localStorage
    };

    var setCurrentStatusModule = function (temp) {
        KeepAliveModule = temp;
        
        // Also set this in cookie/localStorage
    };

    var getCurrentStatusLesson = function () {
        return KeepAliveLesson;    // Or pull this from cookie/localStorage
    };

    var setCurrentStatusLesson = function (temp) {
        KeepAliveLesson = temp;
        
        // Also set this in cookie/localStorage
    };

    var getCurrentStatusActivity = function () {
        return KeepAliveActivity;    // Or pull this from cookie/localStorage
    };

    var setCurrentStatusActivity = function (temp) {
        KeepAliveActivity = temp;
        
        // Also set this in cookie/localStorage
    };

    var getCurrentData = function () {
        return KeepData;    // Or pull this from cookie/localStorage
    };

    var setCurrentData = function (temp) {
        KeepData = temp;
        
        // Also set this in cookie/localStorage
    };

   
    return {
        getStatusCourse: getCurrentStatusCourse,
        setStatusCourse: setCurrentStatusCourse,

        getStatusModule: getCurrentStatusModule,
        setStatusModule: setCurrentStatusModule,

        getStatusLesson: getCurrentStatusLesson,
        setStatusLesson: setCurrentStatusLesson,

        getStatusActivity: getCurrentStatusActivity,
        setStatusActivity: setCurrentStatusActivity,
       
        getCurrentData: getCurrentData,
        setCurrentData: setCurrentData,
    }

})();

export default GetData;