import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer';
import { AiOutlineCloudUpload, AiOutlineCheckCircle } from 'react-icons/ai';
import './ActivitiesAndProjects.css';
import { Link } from 'react-router-dom';
import puzzleicon from './ActivityIcons/AP_ModuleProjectLesson.png';
import videoicon from './ActivityIcons/AP_VideoIcon.png';
import brainicon from './ActivityIcons/AP_Brainicon.png';
import oralicon from './ActivityIcons/AP_Oralicon.png';
import writtenicon from './ActivityIcons/AP_writtenicon.png';
import axiosInstance from '../ManageContent/managecontentforms/axiosInstance';
import { useNavigate } from 'react-router-dom';

const ProjectList = ({ title, projects, backgroundColor, textColor, iconBackground, image,courseID,activityID }) => {
  const shouldDisplayTime = title === 'Mental Activities' || title === 'Oral Activities' || title === 'Written Activities';
  const navigate = useNavigate();




  const handleProjectClick = (projectType,activityID1) => {
    let route = '';

    if (projectType === 'MCQ Activities') {
      route = '/MCQActivity';
    } else if (projectType === 'Written Activities') {
      route = '/WrittenActivity';
    }
    else if (projectType === 'Video Activities') {
      route = '/VideoActivity';
    }
    else if (projectType === 'Speed Quiz Activities') {
      route = '/SpeedQuizActivity';
    }
    else if (projectType === 'Mental Activities') {
      route = '/MentalActivity';
    }
    else if (projectType === 'Oral Activities') {
      route = '/OralActivity';
    }
    window.location.assign(`${route}?activityId=${activityID1}`);
  };

  return (
    <div className='AP_ModuleProjectLesson' style={{ backgroundColor }}>
      <h2 className='AP_title' style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} /> {title}
      </h2>
      <ul>
        {projects.map((project, index) => (
          <li key={index} className='AP_Projects'>
            <div className='AP_ProjectItem' >
              <Link to="" onClick={() => handleProjectClick(title,project.id)} style={{ marginRight: '7%', color: textColor }}>
                {project.name}
              </Link>
              {shouldDisplayTime ? (
                <>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Icon' ><AiOutlineCheckCircle /></span>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Score'>/100</span>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Time'>0:0/0:0</span>
                </>
              ) : (
                <>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Icon'><AiOutlineCloudUpload /></span>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Icon'><AiOutlineCheckCircle /></span>
                  <span style={{ color: textColor, backgroundColor: iconBackground }} className='AP_Score'>/100</span>
                </>
              )}
            </div>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ActivitiesAndProjects = () => {

  console.log("I am in Activities")

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole} = useContext(UserContext);
  const [ActivityId,setActivityId] = useState();
  const [CourseID,setCourseID] = useState();

  const determineUserRole = (email, password) => {
    return "admin";
  };
  const onLogin = (email, password) => {
    const userRole = determineUserRole(email, password);
    setUserRole(userRole);
  };
  const [projects, setProjects] = useState([]);
  const [projects2,setProjects2] = useState([]);
  const [VideoActivities,setVideoActivities]=useState([]);
  const [GameActivities,setGameActivities]=useState([]);
  const [MentalActivities,setMentalActivities]=useState([]);
  const [OralActivities,setOralActivities]=useState([]);
  const [WrittenActivities,setWrittenActivities]=useState([]);
  const [SpeedQuizActivities,setSpeedQuizActivities]=useState([]);


 const [studentid,setStudentid] = useState(100);

 const [userData, setUserData] = useState({});

 useEffect(() => {
   axiosInstance.get('/', { withCredentials: true })
     .then((response) => {
       console.log(response.data);
       setUserData(response.data);
     })
     .catch((error) => {
       console.error('Error fetching data: ', error);
     });
 }, []);

 useEffect(() => {
  const fetchStudentID = () => {
    axiosInstance.post('/getStudentIDfromUser', { UserId: userData.User_id
    })
      .then(response => {
        const data = response.data[0].Student_id;
        console.log("Student ID: ", data);
        setStudentid(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchStudentID();
}, [userData.User_id]);

useEffect(() => {
  const fetchData = async () => {
    try {
      // First, fetch the Activity ID
      const response1 = await axiosInstance.post('/getActivityIDfromStudent', { StudentId: studentid });
      const activityData = response1.data;
      setActivityId(activityData);

      // Check if ActivityId is set before making the second API call
      if (activityData && activityData.length > 0) {
        const response2 = await axiosInstance.post('/getCourseIDfromActivityID', { ActivityID: activityData[0].Activity_id });
        const courseData = response2.data;
        setCourseID(courseData[0].Course_id);
        console.log("Course ID: ");
        console.log(CourseID);
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [studentid]);

  useEffect(() => {
  const fetchProjects = () => {
    axiosInstance.post('/getStudentModuleProjectAssignment', { studentId: studentid })
      .then(response => {
        const data = response.data;
        setProjects(data.map(item => item.projectassignmentname));
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchProjects();
}, [studentid]);

useEffect(() => {
  const fetchProjects2 = () => {
    axiosInstance.post('/getStudentLessonProjectAssignment', { studentId: studentid })
      .then(response => {
        const data = response.data;
        setProjects2(data.map(item => item.projectassignmentname));
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchProjects2();
}, [studentid]);

useEffect(() => {
  const fetchActivities = () => {
    axiosInstance.post('/getStudentLessonActivity', { studentId: studentid })
      .then(response => {
        const data = response.data;
        const activityData = {
          'Video Activity': [],
          'Game Activity': [],
          'Mental Activity': [],
          'Oral Activity': [],
          'Written Activity': [],
          'Speed Quiz Activity': [],
        };

        data.forEach(activity => {
          activityData[activity.ActivityType].push({
            name: activity.ActivityName,
            id: activity.Lesson_Activity_id ,
          });
        });

        setVideoActivities(activityData['Video Activity']);
        setGameActivities(activityData['Game Activity']);
        setMentalActivities(activityData['Mental Activity']);
        setOralActivities(activityData['Oral Activity']);
        setWrittenActivities(activityData['Written Activity']);
        setSpeedQuizActivities(activityData['Speed Quiz Activity']);
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchActivities();
}, [studentid]);


  return (
    <>
      {isGuest && <GuestNav onLogin={onLogin} />}
      {isAdmin && <AdminNav />}
      {isTeacher && <TeacherNav />}
      {isStudent && <StudentNav />}
      <div className='AP_container'>
        <ProjectList
          title="Module Project"
          projects={projects}
          textColor="#5665e9"
          backgroundColor="#dae8ff"
          iconBackground="#aec7f0"
          image={puzzleicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
        <ProjectList
          title="Lesson Project"
          projects={projects2}
          textColor="#5665e9"
          backgroundColor="#dae8ff"
          iconBackground="#aec7f0"
          image={puzzleicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
      </div>
      <div className='AP_container'>
        <ProjectList
          title="Video Activities"
          projects={VideoActivities}
          textColor="#34979c"
          backgroundColor="#eafcfd"
          iconBackground="#a3d6d8"
          image={videoicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
        <ProjectList
          title="Game Activities"
          projects={GameActivities}
          textColor="#d9ce23"
          backgroundColor="#f7fbdb"
          iconBackground="#f2fcae"
          image={puzzleicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
      </div>
      <div className='AP_container'>
        <ProjectList
          title="Mental Activities"
          projects={MentalActivities}
          textColor="#da831e"
          backgroundColor="#faf0d6"
          iconBackground="#fae7b4"
          image={brainicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
        <ProjectList
          title="Oral Activities"
          projects={OralActivities}
          textColor="#d83162"
          backgroundColor="#feecf2"
          iconBackground="#fccada"
          image={oralicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
      </div>
      <div className='AP_container'>
        <ProjectList
          title="Written Activities"
          projects={WrittenActivities}
          textColor="#ac4dcb"
          backgroundColor="#e4ddfb"
          iconBackground="#d0c2fc"
          image={writtenicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
      </div>
      <div className='AP_container'>
        <ProjectList
          title="Speed Quiz Activities"
          projects={SpeedQuizActivities}
          textColor="#ac4dcb"
          backgroundColor="#e4ddfb"
          iconBackground="#d0c2fc"
          image={brainicon}
          activityID = {ActivityId}
          courseID = {CourseID}
        />
      </div>
      <Footer />
    </>
  );
};

export default ActivitiesAndProjects;
