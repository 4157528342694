import React from 'react'
import './FeeStructure.css'
const FeeStructure = ({subject}) => {
  return (
    <>
       {/* Fee Structure */}
       <section className="section fee-structure my-3" id="fee">
            <div>
              <p className="PageHead" style={{ color: "#8b0194" }}>
                Fee Structure
              </p>
              <div>
                <p
                  style={{
                    color: "#8b0194",
                    marginTop: "3vh",
                    fontSize: "2em",
                    fontFamily: "VanillaMilk",
                  }}
                >
                  Learn  {subject} privately or with fellow Polymaths
                </p>
              </div>
            </div>
    
            <div className="d-flex mx-4 feeTableContainer" style={{ marginLeft: "20vh" }}>
              
              <div className="columns" style={{ marginTop: "29vh" }}>
                <ul className="price">
                  <li>
                    <b> Class duration </b>
                  </li>
                  <li>
                    <b> Class type </b>
                  </li>
                  <li>
                    <b> Class schedule </b>
                  </li>
                  <li>
                    <b> Learning mode </b>
                  </li>
                  <li>
                    <b> Pace of learning</b>
                  </li>
                  <li>
                    <b>Activities </b>
                  </li>
                  <li>
                    <b>Assesment </b>
                  </li>
                </ul>
              </div>
              <div className="columns">
                <ul className="price">
                  <li className="header">Private 1-on-1 Class</li>
    
                  <li className="grey">
                    Our 1-on-1 classes for kids are suitable for children who
                    want a full personalized and effective learning experience.
                  </li>
                  <li>60 minutes</li>
                  <li>Individual</li>
                  <li>Flexible</li>
                  <li>With teacher</li>
                  <li>Personalized</li>
                  <li>With teacher</li>
                  <li>Individual</li>
                  <li className="grey">
                    <a href="#" className="button">
                      Buy Course
                    </a>
                  </li>
                </ul>
              </div>
              <div className="columns">
                <ul className="price">
                  <li className="header">Group Classes</li>
    
                  <li className="grey">
                    Our Group classes for kids are perfect for children who
                    want to learn coding together in an interactive and social
                    setting.
                  </li>
                  <li>60 minutes</li>
                  <li>Small Group</li>
                  <li>Peer dependent</li>
                  <li>With teacher and fellow Polymaths</li>
                  <li>Group</li>
                  <li>With teacher and fellow Polymaths</li>
                  <li>Individual and as a team</li>
                  <li className="grey">
                    <a href="#" className="button">
                      Buy Course
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
    </>
  )
}

export default FeeStructure
