
import './TeacherAttendClass.css'
import React,{useState,useContext,useEffect} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import TeacherNav from "../Navbars/TeacherNav";
import written from './ActivityIcons/WrittenActivity.png'
import mental from './ActivityIcons/MentalActivity.png'
import oral from './ActivityIcons/OralActivity.png'
import speedquizz from './ActivityIcons/SpeedQuiz.png'
import mcq from './ActivityIcons/MCQActivity.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const TeacherAttendClass = ({selectedCourse,setSelectedCourse}) => {
  const [selectedLesson, setSelectedLesson] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
 

  const lessonData = [
    {
      lesson: 'Lesson 1',
      activities: ['Written Activity 1', 'Written Activity 2', 'Written Activity 3'],
      projects: ['Project 1', 'Project 2',],
    },
    {
      lesson: 'Lesson 2',
      activities: ['Written Activity 4', 'Written Activity 5', ],
      projects: ['Project 4', 'Project 5', 'Project 6'],
    },
    // Add data for other lessons here
  ];
  const moduleData = [
    {
      module: 'Module 1',
      projects: ['Project 1', 'Project 2', 'Project 3'],
      
    },
    {
      module: 'Module 2',
      projects: ['Project 4', 'Project 5', 'Project 6'],
      
    },
    {
      module: 'Module 3',
      projects: ['Project 7', 'Project 8', 'Project 9'],
      
    },
    {
      module: 'Module 4',
      projects: ['Project 10', 'Project 11', 'Project 12'],
      
    },
    {
      module: 'Module 5',
      projects: ['Project 13', 'Project 14', 'Project 15'],
      
    },
    // Add data for other lessons here
  ];
  const studentData = [
    {
      name: 'Ahmad',
      progres: ['Scratch M4 L1 Activity 2', 'Scratch M4 L1 Activity 1', 'Scratch M4 L1 Activity 3'],
      
    },
    {
      name: 'Mohsin',
      progres: ['Scratch M4 L1 Activity 6', 'Scratch M4 L1 Activity 7', 'Scratch M4 L1 Activity 8'],
    },
    
     
    // Add data for other lessons here
  ];
  const handleLessonChange = (e) => {
    setSelectedLesson(e.target.value);
  };
  const handleModuleChange = (e) => {
    setSelectedModule(e.target.value);
  };
  const handleStudentChange = (e) => {
    setSelectedStudent(e.target.value);
  };
  const handleCourseSelect = (course) => { 
    console.log("Selected Course in AttendClass:", course);
    selectedCourse(course);
    // setSelectedCourse(course);
  };
  
 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
 useEffect(() => {
   const fetchData = async () => {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
       credentials: 'include',
     }).then(response => {
         console.log("Response:",response);
       response.json().then(userInfo => {
         console.log("userinfo:",userInfo);
         setUserInfo(userInfo);

         if(userInfo?.usertype)
         {
           const uType=userInfo?.usertype;
           setUserRole(uType);
         }
       const uname = userInfo?.username;
       const avatar = userInfo?.avatar; // Store the avatar in a separate variable
       console.log("Uname: ", uname);
       console.log("Avatar: ", avatar);

       })
       .catch(err=> console.log("Error in useeffect:",err))
     });
   }
   fetchData();
 }, [isAdmin, isTeacher, isStudent, isGuest]);
const determineUserRole = (usertype) => {
     console.log("In home.js:",usertype)
     if(usertype === 'Student')
       return "Student";
     else if(usertype === 'Teacher')
       return "Teacher";
     else if(usertype === 'Admin')
       return "Admin";
     else
       return "guest";
};


const navigate=useNavigate();
const onLogin = (usertype) => {
 const userRole = determineUserRole(usertype);
 console.log("Calling setUserRole.")
 setUserRole(userRole);
};

function onLogout() {
   console.log("inside Onlogout.");
   fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
     credentials: 'include',
     method: 'POST',
   });
   setUserInfo(null);
   setUserRole('guest');
 }
   
   const username = userInfo?.username;

  
  return (
    <>
         {/* Navbar */}
         {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}


      <div className="joinClass">
        <h2 className="Testimonal_Heading" style={{ color: '#03b4c6', fontSize: '25px', margin: 0 }}>
          {selectedCourse}
        </h2>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button className="joinBtn">Join Class</button>
        </div>

        <div style={{ display: 'flex' }}>
          <div className="lessonPlan">
            <h3>Lesson Plan</h3>
            <select name="" id="" className="lessonOption" value={selectedLesson} onChange={handleLessonChange}>
              <option value="">Select Lesson</option>
              {lessonData.map((lesson, index) => (
                <option key={index} value={lesson.lesson}>
                  {lesson.lesson}
                </option>
              ))}
            </select>
          </div>

          {selectedLesson && (
            <>
              <div className="lessonPlan">
                <h3>Lesson Activities</h3>
                <ul>
                  {lessonData
                    .find((lesson) => lesson.lesson === selectedLesson)
                    .activities.map((activity, index) => (
                      <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>
                      <img src={written} alt="WrittenLogo" className='ActivitiesLogo' />
                        {activity} <br /> <hr />
                      
                      </a>
                     
                    ))}
                </ul>
              </div>
              <div className="lessonPlan">
                <h3>Lesson Projects</h3>
                <ul>
                  {lessonData
                    .find((lesson) => lesson.lesson === selectedLesson)
                    .projects.map((project, index) => (
                      <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{project} <br /> <hr /></a>
                    ))}
                </ul>
              </div>

          
             
            </>
          )}

          {!selectedLesson && (
            <div className="lessonPlan">
              <h3>No Lesson Selected</h3>
            </div>
          )}
            
          
        </div>
      </div>
      <div style={{display:"flex",margin:"2vh 0 0 0"}}>
        <div className="lessonPlan">
            <h3>Module Projects</h3>
            <select name="" id="" className="lessonOption" value={selectedModule} onChange={handleModuleChange}>
              <option value="">Select Module</option>
              {moduleData.map((module, index) => (
                <option key={index} value={module.module}>
                  {module.module}
                </option>
              ))}
            </select>
          </div>
           
                  {selectedModule && (
                    <>
                <div className="lessonPlan">
                        <h3>Module Projects</h3>
                        <ul>
                          {moduleData
                            .find((module) => module.module === selectedModule)
                            .projects.map((project, index) => (
                              <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{project} <br /> <hr /></a>
                            ))}
                        </ul>
                      </div>
                
                    
                    </>
                  )}
              {!selectedModule && (
            <div className="lessonPlan">
              <h3>No Module Selected</h3>
            </div>
          )}
            <div className="lessonPlan">
            <h3>Student Progress</h3>
            <select name="" id="" className="lessonOption" value={selectedStudent} onChange={handleStudentChange}>
              <option value="">Select Student</option>
              {studentData.map((student, index) => (
                <option key={index} value={student.name}>
                  {student.name}
                </option>
              ))}
            </select>
            <br /><br />
            <input style={{padding:"2%",border:"1px solid gray"}}
             type="text" placeholder='Type message'/>
            <button style={{border:"0",borderRadius:"2vh",backgroundColor:"#03b4c6",color:"white",margin:"1vh",padding:"2% 5% 2% 5%"}}>Send</button>


            {selectedStudent && (
                    <>
                {/* <div className="lessonPlan"> */}
                        {/* <h3>Module Projects</h3> */}
                        <ul>
                          {studentData
                            .find((student) => student.name === selectedStudent)
                            .progres.map((progres, index) => (
                              <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{progres} <br /> <hr /></a>
                            ))}
                        </ul>
                      {/* </div> */}
                
                    
                    </>
                  )}
                  {!selectedStudent && (
                  <div className="lessonPlan">
                    <h3>No Student Selected</h3>
                  </div>
                 )}
          </div>
      </div>
      
      {/* Footer */}
      <Footer />
    </>
  );
}

export default TeacherAttendClass
