import React, {useRef, useContext,useEffect } from 'react'
import emailjs from '@emailjs/browser';
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import './ContactUs.css'
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = ({selectedCourse}) => {

  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);

    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };


 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }

  const username = userInfo?.username;

  const form = useRef();
  const handleContactInquiry = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_p478mti',
          'template_za7k209',
          form.current,
          'P0xsNsNTFQBlZTnEa')
      .then((result) => {
          console.log(result.text);
          toast.success('Email sent successfully!', {
            position: 'top-center',
            autoClose: 3000, // Duration in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      }, (error) => {
          console.log(error.text);
          toast.error('Email does not sent!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      });
  };
  return (
    <>

       {/* Navbar */}
       {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
      <form action="" onSubmit={handleContactInquiry} ref={form}>
        <div className='contactUs'>
            <div className="leftDiv">
            <h2 className='contactHeading'>GET IN TOUCH</h2>
            <i class="fa-sharp fa-solid fa-phone" style={{color:"blue",marginLeft:"4%"}}></i>
            <div  style={{marginLeft:"12%"}}>
            <h5>Phone/Whatsapp</h5>
            <p>+923266699907</p>
            </div>

            <i class="fa-sharp fa-solid fa-phone" style={{color:"blue",marginLeft:"4%"}}></i>
            <div style={{marginLeft:"12%"}}>
            <h5>Email</h5>
            <p>info@polymath-kids.com</p>
            </div>
            <a href="https://www.facebook.com/polymathkidz" class="fa-brands fa-square-facebook FB" style={{fontSize:"6vh",marginLeft:"10%",marginRight:"2%"}}></a>
            <a href='https://twitter.com/polymathkidz' class="fa-brands fa-twitter TW" style={{fontSize:"6vh",marginRight:"2%" }} ></a>
            <a href='https://www.youtube.com/@polymath-kids' class="fa-brands fa-youtube YT" style={{fontSize:"6vh",marginRight:"2%" }}></a>
            <a href='https://www.instagram.com/polymathkidz/' class="fa-brands fa-instagram IN" style={{fontSize:"6vh",marginRight:"2%" }}></a>
            <a href='https://www.pinterest.com/polymathkidz/' class="fa-brands fa-pinterest PIN" style={{fontSize:"6vh",marginRight:"2%" }}></a>
            </div>
            <div className="rightDiv">
            <h2 className='contactHeading'>SEND A MESSAGE</h2>
            <input  className='Input' name="user_name" required type="text" placeholder='Name'/>
            <input  className='Input' name="user_number" required type="number" placeholder='WhatsApp Number'/>
            <input  className='Input' name="user_email" required type="email" placeholder='Email'/>
            <input  className='Input' name="message" required type="text" placeholder='Message'/>
            <button className='btnSend'>Send Message</button>

            </div>
        </div>
        </form>
        {/* Footer */}
        <Footer/>
    </>
  )
}

export default ContactUs
