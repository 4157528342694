import React,{useState,useEffect,useContext} from 'react'
import './Home.css'
import {Link} from 'react-router-dom'
import Quote from './CourseImages/quote.png'
import TeacherPic from './CourseImages/teacherImage.png'
import CEOMsg from './CourseImages/ceoMsg.png'
import portalimg1 from './CourseImages/portalImg1.png'
import portalimg2 from './CourseImages/portalImg2.png'
import Logo from './CourseImages/Logo.png'
import Footer from './Footer'
import StudentNav from './Navbars/StudentNav'
import AdminNav from './Navbars/AdminNav'
import GuestNav from './Navbars/GuestNav'
import TeacherNav from './Navbars/TeacherNav'
import  instance  from './ManageContent/managecontentforms/axiosInstance';

import { UserContext } from './UserContext';
import axios from 'axios'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

import pic from "./CourseImages/Architecture.png";

const Home = ({selectedCourse}) => {
  let CourseDetailStyle={
    alignItems:'center',
    justifyContent:'center',
    display:'flex',
    flexWrap:'wrap',
  }
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);
 const [avatar, setAvatar] = useState('');

    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response =>
          {
             response.json().then(userInfo => {

            // if (userInfo?.avatar) {
            //   setAvatar(`data:image/jpeg;base64,${userInfo?.avatar}`);
            //   // setAvatar();
            //   console.log("avatarr:::",avatar)
            // }
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          // const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          // console.log("Uname: ", uname);
          // console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };


 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    setUserRole(userRole);
  };

  function onLogout() {
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }

      const username = userInfo?.username;
      const usertype = userInfo?.usertype;

  return (
    <>
     <div style={CourseDetailStyle} className="homeBackground">
     {/* Navbar */}
     {username && (
       <>
       {/* {avatar && <img src={avatar} alt="User Avatar" />} */}
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}



      {/* Portals' Intro */}
      <div className="portalIntro">
        <div className='IntroDesc'>
          <h1 className='portalHighlights' style={{fontSize:'7vh',color:'#bf0b8c'}}>
          Help your kid become a Polymath!
          </h1>
          <p className='portalMainPara portalRespPara'>
          At Polymath-Kids, we're a global Ed-Tech initiative dedicated to closing the 21st Century Skills gap for children by offering professionally-designed online courses. Our courses focus on developing children's cognitive, leadership, social, and people skills, providing structured learning experiences that help kids build essential life skills. Our online courses cover a range of topics, including coding, graphic design, math, Quran, and other STEM education topics, all with the goal of fostering creative problem-solving and digital literacy skills in children. With our online learning programs, homeschooling curriculum, and virtual classrooms for kids, we're dedicated to helping children develop early skills that will set them up for success in the future.
          </p>

        </div>
        <div className="IntroImages">
          <img src={portalimg1} alt="image" className='IntroImage1'/>
          <img src={portalimg2} alt="image" className='IntroImage2'/>

        </div>

      </div>
         <div className='portal_heading'>
      <p className="mx-auto">
           OUR LIFE SKILLS COURSES
      </p>
          </div>
{/* Math Portal */}
<Link to="/MathCourseDetails">
<div className='mx-3' style={{cursor:'pointer'}}>

<div className='img img1'></div>
    <div className="card card-color1" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Math's Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Brain Train Abacus</p>
              </i>
        </div>
</div>

    </div>
</Link>

{/* Coding Portal */}
   <Link to="/CodingCourseDetails">
<div className='mx-3' style={{cursor:'pointer'}}>

<div className='img img2'></div>
    <div className="card card-color2" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Coding Portal</h4>

            <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                      <p className='course-details'>Scratch</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>Game Development</p>
                  </i>
            </div>

            <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>App Development</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                     <p className='course-details'>PHP</p>
                  </i>
            </div>
            <div className='portalsTickIcons'>
                   <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>Web Development</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                       <p className='course-details'>Python</p>
                  </i>
            </div>




        </div>
</div>

    </div>
   </Link>

{/* Designing Portal */}
<Link  to="/GraphicCourseDetails">
<div className='mx-3' style={{cursor:'pointer'}}>

<div className='img img3'></div>
    <div className="card card-color3" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Graphic Designing Portal</h4>

            <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>UI/Ux</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon ">
                     <p className='course-details'>Canva</p>
                  </i>
            </div>
            <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>AdobeXD</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                     <p className='course-details'>Illustrator</p>
                  </i>
            </div>
            <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>PhotoShop</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                     <p className='course-details'>Corel Draw</p>
                  </i>
            </div>

        </div>
</div>

    </div>
</Link>

{/* Quran Portal */}
<Link to="/QuranCourseDetails">
<div className='mx-3' style={{cursor:'pointer',marginTop:"8%"}}>

<div className='img img4'></div>
    <div className="card card-color5" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Quran Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Yassarnal Qur'an</p>
              <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Tafseer-ul-Qur'an</p>
              </i>
              </i>
              <i className="fa-solid fa-check mx-3 d-flex">

              <p className='course-details'>Qur'an Recitation </p>
              <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Memorize Suras</p>
              </i>
              </i>
              <i className="fa-solid fa-check mx-3 d-flex">

                   <p className='course-details'> Islamic Knowledge</p>

              </i>


        </div>
</div>


</div>
</Link>

{/* Business Portal; */}
<Link to="/BusinessCourseDetails">
<div className='mx-3' style={{cursor:'pointer',marginTop:"8%"}}>

<div className='img img5'></div>
    <div className="card card-color4">
        <div className="container">
            <h4  className='text-center H4_Heading'>Business Portal</h4>


                <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>Kidpreneur Train Abacus</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>ECommerce</p>
                  </i>
                </div>

                <div className='portalsTickIcons'>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>Digital Marketing</p>
                  </i>
                  <i className="fa-solid fa-check mx-3 d-flex tickIcon">
                    <p className='course-details'>Fun Finance and Money handling</p>
                  </i>
                </div>
              {/* <div className='portalsTickIcons'>

              </div>  */}

        </div>
</div>

</div>
</Link>

{/* Engineering Portal */}
<Link to="/EngineeringCourseDetails">
<div className='mx-3'  style={{cursor:'pointer',marginTop:"8%"}}>

<div className='img img6'></div>
    <div className="card card-color6" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Engineering Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Mechanical Engineering</p>
              <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Electrical Engineering</p>
              </i>
              </i>
              <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>IOT & Robotics </p>
              </i>



        </div>
</div>


</div>
</Link>

{/* Architecture Portal */}
<Link to="/ArchitectureCourseDetails">
<div className='mx-3'  style={{cursor:'pointer',marginTop:"8%"}}>

<div className='img img7'></div>
    <div className="card card-color7" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Architecture & Interior Design Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Architecture</p>
              <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Interior Design</p>
              </i>
              </i>

        </div>
    </div>
</div>
</Link>

{/* Space Sciences Portal Portal */}
<Link to="/SpaceSciencesCourseDetails">
<div className='mx-3'  style={{cursor:'pointer',marginTop:"8%"}}>

<div className='img img8'></div>
    <div className="card card-color8" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Space Sciences Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Astronomy</p>

            </i>

        </div>
    </div>
</div>
</Link>

{/* Communication Portal */}
<Link to="/CommunicationCourseDetails">
<div className='mx-3'  style={{cursor:'pointer',marginTop:"8%"}}>
<div className='img img9'></div>
    <div className="card card-color9" >
        <div className="container">
            <h4  className='text-center H4_Heading'>Communication Skills Portal</h4>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Communication Skills</p>

            </i>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Declamation & Oratory Skills (coming soon)</p>

            </i>
            <i className="fa-solid fa-check mx-3 d-flex">
              <p className='course-details'>Argumentation & Debate (coming soon)</p>

            </i>

        </div>
    </div>
</div>
</Link>

{/* POLYMATH-KIDS PORTAL HIGHLIGHTS */}
<div >
<h1 className='portalHighlights'>Polymath-kids portal highlights</h1>
  <div className='portalPara'>
<p>Polymath-Kids give young people the best chance to succeed by providing a personalized learning experience to support kids aged 5 to 16 holistically and ensure success in school, university, smooth transition into the workforce and all aspects of life. </p>
</div>

 <div className='portalBullets portalRespBullets'>
    <ul>
      <li>Structured program portal with global access</li>
      <li>Online Group or One-on-One classes with certified expert instructors</li>
      <li>Audios, Videos and IQ Games to stimulate brain </li>
      <li>Fun, engaging Activities and Projects </li>
      <li>Real Time reports</li>
      <li>Learn and earn exciting rewards through Polymath Reward Program</li>
      <li>Online Assessments and Certificates</li>
    </ul>
 </div>

<h2 className="Testimonal_Heading" style={{color:"#8b0194",width: "auto",textalign:"left",fontSize:"1.5rem",marginleft: "15%"}}>
  Explore our range of courses and register today to help your kid become a Polymath!
</h2>
<div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly"}}>
<button className='portalBtn'>
    Register in a new course
  </button>
  <button className='portalBtn'>
   Book your Free Trial now
  </button>
</div>

</div>

{/* testimonals */}
<div className="teacherTestimonal">
  <div className='testimonalBox1'>
    <img src={Quote} alt="QuoteSign" style={{width:'8vh',height:"8vh"}}/>
    <p style={{color:'#8b0194',marginTop:'10%'}}>Highly Recommended</p>
    <div className="teacherName">
    <h3>Maryam Hamad</h3>
  </div>
  </div>
  <div className="testimonalBox2">
    <img src={TeacherPic} alt="TeacherPic" style={{width:'60%',height:"50%"}} />
  </div>
</div>

<div className="kidSchool">
  <h1 style={{color:'grey'}}>
  Polymath-Kids @ Schools
  </h1>
  <p className='portalPara' style={{fontSize:"3vh",paddingBottom:"10%"}}>
    Polymath-Kids is the best platform for kids where they can learn 21st Century life skills in a structured and fun learning environment. Polymath-Kids directly collaborate with well reputed schools to provide valuable training for students. We provide the preeminent learning experience with SMART Training Portal that helps to boost the creativity, problem solving skills, ICT Literacy, mental calculation ability and overall cognitive skills in kids.
    <br /><br />
    We facilitate schools by offering two modes of teaching 21st Century Like Skills at schools. <br />
    1.	Physical Mode / Online Mode with school teachers <br />
    2.	Online Mode with Polymath-Kids instructors <br />
  </p>
  <button className='portalBtn' style={{backgroundColor:"gray",paddingLeft:'5%',paddingRight:'5%'}}>
   Read More
  </button>
</div>

{/* Ceo Message */}
<div className="CEOMsg">
  <img src={CEOMsg} alt="CEOMsg"style={{width:'8vh',height:"8vh"}} />
  <p  style={{color:'gray',marginTop:'2%'}}>
  Learning contemporary skills is a necessity. Polymath-kids, assiduously seeks to provide knowledge of a variety of subjects and topics to help develop 21st Century Life Skills, which are critical for the next generation. It helps our young ones by developing new ways of thinking, problem solving, taking informed decisions and meeting the needs and demands of today’s dynamic milieu.
  </p>
  <h3 style={{color:'#6c82a8'}}>
  Maryam Hamad
  </h3>
  <h5 style={{color:'#6c82a8'}}>
  Founder & CEO, Polymath-Kids.com
  </h5>

</div>

{/* Footer */}
<Footer/>


</div>

    </>

  )
}

export default Home
