import React, { useState,useContext,useEffect } from 'react'
import "./AboutUs.css";
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from "../Footer";
import owner from "../CourseImages/ownerImg.png";
import TeacherNav from '../Navbars/TeacherNav';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const AboutUs = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
     
  return (
    <>
      {/* Navbar */}
      {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

      <div className="about">
        <div className="innerAbout">
          <div className="owner">
            <img
              src={owner}
              alt="ownerImgage"
              style={{
                float: "left",
                width: "20%",
                height: "30%",
                margin: "2%",
                marginBottom: "0",
              }}
            />
            <p style={{ padding: "2%" }}>
              <h3
                style={{ fontSize: "25px", fontWeight: "bold", padding: "2%" }}
              >
                MARYAM HAMAD
              </h3>
              Founder & CEO
              <br />
              <br />
              Master of Educational Technologies - Monash University, Australia
              MBA - IBA, Punjab University
              <br />
              <br />
              Maryam Hamad has extensive knowledge and experience of working in
              EdTech in Australia as well as Pakistan. She strongly believes
              that technology has the potential to empower teachers and students
              alike and is passionate to provide endless possibilities to
              students around the globe, to develop new critical skills that can
              help shape personalities, through the platform ~ POLYMATH-KIDS.com
            </p>
          </div>
          <h3 className="mainHeading">
            Polymath-Kids.com and 21st Century Life Skills of Youth
          </h3>
          <p className="para">
            Polymath-Kids.com has been initiated with the vision to help prepare
            the next generation to thrive in the future. The basic tenet of the
            platform is to provide crucial 21st Century Life Skills such as
            Digital, Numeracy and Literacy, Creative and Analytical, as well as
            Social and Entrepreneurial Skills, which are decisive for children
            in their early stages and subsequently supports their transition
            into the workforce.
          </p>
          <br />
          <br />

          <h4 className="H4_Heading" style={{ color: "black", fontSize: "30px", fontWeight: "bold" }}>
            Why 21st Century Life Skills?
          </h4>
          <p className="para">
            21st Century skills generally refers to a wide set of knowledge,
            skills, behaviors and practices which are considered critical for
            achieving success in all aspects of life such as school and college
            programs, career choice and workplace. Although there is a long list
            of traits and skills which are considered highly important for the
            successful progression of a child, the following skills are deemed
            as the driving force for a thriving nation
            <br />
            <ul>
              <li>
                Information and communication technology (ICT) literacy, media
                and internet literacy, data interpretation and analysis,
                computer programming
              </li>
              <li>Critical thinking required for problem solving</li>
              <li>
                Listening skills, Oral and written communication, public
                speaking
              </li>
              <li>
                Reasoning, analysis, interpretation and ability to synthesize
                information
              </li>
              <li>Research skills</li>
              <li>Creativity, artistry and imagination</li>
              <li>Entrepreneurialism, innovation and personal expression</li>
              <li>
                Perseverance, self-direction, planning, self-discipline,
                adaptability, initiative
              </li>
              <li>Leadership, teamwork, collaboration and cooperation</li>
              <li>Economic and financial literacy</li>
              <li>Scientific literacy and reasoning, the scientific method</li>
              <li>
                Health and wellness literacy, including nutrition, diet,
                exercise, and public health and safety
              </li>
            </ul>
          </p>
          <br />
          <p className="para">
            {" "}
            <p className="para">
              According to a
              <a
                href="https://www.globalvillagespace.com/pakistans-education-lack-of-critical-thinking/"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                report (13 July, 2022)
              </a>
              published by Education Commission and UNICEF, around 3 in 4 youth
              lack critical skills needed for employment. The data from young
              individuals of low income countries highlights that majority of
              the children haven failed by their education system, leaving them
              incompetent and unproductive.
              <br />
              The shocking statistic by World
              <a
                href="https://www.globalvillagespace.com/pakistans-education-lack-of-critical-thinking/"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                Skills Clock
              </a>
              shows that 87.8 % of youth in Pakistan does not meet the
              benchmarks, in either literacy and-or numeracy, based on recent
              PISA/TIMSS data. Also, the recent data by World Economic Forum
              enlists Pakistan as 125th Worst Education System out of 130
              Countries. This requires a serious concern for addressing the
              critical gaps in Skills Development.
              <br />
              Polymath-Kids bridge this skill gap by providing a comprehensive
              platform to help children achieve success in all aspects of life
              such as school and college programs, career choice and workplace.
            </p>
          </p>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default AboutUs;
