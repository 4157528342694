// import React, { createContext, useState } from 'react';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [isAdmin, setIsAdmin] = useState(false);
//   const [isTeacher, setIsTeacher] = useState(false);
//   const [isStudent, setIsStudent] = useState(false);
//   const [isGuest, setIsGuest] = useState(true);

//   const setUserRole = (userRole) => {
//     setIsAdmin(userRole === 'admin');
//     setIsTeacher(userRole === 'teacher');
//     setIsStudent(userRole === 'student');
//     setIsGuest(userRole === 'guest');
//   };

//   const userContextValue = {
//     isAdmin,
//     isTeacher,
//     isStudent,
//     isGuest,
//     setUserRole,
//   };

//   return (
//     <UserContext.Provider value={userContextValue}>
//       {children}
//     </UserContext.Provider>
//   );
// };
// import React from 'react'
// import {createContext, useState} from "react";

// export const UserContext = createContext({});
// const UserContextProvider = ({children}) => {

//   const [isAdmin, setIsAdmin] = useState(false);
//   const [isTeacher, setIsTeacher] = useState(false);
//   const [isStudent, setIsStudent] = useState(false);
//   const [isGuest, setIsGuest] = useState(true);

//   const setUserRole = (userRole) => {
//     setIsAdmin(userRole === 'admin');
//     setIsTeacher(userRole === 'teacher');
//     setIsStudent(userRole === 'student');
//     setIsGuest(userRole === 'guest');
//   };

//   const userContextValue = {
//     isAdmin,
//     isTeacher,
//     isStudent,
//     isGuest,
//     setUserRole,
//   };

//   const [userInfo,setUserInfo] = useState({});
//     return (
//         <div>
//             <UserContext.Provider value={{userInfo,setUserInfo,userContextValue}}>
//               {children}
//             </UserContext.Provider>
//         </div>
//   );
// }

// export default UserContextProvider

import React, { createContext, useState } from 'react';

export const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const setUserRole = (userRole) => {
    setIsAdmin(userRole === 'Admin');
    setIsTeacher(userRole === 'Teacher');
    setIsStudent(userRole === 'Student');
    setIsGuest(userRole === 'guest');
  };

  const value = {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    userInfo,
    setUserRole,
    setUserInfo,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;












 // setIsGuest(false);
    // if (userRole === 'admin') {
    //   setIsAdmin(true);
    //   setIsTeacher(false);
    //   setIsStudent(false);
    //   setIsGuest(false);
    // } else if (userRole === 'teacher') {
    //   setIsAdmin(false);
    //   setIsTeacher(true);
    //   setIsStudent(false);
    //   setIsGuest(false);
    // } else if (userRole === 'student') {
    //   setIsAdmin(false);
    //   setIsTeacher(false);
    //   setIsStudent(true);
    //   setIsGuest(false);
    // }
    // else if (userRole === 'guest') {
    //   setIsAdmin(false);
    //   setIsTeacher(false);
    //   setIsStudent(true);
    //   setIsGuest(false);
    // }