import React,{useState} from "react";
import "./Home.css";
import footer from "./CourseImages/footer.png";
import Logo from "./CourseImages/Logo.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    try {
      const response = await addNewsLetterEmail(email);
      // Handle the API response as needed
      // console.log(response.data); // Log the response for debugging
      toast.success('You have been Subscribed successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setEmail("");
    } catch (error) {
      // console.error(error);
      toast.error('Email might already been subcribed!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setEmail("");

    }
  };
  

  const addNewsLetterEmail = async (email) => {
    try {
      // Make an Axios POST request to your API
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/addNewsletterEmail`, { email });
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <footer className="footer">
          <div className="footerContent">
            <div className="footerText">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "50%", height: "5%" }}
                className="py-3 my-3"
              />
              <br></br>
              <p>
                Polymath-Kids.com is market leader in grooming children's
                cognitive, leadership, social and people skills through our
                structured courses designed by our team of subject matter
                experts. We help your kids become a Polymath!
              </p>
            </div>
            <div className="footerLinksSection" style={{ marginTop: "5%" }}>
              <div className="footerLinks">
                <ul itemType="none">
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "3vh",
                    }}
                  >
                    Our Company
                  </p>
                  <Link
                    to={"/ourVision"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Our Vision
                  </Link>
                  <br />
                  <Link
                    to={"/PolymathKidSchool"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Polymath-kids@school
                  </Link>
                  <br />
                  <Link
                    to={"/AboutUs"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    About us
                  </Link>
                  <br />
                  <Link
                    to={"/TermsOfUse"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Terms of use
                  </Link>
                  <br />
                  <Link
                    to={"/Policy"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Privacy policy
                  </Link>
                  <br />
                  <Link
                    to={"/Cancellation"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Cancellation and refund policy
                  </Link>
                  <br />
                </ul>
              </div>
             
              <div className="footerLinks2">
                  <ul itemType="none">
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "3vh",
                      }}
                    >
                      Our Newsletter
                    </p>
                    <p style={{ width: "90%", color: "#aee6da" }}>
                      Subscribe to our newsletter and stay up to date with our events.
                    </p>
                    <input
                      style={{
                        border: 0,
                        borderRadius: "1vh",
                        width: "100%",
                        height: "5vh",
                      }}
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <br />
                    <br />
                    <button
                      style={{
                        backgroundColor: "#a1e8f0",
                        border: 0,
                        borderRadius: "1vh",
                        padding:"5%"
                      }}
                      onClick={handleSubscribe} // Call handleSubscribe when the button is clicked
                    >
                      Submit
                    </button>
                  </ul>
              </div>

              <div className="footerLinks1">
                <ul itemType="none">
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "3vh",
                    }}
                  >
                    Get in touch
                  </p>
                  <a style={{ color: "#aee6da" }}>info@polymath-kids.com</a>
                  <br />
                  <a style={{ color: "#aee6da" }}>+923266699907</a>
                  <br />
                  <Link to="/ContactUs" style={{ color: "#aee6da" }}>
                    Send a message
                  </Link>
                  <br />
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
