import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './ManageModuleProject.css';

const ManageModuleProject = ({ module }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null); // State variable to track the selected project for editing
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");

  useEffect(() => {
    fetchModulesProject();
  }, []);

  const fetchModulesProject = () => {
    axiosInstance
      .post('/getModuleProjectNameDescription', { module })
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = (projectId) => {
    const updatedProjects = projects.filter((project) => project.Module_Project_id !== projectId);

    const payload = { Module_Project_id: projectId };

    axiosInstance
      .post('/deleteModuleProjectAssignment', payload)
      .then(() => {
        console.log("Data has been sent to the server");
        console.log(payload.projectassignmentname);
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
  };


  const handleUpdate = (projectId) => {
    setSelectedProjectId(projectId); // Set the selected project id when clicking on "Update" button
    const selectedProject = projects.find((project) => project.Module_Project_id === projectId);
    setUpdatedName(selectedProject.projectassignmentname);
    setUpdatedDescription(selectedProject.projectassignmentdescription);
  };

  const handleSave = (projectId) => {
    const updatedProjects = projects.map((project) => {
      if (project.Module_Project_id === projectId) {
        return {
          ...project,
          editing: false,
          projectassignmentdescription: updatedDescription,
          projectassignmentname: updatedName,
        };
      }
      return project;
    });

    const selectedProject = updatedProjects.find((project) => project.Module_Project_id === projectId);

    const payload = { ...selectedProject };

    axiosInstance.post('/updateModuleProject', payload)
      .then(() => {
        console.log("Data has been sent to the server");
        console.log(payload.projectassignmentname);
      })
      .catch(error => {
        console.log("Internal server error", error);
      });

    setProjects(updatedProjects);
    setSelectedProjectId(null); // Reset selected project id after saving changes
  };

  return (
    <div className="manage-module-project">
      <h2>Manage Module Project</h2>
      <div className="project-list">
        {projects.map((project) => (
          <div className="project-card" key={project.Module_Project_id}>
            {selectedProjectId === project.Module_Project_id ? (
              <div className="edit-form">
                <input type="text" value={updatedName} onChange={(e) => setUpdatedName(e.target.value)} />
                <textarea value={updatedDescription} onChange={(e) => setUpdatedDescription(e.target.value)} />
                <button className="aqua-button" onClick={() => handleSave(project.Module_Project_id)}>Save</button>
              </div>
            ) : (
              <>
                <h3>{project.projectassignmentname}</h3>
                <p>{project.projectassignmentdescription}</p>
                <div className="mybuttons">
                  <button className="aqua-button" onClick={() => handleUpdate(project.Module_Project_id)}>Update</button>
                  <button className="aqua-button" onClick={() => handleDelete(project.Module_Project_id)}>Delete</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );

};

export default ManageModuleProject;
