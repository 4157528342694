import React,{useState} from 'react'
import './User.css'
import ProfilePic from "./user.png";
import { toast } from 'react-toastify';
import {Link} from 'react-router-dom'

const UserCard = ({ user, updateUserType,updateUserStatus }) => {
    const [userType, setUserType] = useState(user.usertype);
    const [deactivated, setDeactivated] = useState(user.deactivated);

    const handleUserTypeChange = (event) => {
      const selectedUserType = event.target.value;
      console.log(user.User_id,selectedUserType);
      updateUserType(user.User_id, selectedUserType,userType)
      .then(() => {
        // Update the user type in the local state
        setUserType(selectedUserType);
        toast.success('User Type has changed.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
    };
    const handleDeactivatedChange = (event) => {
        const isChecked = event.target.checked;
        setDeactivated(isChecked);
        const userStatus = isChecked ? 1 : 0;
        console.log(user.User_id, userStatus);
        updateUserStatus(user.User_id, userStatus)
          .then(() => {
            if(userStatus==1)
            toast.info(`${user.username} has been Deactivated.`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            else
            toast.info(`${user.username} has been Activated.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
          })
          .catch((error) => {
            console.error('Error updating user activation status:', error);
          });
      };
    
  return (
     <>
        <div className="MAcard">
        <div className="MAcard-head">
        <div className="MAdeactive-status">
            <input
              className="MAdeactive-checkbox"
              type="checkbox"
              checked={deactivated}
              onChange={handleDeactivatedChange}
            />
            <p id="deactivate-text">Deactivated</p>
          </div>


            <div className="MAprofile-picture">
                <img className="MAuser-profile-picture" src={ProfilePic} alt="profile-picture"/>
            </div>
            <div className="MAuser-role">
          <select
            className="MAdropdown-menu"
            name="role"
            value={userType}
            onChange={handleUserTypeChange}
          >
            <option value="Admin">Admin</option>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
          </select>
        </div>
        </div>

        {/* <!--User details--> */}

        <div className="MAuser-details">
            <div className="MAname-email-address">
                <p><b>Username: </b>{user.username}</p>
                <p><b>Parent: </b>{user.parentname}</p>
                <p>{user.email}</p>
                <p>{user.address}</p>
                <p><i>{userType}</i></p>
            </div>
            <div className="MAdob-phone">
                <p>{user.dateofbirth}</p>
                <p>{user.phonenumber}</p>

            </div>
        </div>

        {/* <!--Current Course and Module--> */}

        <div className="MAcourse-module">
            <div className="MAcourse">
                <select className="MAdropdown-menu" name="role">
                    <option value="course-1"></option>
                    <option value="course-2">course-2</option>
                    <option value="course-3"></option>
                </select>
            </div>

            <div className="MAmodule">
                <select className="MAdropdown-menu" name="role">
                    <option value="module-1"></option>
                    <option value="module-2">module-2</option>
                    <option value="module-3"></option>
                </select>
            </div>

            <div className="MAlesson">
                <select className="MAdropdown-menu" name="role">
                    <option value="lesson-1"></option>
                    <option value="lesson-2">lesson-2</option>
                    <option value="lesson-3"></option>
                </select>
            </div>

            <div className="MAsave-button">
                <button>Save</button>
            </div>

        </div>

        {/* <!--message--> */}

        <div className="MAtype-message">
            <input type="text" placeholder="Type Message here..."/>
        </div>

        {/* <!--Card Footer--> */}

        <div className="MAcard-foot">
            <div className="MAupper-left">
                <a href="">Progress</a>
            </div>

            <div className="MAupper-right">
                <a href="">Fee</a>
            </div>
            {userType === 'Student' &&
            <div className="MAlower-left">
            <Link to={`/AdminRegisterInCourse?username=${user.username}&User_id=${user.User_id}`}>Register in a Course</Link>
          </div>
            }


            <div className="MAlower-right">
                <a href="">Change Teacher & Class</a>
            </div>
        </div>

    </div>
        </>
  )
}

export default UserCard
