import React from "react";
import { useState, useEffect ,useContext} from "react";
import { Link } from "react-router-dom";

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav' 
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';

import { UserContext } from '../UserContext';


import Footer from '../Footer'
import "./SpaceSciencesCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";

import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./SpaceSciencesImages/spaceSciencesBackground.png";
import pic2 from "./SpaceSciencesImages/Astronomy.png"; 
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from "../components/FeeStructure";

const SpaceSciencesCourseDetails = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
    return (
     
      <div  >
        
       {/* Navbar */}
       {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
          
      <div className="SpaceSciencesPortalBackground">
         <section className="section">
           <p className="PageHead">SPACE SCIENCES PORTAL</p>
           <div className="PageDesc">
             <p>
             Exploring the mysteries of space has always been a fascinating subject for young minds. From understanding the origins of the universe to discovering new planets, space sciences open up a world of endless possibilities for learning and discovery. At Polymath-Kids, we offer a variety of courses that delve into the exciting world of space sciences. Our courses cover a wide range of topics, including astronomy, astrophysics, space exploration, and more. Join us on this incredible journey of discovery and exploration.
             </p>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">
  
               {/* <div className="btn-row-resp"> */}
               <div style={{color:"#e2e872", width: "auto", height: "50px" }}>
  
  
                 <a className="btn-row-resp button " href="#course">Individual Courses</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
  
  
                 <a className="btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>
               </div>
               
               <div style={{ width: "auto", height: "50px" }}>
  
                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>
  
  
               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                
  
               </div>
  
  
          
             </div>
           </div>
  
         </section>
         <div className="overlay"></div>
       </div>
       
  
  {/* Individual courses */}
  <div style={{ backgroundColor: '#d8f2fb' }} id="course">
         <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
         Space Sciences Individual courses
         </p>
  
         <div className="container"  >
  
           <MDBRow>
             <MDBCol md='4'>
               <div className=" col-xs-6">
                 <Link to="/Astronomy">
                   <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>
  
                     <h3 className="ind-course-title ">Astronomy</h3>
                 <div>
                       <img src={pic2} alt="" style={{width:"100px",height:"100px"}}/>
                   </div>
                   
                      </div>
                  
                 </Link>
               </div>
             </MDBCol>
              
            
            
          
           </MDBRow>
         </div>
  
  
       </div>
  
  
      {/* Fee Structure */}
      <FeeStructure subject="Space Sciences"/>
  
       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>
  
                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>
  
       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">
  
         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q: What is Astronomy?" >
               <strong>A: </strong>
               Astronomy is the study of celestial objects and phenomena that originate outside the Earth's atmosphere. It includes the study of planets, stars, galaxies, and other objects in the universe.
              </MDBAccordionItem>
             <MDBAccordionItem collapseId={2} headerTitle="Q: What topics are covered in Polymath-Kids' Astronomy course?">
               <strong>A: </strong>
               Polymath-Kids' Astronomy course covers a wide range of topics such as the solar system, stars and galaxies, the universe, constellations, and space exploration.
             </MDBAccordionItem>
  
  
             <MDBAccordionItem collapseId={3} headerTitle="Q: What age group is suitable for learning Astronomy?">
               <strong>A: </strong>
               Our Astronomy course is suitable for kids aged 8 to 18 years old. We have designed the curriculum to cater to the different age groups, with customized materials and teaching methods for each level.
             </MDBAccordionItem>
  
             <MDBAccordionItem collapseId={3} headerTitle="Q: Who will be teaching the Astronomy course?">
               <strong>A: </strong>
               Our Astronomy course is taught by experienced instructors who have a background in Astronomy or related fields. All our instructors undergo a rigorous selection process and are experts in their subject areas.
             </MDBAccordionItem>
  
             <MDBAccordionItem collapseId={3} headerTitle="Q: How is the Astronomy course structured?">
               <strong>A: </strong>
               Our Astronomy course is structured into modules, with each module covering a specific topic in Astronomy. Each module includes videos, interactive quizzes, and hands-on activities to help students better understand the concepts. At the end of each module, students are given a project to apply what they have learned.
             </MDBAccordionItem>
             <MDBAccordionItem collapseId={3} headerTitle="Q: How long is the Astronomy course?">
               <strong>A: </strong>
               A: The duration of the Astronomy course depends on the level of the course. The course for younger kids is shorter(12 weeks) and simpler, while the course for older kids is more comprehensive and longer. 
             </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
  
       </div>

  {/* Footer */}
  <Footer/>
  
   </div>
    )
}

export default SpaceSciencesCourseDetails
