import React,{useState,useContext,useEffect} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import './CourseRegistration.css'
import Footer from '../Footer';

import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { toast } from 'react-toastify';

const CourseRegistration = () => {
    
    const [discount, setDiscount] = useState(['10% Off','20% Off','30% Off']);
    
    function Registration({RegistrationCourse,classIDs}) {
        // console.log(RegistrationCourse);
        const filteredClassID = classIDs.find((CID) => RegistrationCourse.Course_id === CID.Course_id);
        const handleRegister = () => {
          // console.log(RegistrationCourse);
          // Make a POST request to the 'saveStudentCourse' API
          axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentCourse`, RegistrationCourse)
            .then(response => {
              // Handle the response if needed
              fetchRegistrationCourses();
              // console.log(response.data);
              toast.success('Student has been registered Successfully', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
            })
            .catch(error => {
              // Handle any errors that occurred during the request
              console.log('Error saving student course:', error);
            });
        };
        return (
             <>
             {
              RegistrationCourse.StatusComplete == 0 &&
           
            <tbody id="table-rows">
                <tr className='CourseRegistrationTable-tr'>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.firstname} {RegistrationCourse.lastname}</td>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.coursename}</td>
                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.RegistrationDate}</td>
                    {/* <td className='CourseRegistrationTable-td'>ABC</td> */}
                    <td className='CourseRegistrationTable-td'> <b>PKR</b> 20000</td>
                    <td className='CourseRegistrationTable-td'>
                        <select name="discount" id="discount-code" className='CourseRegistrationSelect'>
                        <option value="" className='CourseRegistrationOption'>Select Discount</option>
                       
                        {discount.map((discount) => (
                                    <option key={discount} value={discount}>
                                    {discount}
                                    </option>
                                ))}
                        </select>
                    </td>

                    <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" id="monthly-fee" name="fee-1" value="Monthly" 
                             checked={RegistrationCourse.PaymentType === "Monthly"} 
                            />
                            <label for="monthly-fee" className='CourseRegistrationLabel'>Monthly</label>
                        </div>
                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" id="one-time-fee" name="fee-2" value="Upfront" 
                             checked={RegistrationCourse.PaymentType === "Upfront"} 
                             />
                            <label for="one-time-fee" className='CourseRegistrationLabel'>One Time</label>
                        </div>
                    </td>

                    <td class="CourseRegistrationRadioType CourseRegistrationTable-td">

                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" id="group-class" name="class-1" value="group" 
                             checked={RegistrationCourse.ClassType === "group"}
                             />
                            <label for="group-class" className='CourseRegistrationLabel'>Group</label>
                        </div>

                        <div class="type-section">
                            <input className='CourseRegistrationInput' type="radio" id="one-on-one-class" name="class-2" value="one-one"
                             checked={RegistrationCourse.ClassType === "one-one"} // Assuming PaymentType is the variable storing the value
                             />
                            <label for="one-on-one-class" className='CourseRegistrationLabel'>One on One</label>
                        </div>

                    </td>

                    <td className='CourseRegistrationTable-td'>{RegistrationCourse.SchedulePreferenceDay} {RegistrationCourse.SchedulePreferenceTime}</td>

                    <td className='CourseRegistrationTable-td'>
                      <select name="discount" id="discount-code" className='CourseRegistrationSelect'>
                        {classIDs.map((CID) => {
                          // Check if RegistrationCourse.Course_id matches with the courseIDs in the array
                          if (RegistrationCourse.Course_id === CID.Course_id) {
                            return (
                              <option key={CID.ClassID} value={CID.ClassID}>
                                {CID.ClassID}
                              </option>
                            );
                          }
                          return null; // Render nothing if there is no match
                        })}
                      </select>
                    </td>
                        

                    {filteredClassID && (
                      <td className='CourseRegistrationTable-td' key={filteredClassID.ClassID} value={filteredClassID.ClassID}>
                        {filteredClassID.firstname} {filteredClassID.lastname}
                      </td>
                    )}

                    {/* <td className='CourseRegistrationTable-td'>{studentRegistration.classSchedule}</td> */}

                    <td className='CourseRegistrationTable-td'>
                        <button className='CourseRegistrationButton' onClick={handleRegister}>Register</button>
                    </td>
                </tr>
            </tbody> 
             }
            </>
        );
    };
    const [RegistrationCourses, setRegistrationCourses] = useState([]);
    const [CourseIDs, setCourseIDs] = useState([]);
    const [ClassIds, setClassIDs] = useState([]);

    useEffect(() => {
        fetchRegistrationCourses(); 
    }, []);
    useEffect(() => {
     
      fetchClassIDs(CourseIDs);
      // fetchRegistrationCourses(); 

  }, [RegistrationCourses]);
 
    const fetchRegistrationCourses = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getRegistrationCourses`);
          const data = response.data;
          const formattedData = data.registrationCourses.map(courseData => ({
            ...courseData,
            RegistrationDate: new Date(courseData.RegistrationDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
          }));
        setRegistrationCourses(formattedData);
        setCourseIDs(data.uniqueCourseIDs);
        } catch (error) {
          console.log(error);
        }
      };
      const fetchClassIDs = async (courseIds) => {
        try {
          console.log("in fetchClassIDs")
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getClassIDs/${courseIds}`);
          const data = response.data;
          setClassIDs(data.classIDs);
        } catch (error) {
          console.log(error);
        }
      };
      
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);
   
            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname in user.js: ", uname);
        
          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
   
          
         const onLogin = (usertype) => {
            
           setUserRole(usertype);
         };
        const navigate=useNavigate();
        async function onLogout() {
           console.log("inside Onlogout.");
         await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
             credentials: 'include',
             method: 'POST',
           });
           setUserInfo(null);
           console.log("navigating");
           navigate('/');
           // console.log("navigated");
           setUserRole('guest');
         }
   
         const username = userInfo?.username;

    return (
        <>
          {/* Navbar */}
          {username && (
       <>
       {/* <p>{username}</p> */}
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}
            {isTeacher && <TeacherNav   username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
        <table className='CourseRegistrationTable'>
            <thead>
                <tr className='CourseRegistrationTable-tr'>
                    <th className='CourseRegistrationTable-th'>Student</th>
                    <th className='CourseRegistrationTable-th'>Course</th>
                    <th className='CourseRegistrationTable-th'>Registration Date</th>
                    {/* <th className='CourseRegistrationTable-th'>School</th> */}
                    <th className='CourseRegistrationTable-th'>Fee</th>
                    <th className='CourseRegistrationTable-th'>Discount</th>
                    <th className='CourseRegistrationTable-th'>Fee Type</th>
                    <th className='CourseRegistrationTable-th'>Class Type</th>
                    <th className='CourseRegistrationTable-th'>Schedule Preference</th>
                    <th className='CourseRegistrationTable-th'>Class ID</th>
                    <th className='CourseRegistrationTable-th'>Teacher</th>
                    {/* <th className='CourseRegistrationTable-th'>Class Schedule</th> */}
                    <th className='CourseRegistrationTable-th'>Registration</th>
                </tr>
            </thead>
        {RegistrationCourses.map((RegistrationCourse, index) => (
            <Registration key={index} RegistrationCourse={RegistrationCourse} classIDs={ClassIds}  />
            ))}
            </table>
            <Footer/>
            
        </>
    );
};

export default CourseRegistration
