import React,{useContext,useEffect,useState} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import TeacherNav from "../Navbars/TeacherNav";
import './EditProfile.css'
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { toast } from 'react-toastify';


const EditProfile = () => {
  const [user, setUser] = useState({});
  const [user_id, setuser_id] = useState();

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
     await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}` , {
        credentials: 'include',
      }).then(response => {
          console.log("Response in EditProfile:",response);
        response.json().then(userInfo => {
          console.log("userinfo in EditProfile:",userInfo);
          setUserInfo(userInfo);
          setuser_id(userInfo?.User_id);
          console.log("user_id :",user_id);

          fetchUser(userInfo?.User_id);

          if(userInfo?.usertype)
          {
            const uType=userInfo?.usertype;
            setUserRole(uType);
          }
        const uname = userInfo?.username;
        const avatar = userInfo?.avatar; // Store the avatar in a separate variable
        console.log("Uname Editprofile: ", uname);
        // console.log("Avatar: ", avatar);

        })
        .catch(err=> console.log("Error in useeffect:",err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  // var formattedDateOfBirth
  const [formattedDateOfBirth, setFormatedDate] = useState();

  const setDate = (DOB) => {
     console.log("DOB",DOB);
    //  formattedDateOfBirth = new Date(DOB).toLocaleDateString('en-CA');
     setFormatedDate(new Date(DOB).toLocaleDateString('en-CA'));
     console.log("formattedDateOfBirth",formattedDateOfBirth);

    };
  const fetchUser = async (userId) => {
    // console.log("USERINFO: ",userInfo);
    console.log("userId: ",userId);
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUser/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data:",data)
        console.log("data.user:",data.user)
        setUser(data.user);
        // console.log(formattedDateOfBirth);
        setFormValues((prevValues) => ({
          ...prevValues,
          firstName: data.user.firstName || '',
          lastName: data.user.lastName || '',
          address: data.user.address || '',
          phonenumber: data.user.phonenumber || '',
          parentname: data.user.parentname || '',
          dateOfBirth: data.user.dateofbirth || '',
          gender: data.user.gender || '',
          profileImage: null,
        }));
        setDate(data.user.dateofbirth);
        // formattedDateOfBirth = new Date(data.user.dateofbirth).toLocaleDateString('en-CA');
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
   };


  // var rawDateOfBirth=user.dateofbirth;
  //  formattedDateOfBirth = new Date(rawDateOfBirth).toLocaleDateString('en-CA');

  const navigate=useNavigate();

  async function onLogout() {
    console.log("inside Onlogout.");
  await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    navigate('/');
    setUserRole('guest');
  }
  // const determineUserRole = (email, password) => {
  //   return "teacher";
  // };
    const onLogin = (usertype) => {

    setUserRole(usertype);

    };

  const username = userInfo?.username;
  const User_id = userInfo?.User_id;

  const [formValues, setFormValues] = useState({  });


    const handleUpdate = async (e) => {
      e.preventDefault();
    //   console.log("formValues",formValues)
      // console.log("userid:",userInfo?.User_id)

      const updatedData = {
        username:'',
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        address: formValues.address,
        phonenumber: formValues.phonenumber,
        parentname: formValues.parentname,
        dateOfBirth: formValues.dateOfBirth,
        gender: formValues.gender,
        avatar: formValues.profileImage,
      };

      // Make the PUT request to the API
    await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/editUser/${userInfo?.User_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          console.log('Update successful:', data);
          toast.success('Successfully Updated.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          // Handle errors
          console.error('Update failed:', error);
          toast.error('Edit details are not correct.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        });
    };

  return (
    <div style={{ marginTop: "0%" }}>
     {/* Navbar */}
     {username && (
       <>
       {/* <p>{username}</p>
       <p>{usertype}</p> */}

      {/* <img src={avatar} alt="Avatar" style={{width:"10vh",height:"10vh"}}/> */}


            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav    username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}


      <div className='edit-bar'>
        <h3 className='student-EditHeading'>
            <i class="fa fa-gear fa-spin"></i>

            Edit Profile

            {/* <span>
                <i class="fa-solid fa-arrow-right-from-bracket" style={{float:'right',paddingRight:"2%",paddingTop:"1%"}}>
               <span style={{fontSize:"10px"}}>
                logout
                </span>
                </i>

            </span> */}
        </h3>
      </div>

      <div class="StudentEditProfileContainer container">
  <form action="">
    <div class="row">
      <div class="col-25">
        {/* {user.firstName} */}
        <label  className="StudentEditProfileLabel" for="fname">firstName</label>
      </div>
      <div class="col-75">
        <input type="text" id="fname"
         name="firstname"
         className='editInput'
         placeholder="First Name"
         value={formValues.firstName}
         onChange={(e) =>
          setFormValues({ ...formValues, firstName: e.target.value })
        }
         />
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="lname">Last Name</label>
      </div>
      <div class="col-75">
        <input type="text" id="lname"
        name="lastname"
        className='editInput'
        placeholder="Last Name"
        value={formValues.lastName}
        onChange={(e) =>
         setFormValues({ ...formValues, lastName: e.target.value })
       }/>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="lname">Gender</label>
      </div>
      <div class="col-75">
        <h6>Male
        <input
            className='mx-3 editInput'
            type="radio"
            id="Male"
            name="gender"
            value="Male"
            checked={formValues.gender === 'Male'}
            onChange={(e) => setFormValues({ ...formValues, gender: e.target.value })}
          />
          Female
          <input
            className='mx-3 editInput'
            type="radio"
            id="Female"
            name="gender"
            value="Female"
            checked={formValues.gender === 'Female'}
            onChange={(e) => setFormValues({ ...formValues, gender: e.target.value })}
          />

        </h6>

      </div>
    </div>

    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="birthday">Birthday</label>
      </div>
      <div class="col-75">

      <input
          type="date"
          className='editInput'
          id="DOB"
          name="DOB"
          placeholder="Date of birth"
          value={formattedDateOfBirth}
          onChange={(e) =>{

            setFormValues({ ...formValues, dateOfBirth: e.target.value });
            setDate(e.target.value);

        } }
        />

      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="address">Address</label>
      </div>
      <div class="col-75">
        <input type="text"
        className='editInput'
        id="Address"
        name="Address"
        placeholder="Address"
        value={formValues.address}
        onChange={(e) =>
         setFormValues({ ...formValues, address: e.target.value })
       }/>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="phone">Phone No</label>
      </div>
      <div class="col-75">
        <input type="text"
        className='editInput'
        id="Phone"
        name="Phone"
        placeholder="Phone No."
        value={formValues.phonenumber}
        onChange={(e) =>
         setFormValues({ ...formValues, phonenumber: e.target.value })
       }/>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="guardian">Parent Name</label>
      </div>
      <div class="col-75">
        <input type="text"
        className='editInput'
        id="Guardian"
        name="Guardian"
        placeholder="GuardianName"
        value={formValues.parentname}
        onChange={(e) =>
         setFormValues({ ...formValues, parentname: e.target.value })
       }/>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label  className="StudentEditProfileLabel" for="DP">Profile Picture</label>
      </div>
      <div class="col-75">
      <input type="file"
       className='editInput'
       id="img"
       name="img"
       accept="image/*"
       onChange={(e) => setFormValues({ ...formValues, profileImage: e.target.files[0] })}
       />
      </div>
    </div>

    <div class="row">
      <input type="submit"
      className='SubmitBtn'
      value="Save"
      style={{backgroundColor:"rgb(0, 100, 128)"}}
      onClick={handleUpdate}
      />
    </div>
  </form>
        </div>

 {/* Footer */}
 <Footer/>

    </div>

  )
}

export default EditProfile
