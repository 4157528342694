import React,{useContext} from 'react'
import AdminNav from "../Navbars/AdminNav";
import StudentNav from '../Navbars/StudentNav'
import GuestNav from "../Navbars/GuestNav";
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import TeacherNav from "../Navbars/TeacherNav";
import './AttendClass.css'

const AttendClass = () => {
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole } = useContext(UserContext);
  const determineUserRole = (email, password) => {
    // console.log('Email:', email);
    // console.log('Password:', password); 

        return "teacher";
  };
  const onLogin = (email, password) => {
    const userRole = determineUserRole(email, password);
    setUserRole(userRole);
  };
  return (
    <>
     {/* Navbar */}
     {isGuest && <GuestNav onLogin={onLogin} />}
        {isAdmin && <AdminNav />}
        {isTeacher && <TeacherNav />}
        {isStudent && <StudentNav />}

    <div className='AttendClassJoinClass'>
    <h2 className="Testimonal_Heading" style={{color:"#03b4c6",fontSize:"25px",margin:0}}>Coding Scratch Programming</h2>

    <div className='AttendClassBtn'>

    <button className='AttendClassjoinBtn'>Join Class</button>
    </div>

    <div className='AttendClassRate'>
        <p>Please share your feedback for this class</p>
        <i class="fa-regular fa-star mx-2"></i>
        <i class="fa-regular fa-star mx-2"></i>
        <i class="fa-regular fa-star mx-2"></i>
        <i class="fa-regular fa-star mx-2"></i>
        <i class="fa-regular fa-star mx-2"></i>
        <br />
        <br />
        <br />
        <input type="text"placeholder='Comments' className='AttendClassAttendInput' />
        <button className='AttendClassSendRating'>Submit Rating</button>
    </div>

    </div>

    
 {/* Footer */}
 <Footer/>

    </>
  )
}

export default AttendClass
