import React, { useState } from 'react';
import './managecontent.css'
import { Form, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Data from './GetData';
import { toast } from 'react-toastify';
import  instance  from './axiosInstance';

const CreateCourse = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [ageGroup, setAgeGroup] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !description || !ageGroup) {
      console.log("Please fill in all fields");
      return;
    }

    const payload = {
      coursename: name,
      coursedescription: description,
      Age: ageGroup,
    };

    instance.post('addCourses', payload)
    .then(() => {
      console.log("Data has been sent to the server");
      setName("");
      setAgeGroup("");
      setDescription("");
      toast.success("Course added successfully", {
        position: toast.POSITION.TOP_RIGHT
      });
    })
    .catch(() => {
      console.log("Internal server error" + payload);

      // Display an error toast message
      toast.error("Internal server error", {
        position: toast.POSITION.TOP_RIGHT
      });
    });

    Data.setStatusCourse("Invalid...");
  };

  return (
    <div className="create-course-container">
      <h1>Create Course</h1>
      <form onSubmit={handleSubmit} id="addcourse">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="ageGroup">Age Group</label>
          <input
            type="text"
            id="ageGroup"
            name="ageGroup"
            value={ageGroup}
            onChange={(e) => setAgeGroup(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary save-button">
          Save
        </button>
      </form>
    </div>
  );
};

export default CreateCourse;
