import React, { useState,useContext,useEffect } from 'react'
// import "../Home.css";
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import Footer from '../Footer'
import { UserContext } from '../UserContext';
import logo1 from './MathCourseDetailImages/benefit1.png'
import logo2 from './MathCourseDetailImages/benefit2.png'
import logo3 from './MathCourseDetailImages/benefit3.png'
import logo4 from './MathCourseDetailImages/benefit4.png'
import logo5 from './MathCourseDetailImages/benefit5.png'
import logo6 from './MathCourseDetailImages/benefit6.png'
import logo7 from './MathCourseDetailImages/benefit7.png'
import logo8 from './MathCourseDetailImages/benefit8.png'
import logo9 from './MathCourseDetailImages/benefit9.png'
import logo10 from './MathCourseDetailImages/benefit10.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import "./MathCourseDetails.css";
import "./MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer, MDBNavbarToggler } from "mdb-react-ui-kit";
const arr = [logo1, logo2, logo3, logo4, logo5, logo6, logo9, logo10,logo7 , logo8]
 
const cols = ['#bd2e3e', '#00a9b9', '#c42e3e', '#085d66','#b32e3e','#00a9c9','#e6a721','#f26362','#006059','#72c752'];
const BenefitDetail = ({selectedCourse}) => {

    const handleCourseSelect = (course) => {
        // console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
  
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
    const username = userInfo?.username;
    return (
        
        <div >
  {/* Navbar */}
  {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
       
    <section className='benefit-detail-background benefit-detail-background-resp'>
        <p className="PageHead" style={{ color: "#8b0194" }}>
            How Student can benefit from abacus

        </p>
        <div className='text-center' style={{ fontFamily: "sans-serif", fontSize: "3vh", marginLeft: "20%", marginRight: "20%" }} >
            <p>The research related to human brain establishes that there are two parts of human brain, the left brain and the right brain. The left-brain is called digital brain and is more verbal, analytical and orderly than right brain. It helps you with logic, sequencing, linear thinking, mathematics, facts and thinking about words. The right brain is also called analog brain and is more visual and intuitive. The right brain helps people with imagination, holistic thinking, arts, rhythm, non-verbal cues and visualization.
                In order to enhance mental activities in children, it is crucial to activate human brain in the formative years. Activating the brain means developing the brain muscles by practicing mental activities and lifetime skills. Research indicates that the mental development achieved by a child before the age of 12 is crucial as the mental abilities developed before this age has a substantial influence on the child.

                Learning to use Abacus from an early age helps in activating brain, tapping intelligence, enhancing ideation smartness and making accurate calculation without using computer. Authentic research reports many benefits of Abacus based Mental Calculations. Some of them are listed below.
            </p>
        </div>

        {/* Faqs */}
        <div style={{ maxWidth: '100%' }} className=" wrapper">
            <div className="benefit-resp ">
                {data.map((item, j) => (
                    <div className=''  style={{ maxWidth: "200vh" }}>
                        <div className="gfg benefit-resp" style={{color:cols[j], border: "1px solid", height: "120px" }}>
                            <div className='imgbene-resp'>
                                <img className='imgbene-resp' src={arr[j]} />
                                {/* <div style={{wordBreak:"break-word",maxWidth:"10vh"}}>  */}

                                <h3 class="second-txt benefit-resp-text" style={{
                                //     marginLeft:"0vh",
                                // width: "30vh", fontSize:"15px",
                                color:cols[j] }}>
                                    {item.Question}
                                    {/* </div> */}
                                </h3>
                                <span className='span arrow-resp' style={{
                                    cursor: 'pointer', color:cols[j]
                                }}>

                                    {selected === j ?
                                        <i className="fa-solid fa-angle-up arrowRespFont" onClick={() => toggle(j)}></i>
                                        :
                                        <i className="fa-solid fa-angle-down arrowRespFont" onClick={() => toggle(j)}></i>
                                    }
                                </span>
                            </div>


                        </div >


<div className=' '>

                        <div className={selected === j ? 'content show benefit-resp2 ' : 'content benefit-resp2 '} style={{color:cols[j], maxWidth: "150vh", background: "white",  border: '1px solid' }}>{item.answer}
                        </div>
</div>
                    </div>



                ))
                }
            </div>


        </div>

    </section>

{/* Footer */}
<Footer/>


        </div>
    )
}


const data = [
    {
        Question: "Whole brain development",
        answer: " The research by Kiran (2009) concluded that Abacus can be used as an effective instrument in activating the children brain. The Abacus user starts learning by using both hands on the physical Abacus to move the beads. With practice and training, they are able to perform same calculations by manipulating the beads of the imaginative virtual Abacus in their minds. The use of physical and virtual Abacus helps in successfully developing both hemispheres of the brain and leads to whole brain development ",


    },
    {
        Question: "Proficiency in Mathematics ",
        answer: " The research by Kiran (2009) concluded that Abacus can be used as an effective instrument in activating the children brain. The Abacus user starts learning by using both hands on the physical Abacus to move the beads. With practice and training, they are able to perform same calculations by manipulating the beads of the imaginative virtual Abacus in their minds. The use of physical and virtual Abacus helps in successfully developing both hemispheres of the brain and leads to whole brain development ",

    },
    {
        Question: "Develops speed and accuracy  ",
        answer: "Continuous practice of Mental Arithmetic leads to increase the speed and accuracy of the numerical calculations. It has been proven by many researchers through various controlled tests and studies.Expert abacus students can easily calculate very rapidly without an abacus, just by relying on the mental representation of their virtual abacus (Hatta & Miyazaki, 1989).        Also, according to Stigler (1984), abacus training has both quantitative and qualitative effects on children mental calculation skills. "
    },
    {
        Question: "Personality development ",
        answer: "All the above mentioned benefits of abacus brain training, harnesses the natural potential in a child. It gives them confidence and leads to an overall personality development which helps them in their pursuits of life."
    },
    {
        Question: "Improves Concentration, Observation and Memory",
        answer: "Abacus is an effective tool which provide multi-sensory learning environment to the learners. According to brain researchers, children learn best when more than one sense modality is used for learning (Winters, 1994). Abacus students make use of both their hands to move the beads on physical abacus. Also, they perform oral and mental calculations where they have to focus their attention to the voice, remember the numbers and calculate the questions imagining their abacus.  This multi-sensory learning environment offers the best opportunity for child to acquire the skills of concentration, listening, speed, accuracy, imagination, innovation, creativity and photographic memory.        The results of an experimental research by Wengang, et al. (2004) revealed that the students receiving Abacus based Mental Arithmetic training exhibited superior brain physiological functions as compared to students without Abacus training. Also, the Abacus students demonstrated advantage in focusing their attention, extracting information, intellectual levels and perception ability."
    },
    {
        Question: "Enriches Visual and listening skill ",
        answer: "In order to learn Abacus, a variety of teaching techniques are used such as looking at a series of numbers to find the value of the abacus, listening to the voice and calculating the numbers in their minds and looking at the pictures and finding the value of the abacus. All these learning techniques lead to develop strong visual and listening skills.The research by Bhaskaran, et al., (2006), evaluated the Abacus learners and non-Abacus learners using the Wechsler memory scale test. The results indicated that the Abacus learners had an improved visual and auditory memory at the end of one and two years, as compared to the other group of students."
    },
    {
        Question: "Sharpens Cognitive skills",
        answer: "Abacus based Brain training helps in sharpening cognitive skills in children. Cognitive skills are the major skills that our brain uses to help us think, pay attention, remember, reason and utilize other functions of the brain. The cognitive abilities of a child help recognize and process new information. It stores information in the brain for access and use at a later stage. These cognitive abilities are flexible and can be improved with training.  Developing cognitive abilities helps in incorporating and processing information more efficiently.According to the review study by Wang (2020), Abacus based Mental Arithmetic Brain training has the potential to boost various cognitive skills including mathematics, working memory and numerical magnitude processing. "
    },
    {
        Question: "Supports dyslexic / dyscalculia children ",
        answer: "Abacus Education helps children suffering from dyslexia or dyscalculia. These mathematical learning disabilities negatively affect individuals by severely affecting their ability to perform arithmetical calculations, reading, spelling, and writing. Abacus can help improve their numerical skills as these children sense by touch. The use of Physical Abacus supports their learning process by improving their mathematical calculations, memory, motor skills and general learning and development.A research conducted on Developmental Dyscalculia by Lu et al., (2021), demonstrated that students with an abacus course revealed better performance in arithmetic computation and spatial short‐term memory and other basic cognitive abilities. The results of the study suggest that the abacus brain training could be an effective tool for Dyscalculia intervention in natural education settings."
    }, 
    {
        Question: "Boosts Confidence and Problem Solving Ability",
        answer: "Abacus brain training, not only improves the mathematical skills but also helps to improve overall academic skills which leads to boost confidence and problem-solving capacity of the learners.Manchanda & Sood, (2012) studied the effect of abacus mental arithmetic program on self-confidence of students in mathematics. Their results indicated that the Abacus based Mental training significantly helps in boosting self confidence in boys as well as girls.     Also, an investigation of effects of Abacus training on 3185 Sudanese children by Irwing et al., (2008) revealed that students receiving Abacus training gained a significant IQ point, performed significantly faster and had a greater impact on problem solving skills."
    },
    {
        Question: "Enhances spatial ability and logical understanding",
        answer: "Spatial ability is the capacity to understand, reason and remember the visual and spatial relations among objects or space. Abacus Brain Training enables spatial ability which is applied in areas such as Science, Architecture, Engineering, etc. In 2021, Lu et al., conducted a study on 343 Vocational schools/colleges and found that Abacus Brain training helps to promote calculation and spatial abilities. "
    },


]
export default BenefitDetail
