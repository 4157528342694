import React, { useState, useContext } from "react";
import "./managecontent.css";
import Footer from "../Footer"; // Import your footer component here
import AdminNav from "../Navbars/AdminNav"; // Import your AdminNav component here
import GuestNav from "../Navbars/GuestNav"; // Import your GuestNav component here
import CreateCourse from "./managecontentforms/CreateCourse";
import CreateCourseModule from "./managecontentforms/CreateCourseModule";
import CreateLessonActivity from "./managecontentforms/CreateLessonActivity";
import CreateLessonPlan from "./managecontentforms/CreateLessonPlan";
import CreateLessonproject from "./managecontentforms/CreateLessonProject";
import CreateModuleLesson from "./managecontentforms/CreateModuleLesson";
import CreateModuleProject from "./managecontentforms/CreateModuleProject";
import ManageCourse from "./managecontentforms/ManageCourse";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useEffect } from "react";

const Managecontent = () => {
  const [selectedForm, setSelectedForm] = useState("");
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        response
          .json()
          .then((userInfo) => {
            setUserInfo(userInfo);

            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar;
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest, setUserInfo, setUserRole]);
  const determineUserRole = (usertype) => {
    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    setUserRole(userRole);
  };

  function onLogout() {
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    })
    .then(() => {
      navigate('/');
    })
    .catch(error => {
      console.error("Logout failed:", error);
    });
    setUserInfo(null);
    setUserRole("guest");
  }

  const username = userInfo?.username;

  const handleFormSelect = (formName) => {
    setSelectedForm(formName);
  };

  const renderForm = () => {
    switch (selectedForm) {
      case "createCourse":
        return <CreateCourse />;
      case "createCourseModule":
        return <CreateCourseModule />;
      case "createModuleLesson":
        return <CreateModuleLesson />;
      case "createLessonActivity":
        return <CreateLessonActivity />;
      case "createLessonPlan":
        return <CreateLessonPlan />;
      case "createLessonProject":
        return <CreateLessonproject />;
      case "createModuleProject":
        return <CreateModuleProject />;
      case "ManageCourse":
        return <ManageCourse />;
      default:
        return null;
    }
  };

  return (
    <div>
    {username && (
      <>{isAdmin && <AdminNav username={username} onLogout={onLogout} />}</>
    )}
    {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}
    <div className="forms-wrapper">
      <div className="form-column">
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createCourse"
            name="form"
            className="custom-radio"
            value="createCourse"
            checked={selectedForm === "createCourse"}
            onChange={() => handleFormSelect("createCourse")}
          />
          <label htmlFor="createCourse">Create Course</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createCourseModule"
            name="form"
            className="custom-radio"
            value="createCourseModule"
            checked={selectedForm === "createCourseModule"}
            onChange={() => handleFormSelect("createCourseModule")}
          />
          <label htmlFor="createCourseModule">Create Course Module</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createModuleLesson"
            name="form"
            className="custom-radio"
            value="createModuleLesson"
            checked={selectedForm === "createModuleLesson"}
            onChange={() => handleFormSelect("createModuleLesson")}
          />
          <label htmlFor="createModuleLesson">Create Module Lesson</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createLessonActivity"
            name="form"
            className="custom-radio"
            value="createLessonActivity"
            checked={selectedForm === "createLessonActivity"}
            onChange={() => handleFormSelect("createLessonActivity")}
          />
          <label htmlFor="createLessonActivity">Create Lesson Activity</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createLessonPlan"
            name="form"
            className="custom-radio"
            value="createLessonPlan"
            checked={selectedForm === "createLessonPlan"}
            onChange={() => handleFormSelect("createLessonPlan")}
          />
          <label htmlFor="createLessonPlan">Create Lesson Plan</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createModuleProject"
            name="form"
            className="custom-radio"
            value="createModuleProject"
            checked={selectedForm === "createModuleProject"}
            onChange={() => handleFormSelect("createModuleProject")}
          />
          <label htmlFor="createModuleProject">Create Module Project</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="createLessonProject"
            name="form"
            className="custom-radio"
            value="createLessonProject"
            checked={selectedForm === "createLessonProject"}
            onChange={() => handleFormSelect("createLessonProject")}
          />
          <label htmlFor="createLessonProject">Create Lesson Project</label>
        </div>
        <div className="form-item manage-course custom-radio">
          <input
            type="radio"
            id="ManageCourse"
            name="form"
            className="custom-radio"
            value="ManageCourse"
            checked={selectedForm === "ManageCourse"}
            onChange={() => handleFormSelect("ManageCourse")}
          />
          <label htmlFor="ManageCourse">Manage Course</label>
        </div>
      </div>
      <div className="form-display">{renderForm()}</div>
    </div>
    <Footer />
  </div>
  );
};

export default Managecontent;
