import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "./managecontent.css";
import { toast } from "react-toastify";

function CreateLessonPlan() {
  const [course, setCourse] = useState("");
  const [module, setModule] = useState("");
  const [lesson, setLesson] = useState("");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [time, setTime] = useState("");

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance
      .post("/getAllLesson", { moduleId })
      .then((response) => {
        const filteredLessons = response.data.filter(
          (lesson) => lesson.Module_id === parseInt(moduleId)
        );
        setLessons(filteredLessons);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLesson(lessonId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() === '' || detail.trim() === '' || time.trim() === '') {
      document.getElementById('name-input').classList.add('invalid-input');
      document.getElementById('detail-input').classList.add('invalid-input');
      document.getElementById('time-input').classList.add('invalid-input');
      toast.error('Please fill out all required fields.', {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    const payload = {
      Lesson_id: lesson,
      itemname: name,
      itemtime: time,
      itemdetails: detail,
    };

    axiosInstance
      .post("/addLessonPlan", payload)
      .then((response) => {
        setCourse("");
        setModule("");
        setLesson("");
        setName("");
        setDetail("");
        setTime("");

        toast.success("Lesson Plan has been added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log("Error");
        console.log(error);
      });
  };


  return (
    <div className="create-lesson-plan">
      <h2>Create Lesson Plan</h2>
      <Form>
        <FormGroup>
          <Label for="course-select">Course</Label>
          <Input
            type="select"
            name="select"
            id="course-select"
            value={course}

            onChange={(event) => handleCourseSelect(event.target.value)}
            required
          >
            <option>Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.Course_id}>
                {course.coursename}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="module-select">Module</Label>
          <Input
            type="select"
            name="select"
            id="module-select"
            value={module}
            onChange={(event) => handleModuleSelect(event.target.value)}
            required
          >
            <option>Select a Module</option>
            {modules.map((module) => (
              <option key={module.id} value={module.Module_id}>
                {module.modulename}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="lesson-select">Lesson</Label>
          <Input
            type="select"
            name="select"
            id="lesson-select"
            value={lesson}
            onChange={(event) => handleLessonSelect(event.target.value)}
            required
          >
            <option>Select a Lesson</option>
            {lessons.map((lesson) => (
              <option key={lesson.id} value={lesson.Lesson_id}>
                {lesson.lessonname}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name-input">Name</Label>
          <Input
            type="text"
            name="name"
            id="name-input"
            placeholder="Enter name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="detail-input">Detail</Label>
          <Input
            type="textarea"
            name="detail"
            id="detail-input"
            placeholder="Enter detail"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="time-input">Time</Label>
          <Input
            type="text"
            name="time"
            id="time-input"
            placeholder="Enter time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </FormGroup>
        <button className="save-button" type="button" onClick={handleSubmit}>
          Save
        </button>
      </Form>
    </div>
  );
}

export default CreateLessonPlan;
