import '../AdminActions/ManageClasses.css'
import './RegisterInCourse.css'

import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer';
import axiosInstance from '../ManageContent/managecontentforms/axiosInstance';
import {toast} from 'react-toastify';

const RegisterInCourse = () => {
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole } = useContext(UserContext);
  const determineUserRole = (email, password) => {
    return "admin";
  };
  const onLogin = (email, password) => {
    const userRole = determineUserRole(email, password);
    setUserRole(userRole);
  };

  const [courseName, setCourseName] = useState('');
  const [courseType, setCourseType] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [courses, setCourses] = useState([]);
  const [discountCode, setDiscountCode] = useState('');

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const fetchCourses = () => {
    axiosInstance.post('/getAllCourses')
      .then(response => {
        setCourses(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  const handleSlotSelection = (slot) => {
    const updatedSlots = [...selectedSlots];
    const index = updatedSlots.indexOf(slot);

    if (index > -1) {
      updatedSlots.splice(index, 1); // Remove slot from the list
    } else {
      updatedSlots.push(slot); // Add slot to the list
    }

    setSelectedSlots(updatedSlots);
  };

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const times = ['10am-12pm', '12pm-3pm', '3pm-6pm', '6pm-9pm'];
  const [selectedSlots, setSelectedSlots] = useState([]);

  const handleRegistration = () => {

    const courseid1 = courses.find(c => c.coursename === courseName)?.Course_id;
    // Prepare the payload
    const payload = {
      Course_id: courseid1,
      Student_id: '132',
      ClassType: courseType,
      Currency:"PKR",
      Discount_id: discountCode ? discountCode : "No",
      PaymentType: paymentType,
      SchedulePreferenceDay:selectedSlots[1],
      SchedulePreferenceTime:selectedSlots[0],
      StatusComplete:"Pending",
    };

    // Make the API call
    axiosInstance.post('/registerincourse', payload)
      .then(response => {
        toast.success("Student Register Successfully",{
          position:toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(error => {
        console.error('Error registering the student inz: ', error);
        // Handle the error case
      });
  };

  return (
    <>
      {isGuest && <GuestNav onLogin={onLogin} />}
      {isAdmin && <AdminNav />}
      {isTeacher && <TeacherNav />}
     {isStudent && <StudentNav />}
      <h3 className='ManageClassHeading'>
        Register In Course
      </h3>
      <div className='ManageClassesFormDiv' style={{ borderRadius: "2vh", backgroundColor: "#34979c" }}>
        <form className='ManageClassesForm' style={{ width: "65%" }}>
          <select className='ManageClassDropdown' style={{ margin: "2%" }} value={courseName} onChange={handleCourseNameChange}>
            <option>Select a course</option>
            {courses.map((course, index) => (
              <option key={index} value={course.courseId}>{course.coursename}</option>
            ))}
          </select>

          <select className='ManageClassDropdown' style={{ margin: "2%" }} value={courseType} onChange={handleCourseTypeChange}>
            <option>Select a CourseType</option>
            <option value="group">Group</option>
            <option value="one-on-one">One-on-One</option>
          </select>

          <select className='ManageClassDropdown' style={{ margin: "2%" }} value={paymentType} onChange={handlePaymentTypeChange}>
            <option>Select a PaymentType</option>
            <option value="Upfront">Upfront</option>
            <option value="monthly">Monthly</option>
          </select>

        </form>
      </div>

      <div className='RegisterInCoursePreferences'>
        <div className='days'>
          {days.map((day) => (
            <div key={day} className='RegisterInCoursePreferencesDays'>
              <input
                type="checkbox"
                checked={selectedSlots.includes(day)}
                onChange={() => handleSlotSelection(day)}
              />
              <label>{day}</label>
            </div>
          ))}
        </div>
        <div className="Times">
          {times.map((time) => (
            <div key={time} className='RegisterInCoursePreferencesTimes'>
              <input
                type="checkbox"
                checked={selectedSlots.includes(time)}
                onChange={() => handleSlotSelection(time)}
              />

              <label>{time}</label>
            </div>
          ))}
        </div>
      </div>

      <div className='PaymentMessage'>
        {paymentType === 'Upfront' && (
          <div style={{ fontWeight: "bold", fontSize: "3vh", textAlign: "center" }}>
            <p style={{ color: "red" }}>
              <del>PKR / Lessons </del>
            </p>
            <p>PKR / Lessons % Off</p> <br />
            OR
            <p>Use Monthly Payment Type to avail easy Monthly Payments</p>

          </div>
        )}

        {paymentType === 'monthly' && (
          <div style={{ fontWeight: "bold", fontSize: "3vh", textAlign: "center" }}>
            <p>PKR / Lessons</p> <br />
            OR <br />
            <p>Use Upfront Payment Type to avail DISCOUNT</p>
          </div>
        )}
      </div>

      <div className='DiscountCode'>
        <input type="text" placeholder='Enter Discount Code' className='DiscountInputCode' value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
        <button className='ManageClassCreateButton RegisterCourseButton' onClick={handleRegistration}>Register</button>
      </div>

      <Footer />
    </>
  )
}

export default RegisterInCourse
