import React, { useState,useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import './managecontent.css';
import axiosInstance from './axiosInstance';
import {toast} from 'react-toastify';

function CreateModuleProject() {
  const [course, setCourse] = useState('');
  const [module, setModule] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [projectURL, setProjectURL] = useState('');
  const [helpingMaterialURL, setHelpingMaterialURL] = useState('');
  const [totalMarks, setTotalMarks] = useState('');
  const [image, setImage] = useState(null);

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [savingpath,setSavingPath] = useState([]);


  useEffect(() => {
    fetchCourses();
    fetchPath();
  }, []);

  const fetchPath = () => {
    axiosInstance
      .post("/getsavingpath", { configname: "Module_Project" })
      .then((response) => {
        setSavingPath(response.data[0].ConfigValue);
        console.log("Path:",savingpath)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCourses = () => {
    axiosInstance.post('/getAllCourses')
      .then(response => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance.post('/getAllModule', { courseId })
      .then(response => {
        const filteredModules = response.data.filter(module => module.Course_id ===  parseInt(courseId, 10));
        setModules(filteredModules);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Check if any of the input fields are empty
    if (
      module.trim() === '' ||
      name.trim() === '' ||
      description.trim() === '' ||
      projectURL.trim() === '' ||
      helpingMaterialURL.trim() === '' ||
      totalMarks.trim() === ''
    ) {
      toast.error('Please fill out all required fields.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Create a FormData object to send the data, including the selected image
    const formData = new FormData();
    formData.append('Module_id', module);
    formData.append('projectassignmentname', name);
    formData.append('projectassignmentdescription', description);
    formData.append('projectassignmenturl', projectURL);
    formData.append('helpingmaterialurl', helpingMaterialURL);
    formData.append('projectmarks', totalMarks);
    formData.append('savingpath', savingpath);
    formData.append('image', image);

    // Proceed with the API call and form submission
    axiosInstance
      .post('addModuleProject', formData)
      .then((response) => {
        // Handle success response
        console.log(response.data);
        setCourse('');
        setModule('');
        setName('');
        setDescription('');
        setProjectURL('');
        setHelpingMaterialURL('');
        setTotalMarks('');
        setImage('');
        toast.success('Module Lesson has been Added', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };


  return (
    <div className="create-module-project" >
      <h2>Create Module Project</h2>
      <Form onSubmit={handleSave}>
        <FormGroup>
          <Label for="course-select">Course</Label>
          <Input
            type="select"
            name="select"
            id="course-select"
            value={course}
            required
            onChange={(event) =>handleCourseSelect(event.target.value)}
          >
            <option>Select a course</option>
            {courses.map(course => (
              <option key={course.id} value={course.Course_id}>{course.coursename}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="module-select">Module</Label>
          <Input
            type="select"
            name="select"
            id="module-select"
            value={module}
            required
            onChange={(event) =>handleModuleSelect(event.target.value)}
          >
            <option>Select a Module</option>
            {modules.map(module => (
              <option key={module.id} value={module.Module_id}>{module.modulename}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name-input">Name</Label>
          <Input type="text" name="name" id="name-input"  required placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="description-input">Description</Label>
          <Input type="textarea" name="description"  required id="description-input" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="project-url-input">Project URL</Label>
          <Input type="text" name="project-url" id="project-url-input" required placeholder="Enter project URL" value={projectURL} onChange={(e) => setProjectURL(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="helping-material-url-input">Helping Material URL</Label>
          <Input type="text" name="helping-material-url" required id="helping-material-url-input" placeholder="Enter helping material URL" value={helpingMaterialURL} onChange={(e) => setHelpingMaterialURL(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="">Total Marks</Label>
          <Input type="text" name="total-marks"  required id="total-marks-input" placeholder="Enter total marks" value={totalMarks} onChange={(e) => setTotalMarks(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="image-upload">Click to Upload Image</Label>
          <Input type="file" name="image" id="image-upload" required accept="image/*" onChange={handleFileChange} />
        </FormGroup>
        <button className="save-button" type="submit">Save Project</button>
     </Form>
    </div>
);
}

export default CreateModuleProject;
