import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav'; 
import Footer from '../Footer';
import './AdminRegisterInCourse.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const AdminRegisterInCourse = () => {
  const [discount, setDiscount] = useState(['10% Off','20% Off','30% Off']);
    
  function Registration({RegistrationCourse,classIDs}) {
    // console.log(RegistrationCourse);
    const filteredClassID = classIDs.find((CID) => RegistrationCourse.Course_id === CID.Course_id);
  //  console.log('flc',filteredClassID);
  //  console.log('cid',classIDs);
    return (
         <>
         {
          RegistrationCourse.StatusComplete == 1 &&
       
        <tbody id="table-rows">
            <tr className='CourseRegistrationTable-tr'>
                <td className='CourseRegistrationTable-td'>
                  {RegistrationCourse.firstname} {RegistrationCourse.lastname}
                </td>
                <td className='CourseRegistrationTable-td'>{RegistrationCourse.coursename}</td>
                <td className='CourseRegistrationTable-td'>{RegistrationCourse.RegistrationDate}</td>
                <td className='CourseRegistrationTable-td'> <b>{RegistrationCourse.Currency}</b></td>
                <td className='CourseRegistrationTable-td'>
                    {RegistrationCourse.Discount_id}
                </td>

                <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                    {RegistrationCourse.PaymentType}
                </td>

                <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                    {RegistrationCourse.ClassType}
                </td>
                {/* <td className='CourseRegistrationTable-td'>
                  {RegistrationCourse.ClassType} 
                </td> */}

                <td className='CourseRegistrationTable-td'>{RegistrationCourse.SchedulePreferenceDay} 
                 - {RegistrationCourse.SchedulePreferenceTime}</td>

                <td className='CourseRegistrationTable-td'>
                  <select name="discount" id="discount-code" className='CourseRegistrationSelect'>
                    {classIDs.map((CID) => {
                      // Check if RegistrationCourse.Course_id matches with the courseIDs in the array
                      if (RegistrationCourse.Course_id === CID.Course_id) {
                        return (
                          <option key={CID.ClassID} value={CID.ClassID}>
                            {CID.ClassID}
                          </option>
                        );
                      }
                      return null; // Render nothing if there is no match
                    })}
                  </select>
                </td>
                    

                {filteredClassID && (
                  <td className='CourseRegistrationTable-td' key={filteredClassID.ClassID} value={filteredClassID.ClassID}>
                    {filteredClassID.firstname} {filteredClassID.lastname} 
                  </td>
                )}

                {/* <td className='CourseRegistrationTable-td'>{studentRegistration.classSchedule}</td> */}
            </tr>
        </tbody> 
         }
        </>
    );
};
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ParamUsername = queryParams.get('username');
  const ParamUserId = queryParams.get('User_id');

   const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseType, setCourseType] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [fee, setFee] = useState('');
  const [discount_, setDiscount_] = useState('');
  const [registrations, setRegistrations] = useState([]);

  const [discounts, setDiscounts] = useState([]);


  const courseTypes = ['One-on-One', 'Group'];

  const paymentTypes = ['Monthly', 'Upfront'];

  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [studentRegisteredCourses, setStudentRegisteredCourses] = useState([]);
 
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 useEffect(() => {
   const fetchData = async () => {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
       credentials: 'include',
     }).then(response => {
         console.log("Response:",response);
       response.json().then(userInfo => {
         console.log("userinfo:",userInfo);
         setUserInfo(userInfo);
         fetchAllCourses();
         fetchAllClasses();
        //  fetchStudentCourses();
        //  console.log(courses);

         if(userInfo?.usertype)
         {
           const uType=userInfo?.usertype;
           setUserRole(uType);
         }
       const uname = userInfo?.username;
       const avatar = userInfo?.avatar; // Store the avatar in a separate variable
       console.log("Uname Admin Register", uname);
      //  console.log("Avatar in: ", avatar);

       })
       .catch(err=> console.log("Error in useeffect:",err))
     });
   }
   fetchData();
 }, [isAdmin, isTeacher, isStudent, isGuest]);


 const [CourseIDs, setCourseIDs] = useState([]);
 const [ClassIds, setClassIDs] = useState([]);

 useEffect(() => {
    fetchStudentCourses(); 
      fetchDiscounts();
  }, []);

  useEffect(() => {
  fetchClassIDs(CourseIDs); 

  }, [studentRegisteredCourses]);
 const onLogin = (usertype) => {
         
  setUserRole(usertype);
};
  const navigate=useNavigate();

      async function onLogout() {
        console.log("inside Onlogout.");
      await  fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
          credentials: 'include',
          method: 'POST',
        });
        setUserInfo(null);
        console.log("navigating");
        navigate('/');
        // console.log("navigated");
        setUserRole('guest');
      }
      async function fetchDiscounts() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getDiscounts`);
             const discount=response.data.discounts;
             setDiscounts(discount);
          // return discounts;
        } catch (error) {
          console.error('Error fetching data from the API:', error);
          return [];
        }
      }
      
  const fetchAllCourses=()=>
  {
        // Fetch all courses
      axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourses`)
      .then(response => {
        const coursesData = response.data.courses;
        setCourses(coursesData);
      })
      .catch(error => {
        console.log('Error fetching courses:', error);
        // Handle the error as needed
      });
  }
  const fetchAllClasses=()=>{
    // Fetch all courses
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllClasses`)
    .then(response => {
      const classesData = response.data.classes;
      setClasses(classesData);
    })
    .catch(error => {
      console.log('Error fetching courses:', error);
      // Handle the error as needed
    });
}
    const fetchClassIDs = async (courseIds) => {
      try {
        // console.log("in fetchClassIDs,",courseIds)
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getClassIDs/${courseIds}`);
        const data = response.data;
        setClassIDs(data.classIDs);
      } catch (error) {
        console.log(error);
      }
    };
  const fetchStudentCourses=()=>{
    
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentRegisteredCourses/${ParamUserId}`)
    .then(response => {
      const data = response.data;
      // console.log(userData);
      // setStudentRegisteredCourses(userData.StudenRsgCourses);
      const formattedData = data.StudenRsgCourses.map(courseData => ({
        ...courseData,
        RegistrationDate: new Date(courseData.RegistrationDate).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
      }));
      setStudentRegisteredCourses(formattedData)
      setCourseIDs(data.uniqueCourseIDs);
      
      // Process the user data as needed
    })
    .catch(error => {
      console.log('Error fetching user:', error);
      // Handle the error as needed
});
  }
  const getClassById = (classId) => {
    const foundClass = classes.find((cls) => cls.Class_id === parseInt(classId));
    console.log("foundClass",foundClass)
    return foundClass ? foundClass.ClassID : '';
  };

  const handleCourseChange = (event) => {
    const { value } = event.target;
    const selectedCourse = courses.find((course) => course.Course_id === parseInt(value));
    setSelectedCourse(selectedCourse);
  };

  const handleCourseTypeChange = (event) => {
    const { value } = event.target;
    setCourseType(value);
  };

  const handlePaymentTypeChange = (event) => {
    const { value } = event.target;
    setPaymentType(value);
  };

  const handleClassChange = (event) => {
    const { value } = event.target;
    setSelectedClass(value);
  };

  const handleFeeChange = (event) => {
    const { value } = event.target;
    setFee(value);
  };

  const handleDiscountChange = (event) => {
    const { value } = event.target;
    const selectedDiscount = discounts.find((discount) => discount.Discount_id === parseInt(value));
    setDiscount_(selectedDiscount);

    // if (selectedDiscount) {
    //   const discountedFee = parseFloat(fee) * (1 - selectedDiscount.value);
    //   setFee(discountedFee.toFixed(2));
    // }
  };

  const handleRegistration = () => {
    const now = new Date();
    const newRegistration = {
      Course_id: selectedCourse ? selectedCourse.Course_id : '',
      courseType,
      paymentType,
      fee,
      discountID: discount_ ? discount_.Discount_id : '',
      class: getClassById(selectedClass), 
      registrationDate: now.toISOString().slice(0, 19).replace('T', ' '),
      // studentName: 'Ahmad',
      // teacherName: 'Inzamam',
      SchedulePreferenceDay: '',
      SchedulePreferenceTime: '',
      StatusComplete: 1,
    };
    // console.log("selectedClass:",selectedClass)
    // console.log(newRegistration);
    setRegistrations([...registrations, newRegistration]);

      // Make an Axios POST request to the API
  axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudent_Studentcourses/${ParamUserId}`, newRegistration)
  .then(response => {
    

    fetchStudentCourses();
  })
  .catch(error => {
    // Handle errors here
    console.error('API Error:', error);
  });
  };
  const username = userInfo?.username;

  return (
    <>
      {/* Navbar */}
      {username && (
       <>
       {/* <p>{username}</p> */}
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}
            {isTeacher && <TeacherNav   username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
      <div className='edit-bar' >
        <h3 className='edit-heading' style={{marginLeft:'0'}}>            

            <span>
                <span style={{fontSize:"5vh",marginRight:'25%',marginLeft:'2%'}}>
                {ParamUsername}
                </span>    
            </span>
            Course Registration 
        </h3>
      </div>
      {/* <form action="" onSubmit={handleRegistration}> */}
        <div className='CourseRegistrationInputs'>
          {/* Dropdowns and input fields */}
          <select className='ManageClassDropdown' 
          required
          value={selectedCourse ? selectedCourse.Course_id.toString() : ''} 
          onChange={handleCourseChange}>
            <option value=''>Select Course</option>
            {courses.map((course) => (
              <option key={course.Course_id} value={course.Course_id}>
                {course.coursename}
              </option>
            ))}
          </select>

          <select className='ManageClassDropdown'
          required
          value={courseType}
          onChange={handleCourseTypeChange}>
            <option value=''>Select Course Type</option>
            {courseTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select className='ManageClassDropdown' 
          required
          value={paymentType} 
          onChange={handlePaymentTypeChange}>
            <option value=''>Select Payment Type</option>
            {paymentTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select className='ManageClassDropdown' 
          required
          value={selectedClass} 
          onChange={handleClassChange}>
            <option value=''>Select Class</option>
            {selectedCourse &&
              classes
                .filter((cls) => cls.Course_id === parseInt(selectedCourse.Course_id))
                .map((cls) => (
                  <option key={cls.Class_id} value={cls.Class_id}>
                    {cls.ClassID}
                  </option>
                ))}
          </select>

          <input className='RegisterInCourseFeeTextField' type='text' required value={fee}
          onChange={handleFeeChange} placeholder='Fee(USD 450,PKR 24500 )' />

          <select className='ManageClassDropdown' 
          required
          value={discount_ ? discount_.Discount_id.toString() : ''} 
          onChange={handleDiscountChange}>
            <option value=''>Select Discount</option>
            {discounts.map((discount) => (
              <option key={discount.Discount_id} value={discount.Discount_id}>
                {discount.discountpercentage}%-{discount.discountname}
              </option>
            ))}
          </select>
      
        <div className='RegisterButtonDiv'>
          <button className='RegisterButton' type='submit' onClick={handleRegistration}>
            <b>
            Register {ParamUsername}
            </b>
          </button>
        </div>
        
        </div>
      {/* </form> */}
        <table className='CourseRegistrationTable'>
            <thead>
                <tr className='CourseRegistrationTable-tr'>
                    <th className='CourseRegistrationTable-th'>Student</th>
                    <th className='CourseRegistrationTable-th'>Course</th>
                    <th className='CourseRegistrationTable-th'>Registration Date</th>
                    {/* <th className='CourseRegistrationTable-th'>School</th> */}
                    <th className='CourseRegistrationTable-th'>Fee</th>
                    <th className='CourseRegistrationTable-th'>DiscountID</th>
                    <th className='CourseRegistrationTable-th'>Payment Type</th>
                    <th className='CourseRegistrationTable-th'>Class Type</th>
                    <th className='CourseRegistrationTable-th'>SchedulePreference Day & Time</th>
                    {/* <th className='CourseRegistrationTable-th'>SchedulePreferenceTime</th> */}
                    <th className='CourseRegistrationTable-th'>Class ID</th>
                    <th className='CourseRegistrationTable-th'>Teacher</th>
                    {/* <th className='CourseRegistrationTable-th'>Class Schedule</th> */}
                </tr>
            </thead>
        {studentRegisteredCourses.map((RegistrationCourse, index) => (
            <Registration key={index} RegistrationCourse={RegistrationCourse} classIDs={ClassIds}  />
            ))}
        </table>
      <Footer />
    </>
  );
};

export default AdminRegisterInCourse;
