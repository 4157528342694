import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./CPP.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

import pic1 from './CodingCourseImages/cpp.png'
import pic2 from './CodingCourseImages/billcalculator.png'
import pic3 from './CodingCourseImages/cricketstats.png'
import pic4 from './CodingCourseImages/banktransaction.png'
import pic5 from './CodingCourseImages/gradecalculator.png'
import pic6 from './CodingCourseImages/filehandlingCpp.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';

const CPP = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }  
        const username = userInfo?.username;
  return (
    <div >
    {/* Navbar */}
    {username && (
      <>
           {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

           {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
           {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
       </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
 
      <div className="CPP-Background">
         <section className="section">
           <p className="PageHead">C++ Programming Course</p>
           <div className="PageDesc">
             <p>
             Polymath-kids offer comprehensive online C++ programming course, a text-based programming language. The online coding classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.
             </p>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">

               {/* <div className="btn-row-resp"> */}
               <div style={{ width: "auto", height: "50px" }}>


                 <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
               </div>
              
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

               </div>
               <div style={{ width: "auto", height: "50px" }}>

                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                

               </div>


          
             </div>
           </div>

         </section>
         <div className="overlay"></div>
       </div>

        {/* Intro text */}
       <div className='scratch-background'>
       <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>What is C++ Programming?</p>
           <div className="PageDesc">
             <p style={{
                 color: "#8b0194",
                 marginTop: "2vh",
                 fontSize: "4vh",
                 fontFamily: "sans serif",
               }}>
              Since its inception, C++ has expanded significantly over time. The latest version (C++20) has object-oriented, functional, and generic features in addition to low-level memory manipulation. The biggest advantage of C++ is that it is super scalable and allows developers to have a lot of control over how their applications use up resources.
                <br /><br />

                1. Object-Oriented <br />
                C++ is an object-oriented programming language which means that the main focus is on objects and manipulations around these objects. This makes it much easier to manipulate code, unlike procedural or structured programming which requires a series of computational steps to be carried out.
                <br /><br />

                2. Speed <br />
                When speed is a critical metric, C++ is the most preferred choice. The compilation and execution time of a C++ program is much faster than most general-purpose programming languages.
                <br /><br />

                3. Compiled <br />
                Unlike other programming languages where no compilation is required, every C++ code has to be first compiled to a low-level language and then executed.
                <br /><br />

                4. Rich Library Support <br />
                The C++ Standard Template Library (STL) has many functions available to help write code quickly. For example, there are STLs for various containers like hash tables, maps, sets, etc.
                <br /><br />

                5. Pointer Support <br />
                C++ also supports pointers which are often not available in other programming languages. 
                <br /><br />
                6. Closer to Hardware <br />
                C++ is closer to hardware than most general-purpose programming languages. This makes it very useful in those areas where hardware and software are closely coupled together, and low-level support is needed at the software level.

             </p>    
           </div>
         </section>
       </div>
       
       {/* Course Structure and Syllabus */}
       <section className="section course-structure" id="course">
         <p className="PageHead" style={{ color: "#8b0194" }}>
           Course Structure and Syllabus
         </p>
         <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
               Course Objectives
             </b>
         </h4>
         <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
           <li>Learn the expertise of Computer Science using C++ Programming:</li>
             <p>          
             {'> '}Variables and data types, such as integers, floats, and strings.<br />
             {'> '}Conditions and operators, such as arithmetic, comparison, and logical operators. <br />
             {'> '}Fundamental building blocks of programming logic, loops, arrays & functions.<br />
             {'> '}Object-oriented programming<br />
             {'> '}Inheritance, Polymorphism, Encapsulation and file handling.<br />
             </p>
         <li>Develop 21st Century Skills using text based Programming:<br /></li>
             <p>          
             {'> '}Logic application and self-expression<br />
             {'> '}Creativity and Problem-Solving Skills<br />
             {'> '}Mathematical, Computational and Analytical Thinking<br />
             {'> '}Persistence<br />
             {'> '}Creativity and Imagination<br />
             {'> '}Digital Literacy<br />
             {'> '}Career-Building Skills<br />
             {'> '}Confidence and Communication Skills<br />
             {'> '}STEM Education<br />
             </p>
         </ul>
         
         <div>
           <p className='moduleSection' >
               <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
               C++ Course
           </p>
         </div>

         {/* Module one */}
         <div className="modules">
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               1
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
             Fundamentals of C++
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                Students will learn the basics of the C++ language. They will learn about variables and data types, including integers, floats, and strings. They will also learn about conditions and operators such as arithmetic, comparison, and logical operators. Overall, this module will provide students with a solid foundation in C++ programming .
               </p>
             </p>
           </div>
         </div>

         {/* Module  two */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               2
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             C++ Logics
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                In this module, students will learn about the fundamental building blocks of programming logic such as loops, arrays, and functions. They will learn how to use loops i.e. for and while to perform repetitive tasks, how to work with arrays to store and manipulate large amounts of data, and how to create reusable code with functions. 
               </p>
              
             </p>
           </div>
         </div>

         {/* Module  Three */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               3
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Object Oriented Programming
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >         
                Students will learn about classes and objects, the building blocks of object-oriented programming, and how to use them to organize their code and data. Additionally, students will learn inheritance, Polymorphism and Encapsulation. They will also learn file handling, which enables them to read from and write to files on their computer. 
               </p>
              
             </p>
           </div>
         </div>
         <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
             Learn C++ Programming with 30+ Fun Activities
             </b>
         </h6>

        <div className="activityImagesContainer">
        <div class="row">
          <div class="col-lg mx-3">
            <img src={pic2} alt="" className='ActivityImages'/>
            <p className='mx-3'>Bill Calculator</p>
          </div>
          <div class="col-lg mx-3">
            <img src={pic3} alt="" className='ActivityImages'/>
            <p className='mx-3'>Cricket Statistics</p>
          </div>
          <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className='mx-3'>Bank Transactions</p>
          </div>
          <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className='mx-3'>Grade Calculator</p>
          </div>
          <div class="col-lg mx-3">
            <img src={pic6} alt=""  className='ActivityImages'/>
            <p className='mx-3'>File Handling</p>
          </div>
          
        </div>
        </div>
      
      

       </section>

        {/* Fee Structure */}
        <FeeStructure subject="Coding"/>

       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>

                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>

       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q: What is C++ programming language, and why should I learn it?" >
               <strong>A: </strong>
               C++ is a powerful programming language used in a wide range of applications, including software development, video game design, and more. It's highly versatile and efficient, making it an ideal choice for a variety of programming tasks. Learning C++ can help you become a more skilled and employable programmer.
             </MDBAccordionItem>
             <MDBAccordionItem collapseId={2} headerTitle="Q: Do I need prior programming experience to learn C++?">
               <strong>A: </strong>
               While prior programming experience can be helpful, however it's not a pre-requisite to learn C++. Our course is designed to be accessible to beginners and experts alike. We provide clear and concise explanations of programming concepts and offer hands-on practice to reinforce learning.
             </MDBAccordionItem>


             <MDBAccordionItem collapseId={3} headerTitle="Q: What topics are covered in the Polymath-Kids C++ course?">
               <strong>A: </strong>
               Our C++ course covers a wide range of topics, including variables, data types, operators, control structures, functions, classes, templates, and more. We start with the basics and gradually build up to more advanced concepts and techniques.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: How is the C++ course taught at Polymath-Kids?">
               <strong>A: </strong>
               Our C++ course is taught through a combination of online live video lectures, interactive coding exercises, projects and quizzes. You'll be able to learn at your own pace and track your progress throughout the course.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: What kind of support is available during the course?">
               <strong>A: </strong>
               We offer live online classes and all the helping material will be provided on our online portal (LMS).
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: Will I receive a certificate upon completion of the course?">
               <strong>A: </strong>
               Yes, we offer a certificate (STEM.org accreditated) of completion for our C++ course. To earn the certificate, you'll need to complete all of the required assignments and quizzes and achieve a passing score on the final exam. 
             </MDBAccordionItem>
             <MDBAccordionItem collapseId={3} headerTitle="Q:What are the learning goals of the C++ programming course for kids?">
               <strong>A: </strong>
               Polymath-Kids offer C++ Programming Course for Ages 12 to 18. By the end of the course, students would have mastered the essential principles of coding such as variables, data types, operators, control structures, functions, classes, templates, and more with the help of several activities and Projects. 
                The course design facilitates the young brains by learning coding along with other important skills such as problem solving, logic, creativity and storytelling. 
             </MDBAccordionItem>
           </MDBAccordion>
         </MDBContainer>

       </div>

        {/* Footer */}
        <Footer/>

   </div>
  )
}

export default CPP
