import React,{useContext,useEffect} from "react";


import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav' 
import GuestNav from "../Navbars/GuestNav";

import { UserContext } from '../UserContext';
import Footer from '../Footer'
import "./CorelDraw.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import pic1 from "./GraphicCourseImages/corelDraw.png";
import pic2 from "./GraphicCourseImages/card.png";
import pic3 from "./GraphicCourseImages/flyers.png";
import pic4 from "./GraphicCourseImages/bookCover.png";
import pic5 from "./GraphicCourseImages/brochures.png";
import pic6 from "./GraphicCourseImages/logos.png";
import TeacherNav from "../Navbars/TeacherNav";
import FeeStructure from "../components/FeeStructure";
const CorelDraw = ({selectedCourse}) => {
  
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
    }
      
      const username = userInfo?.username;
   
  return (
    <div >
      
     {/* Navbar */}
     {username && (
       <>
            {isAdmin && <AdminNav  username={username} onLogout={onLogout} />}

            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} onLogout={onLogout} />}
        </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 


    <div className="CorelPortalBackground">
      <section className="section">
        <p className="PageHead">Corel Draw Course</p>
        <div className="PageDesc">
          <p>
          CorelDRAW is a Vector graphic software, allowing the user to produce outstanding Illustrations with millions of colours. To help kids deliver their message creatively and develop aesthetic sense, Polymath-Kids offers a Graphic Design with CorelDRAW course for kids age 9 and above. Kids will learn to pay attention to details, develop organizational and observational skills and create meaningful and impeccable designs.
          </p>
          <div className="btn-row d-flex btn-row-resp btn-row-resp1">
            {/* <div className="btn-row-resp"> */}
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className="btn-row-resp button btn-color-course"
                href="#course"
              >
                Course Structure
              </a>
            </div>

            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-fee" href="#fee">
                Fee Structure
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-Testimonal"
                href="#testimonal"
              >
                Testimonals
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                Faqs
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-registered"
                href="#"
              >
                Get Registered
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="overlay"></div>
    </div>

    {/* Intro text */}
    <div className="scratch-background">
      <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}>
        What does Polymath-Kids Corel Draw course Offers?
        </p>
        <div className="PageDesc">
          <p
            style={{
              color: "#8b0194",
              marginTop: "2vh",
              fontSize: "4vh",
              fontFamily: "sans serif",
            }}
          >
           CorelDraw is used primarily for marketing and advertising businesses. Kids can use these skills to create extraordinary real life projects. With the help of expert instructors, Polymath-Kids Corel Draw Course help children learn the fundamentals of graphic design in online live classes. They will learn to create logos, brochures, newsletters, business cards, certificates, 3D tables and Illustrations from scratch. These skills will help them ace in school and college projects and develop ideation, observation and visualization skills. 
          </p>
        </div>
      </section>
    </div>

    {/* Course Structure and Syllabus */}
    <section className="section course-structure" id="course">
      <p className="PageHead" style={{ color: "#8b0194" }}>
        Course Structure and Syllabus
      </p>
      <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Course Objectives</b>
      </h4>
      <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <li>
      Fundamentals of Graphic Designing
        </li>
        <li>
	Elements and principles of design
        </li>
        <li>
	Basic and Advance features of Corel Draw
        </li>
        <li>
	Color tools and 3D tables
        </li>
        <li>
	Object and effect menu
        </li>
        <li>
	Logo, certificate and Flex design
        </li>
      </ul>

      <div>
        <p className='moduleSection'>
          <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
          Graphic Designing in Corel Draw
        </p>
      </div>

      {/* Module one */}
      <div className="modules">
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            1
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box sm-box" 
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
          Introduction To Graphic Designing
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
                This module introduces the fundamentals of graphic designing such as elements and principles of design. Students will also learn how to search appropriate images and file formats and will also discover online market places for graphic designers
            </p>
          </p>
        </div>
      </div>

      {/* Module  two */}
      <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            2
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Corel Draw Basics
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              In this module, students will learn the basic features of the graphic designing platform Corel Draw. They will learn to use the tools used to create a smooth, smear, twirl, repel or smudge effect in an image. They will also learn the effective use of text tools, connectors and drop shadows.
            </p>
          </p>
        </div>
      </div>

       {/* Module  Three */}
       <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
           3
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Corel Draw Advanced
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              This module help develop further expertise in advanced features in Corel Draw. Students will learn to use color tools, object and effect menu. They will also develop expertise required to create logo designs, visiting cards, certificates and flex designs.
            </p>
          </p>
        </div>
      </div>

      

     

      <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Learn Graphic Designing in Corel Draw with 40+ Fun Activities</b>
      </h6>

      <div className="activityImagesContainer">
        <div className="row">
          <div class="col-lg mx-3">
            <img src={pic2} alt="" className='ActivityImages'/>
            <p className="mx-3">Business Cards</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic3} alt="" className='ActivityImages'/>
            <p className="mx-3">Flyers</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic4} alt="" className='ActivityImages'/>
            <p className="mx-3">Book Covers</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic5} alt="" className='ActivityImages'/>
            <p className="mx-3">Brochures</p>
          </div>

          <div class="col-lg mx-3">
            <img src={pic6} alt="" className='ActivityImages'/>
            <p className="mx-3">Logos</p>
          </div>

        </div>
      </div>
    </section>

    {/* Fee Structure */}
    <FeeStructure subject="Corel Draw"/>
   
    {/* Testimonals */}
    <div class="  background" id="testimonal">
      <div class="row" style={{ width: "100%" }}>
        <div class="col-lg-8 mx-auto">
          <h2 className="Testimonal_Heading">Testimonials</h2>
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            {/* <!-- Carousel indicators --> */}
            <ol class="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
            </ol>
            {/* <!-- Wrapper for carousel items --> */}
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img-box">
                  <div className=" img-box1"></div>
                </div>
                <p
                  class="testimonial"
                  style={{ color: "black", fontSize: "3vh" }}
                >
                  I enjoyed the learning experience with EduTechSage-Abacus.
                  The teacher was very friendly. I highly recommend
                  EduTechSage-Abacus.
                </p>
                <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                  <b>Mujtaba Khawaja Sahaf</b>
                </p>
              </div>

              <div class="carousel-item">
                <div class="img-box">
                  <div className="img-box2 "></div>
                </div>
                <p
                  class="testimonial"
                  style={{ color: "black", fontSize: "3vh" }}
                >
                  I feel so proud when my class fellows call me ’BRAINIAC ’,
                  because I can effortlessly do mental calculations much
                  faster and accurate than my class mates. Thank you
                  Polymath-Kids.
                </p>
                <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                  <b>Wahaab Omer</b>, Age 9 Years
                </p>
              </div>
            </div>
            {/* <!-- Carousel controls --> */}
            <a
              class="carousel-control-prev"
              href="#myCarousel"
              data-slide="prev"
            >
              <i class="fa fa-angle-left"></i>
            </a>
            <a
              class="carousel-control-next"
              href="#myCarousel"
              data-slide="next"
            >
              <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Faqs */}
    <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>
            {" "}
            FAQs
          </div>
          <MDBAccordionItem
            collapseId={1}
            headerTitle="Q: Can kids actually learn graphic design? "
          >
            <strong>A: </strong>
            Yes, kids can learn graphic design by learning the main concepts of design theory and getting started with the basics of graphic design software appropriate for their age. In the age of Instagram stories, memes and YouTube videos, kids are eager to create their own graphics. Polymath-Kids offer graphic designing courses for all age groups to help them unlock their creativity and design artistic content.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="Q: What is the difference between UI/ UX?"
          >
            <strong>A: </strong>
            UI and UX are two different but related concepts. UI, or “user interface,” is what the user sees on a web page or screen. This includes buttons location, length or paragraphs on a page, and color schemes, along with other things. These elements are important to leave a lasting impact on users.

              UX, or “user experience,” is about how a user interacts with the page or application. This is less about what the page looks like and more about how the page is used. A UX designer will decide how a website is navigated, and their final goal is to make the navigation and use of the site as convenient as possible. There is some overlap between these two forms of design, but they require different skills and ways of thinking.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Why is it important to teach design concepts to kids?"
          >
            <strong>A: </strong>
            Teaching design concepts to kids is not just about teaching them how to make things look beautiful. Graphic Designing skills help to teach creative ways of thinking, which is essential in today’s world. Any person can learn the answer to a question and repeat it back, but innovation requires creative minds that can see solutions not perceived by others. 

Learning design is a step forward toward developing a creative mind, as a creative endeavor is, in some ways similar to a puzzle. There’s an idea in your mind, and you’re trying to solve the puzzle of how to bring your idea to others in the form of images and fonts to communicate the whole story. This level of focus improves a child’s problem-solving skills and creativity.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: What is graphic designing?"
          >
            <strong>A: </strong>
            Graphic design is the art and practice of planning and projecting ideas and experiences using visual elements and textual content. These visual elements include images, symbols, typography (letter design) and colors, used to convey certain messages and information in an appealing and attractive manner. Graphic designing is also termed as Visual Communications.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Why is graphic design important for kids?"
          >
            <strong>A: </strong>
            Kids are naturally interested in colors and drawings. Graphic Designing is like a puzzle that needs creativity and problem solving skills to come up with an attractive and appealing visual. Learning to design at an early age provides an opportunity for kids to develop artistry, creativity, problem solving, business and branding skills.
          </MDBAccordionItem>

          <MDBAccordionItem
           collapseId={3}
           headerTitle="Q: How will Graphic Designing benefit Kids?"
         >
           <strong>A: </strong>
           Polymath-Kids Graphic design courses enable kids to
           <li>
  Design their very own business card and logo or design for others
           </li>
           <li>
  Design book covers
           </li>
           <li>
  Create impeccable school and personal projects
           </li>
           <li>
  Learn a variety of design skills and tools
           </li>
           <li>
  Create attractive CVs
           </li>
           <li>
  Design personalized social media avatars and stickers
           </li>
           <li>
  Learn to draw their favorite characters and get it printed on t-shirts or mugs
           </li>
           
           <li>
  Earn online through graphic designing skill
           </li>
         </MDBAccordionItem>
        
         <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Can kids do graphic designing?"
          >
            <strong>A: </strong>
            Yes, a kid can be an excellent graphic designer! To be a graphic designer you need to get inspiration from the world around you. Kids have the natural capability to see the world in an innovative way, which help them relate their ideas in a creative manner and bring to life using graphic designing tools.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: What age can you start graphic design?"
          >
            <strong>A: </strong>
            Graphic design is not a field limited to adults. Anyone having a passion for color, design or sketching can learn graphic design. The availability of easy to use platform makes it possible for kids as young as 6 years to start learning graphic designing, with the help of trained instructors. Polymath-Kids offer a range of graphic design courses suitable for ages 6 onwards. Our well trained instructors ensure to keep the classes lively and colorful to help kids achieve maximum benefit.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: How do kids get into graphic design?"
          >
            <strong>A: </strong>
            Kids can take Polymath-Kids structured graphic Design classes designed specifically for children aged 6 onwards. Our well trained instructors ensure to keep the classes lively and colorful to help kids achieve maximum benefit.
          </MDBAccordionItem>

          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Which is the best graphic designing course for kids?"
          >
            <strong>A: </strong>
            Polymath-Kids offer the best graphic design courses for kids aged 6 onwards. Our comprehensive courses are specifically designed for kids to ensure they grasp the concepts in the live classes with expert instructors. Polymath-Kids offers graphic design courses for kids in featured packed, powerful platforms such as Canva, Adobe XD, Photoshop, Adobe Illustrator and Corel Draw. 
          </MDBAccordionItem>

       </MDBAccordion>
      </MDBContainer>
    </div>
    
{/* Footer */}
<Footer/>

  </div>
  )
}

export default CorelDraw
