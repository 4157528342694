import React, { useState,useEffect } from 'react';
import axiosInstance from './axiosInstance';
import './ManageLessonActivities.css';

const ManageLessonActivities = (props) => {
  const [activities, setActivities] = useState([
    { Lesson_Activity_id:'1',ActivityName: 'Activity 1', ActivityDescription: 'Description 1' }
  ]);

  const lesson = props.lessonid;

  useEffect(() => {
    fetchLessonActivities();
  }, []);

  const fetchLessonActivities = () => {
    axiosInstance.post('/getLessonActivities', { lesson })
      .then(response => {
        setActivities(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [isAssignFormOpen, setIsAssignFormOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');

  const [newActivityName, setNewActivityName] = useState('');
  const [newActivityDescription, setNewActivityDescription] = useState('');

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance.post('/getAllCourses')
      .then(response => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance.post('/getAllModule', { courseId })
      .then(response => {
        const filteredModules = response.data.filter(module => module.Course_id ===  parseInt(courseId, 10));
        setModules(filteredModules);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fetchLessons = (moduleId) => {
    axiosInstance.post('/getAllLesson', { moduleId })
      .then(response => {
        const filteredLessons = response.data.filter(lesson => lesson.Module_id === parseInt(moduleId));
        setLessons(filteredLessons);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCourseSelect = (courseId) => {
    setCourses(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModules(moduleId);
    fetchLessons(moduleId);
  };

  const handleLessonSelect = (lessonId) => {
    setLessons(lessonId);
  };

  const handleEdit = (activityId) => {
    const activityToEdit = activities.find((activity) => activity.Lesson_Activity_id === activityId);
    if (activityToEdit) {
      setNewActivityName(activityToEdit.name);
      setNewActivityDescription(activityToEdit.description);
      setSelectedActivity(activityId);
      setSelectedCourse('');
      setSelectedModule('');
      setSelectedLesson('');
      setIsAssignFormOpen(true);
    }
  };

  const handleAdd = () => {
    setSelectedActivity(null);
    setIsAssignFormOpen(true);
  };

  const handleDelete = (activityId) => {
    setActivities(activities.filter((activity) => activity.Lesson_Activity_id !== activityId));
  };

  const handleAssign = (activityId) => {
    setSelectedActivity(activityId);
    setSelectedCourse(''); // Clear the selected course
    setSelectedModule(''); // Clear the selected module
    setSelectedLesson(''); // Clear the selected lesson
    setIsAssignFormOpen(true);
  };

  const handleSaveAssign = () => {
    // Handle save assign functionality
    setIsAssignFormOpen(false);
    setSelectedActivity(null);
    setSelectedCourse('');
    setSelectedModule('');
    setSelectedLesson('');
  };

  const handleSaveActivity = () => {
    if (selectedActivity) {
      // Edit existing activity
      setActivities((prevActivities) =>
        prevActivities.map((activity) => {
          if (activity.Lesson_Activity_id === selectedActivity) {
            return {
              ...activity,
              name: newActivityName,
              description: newActivityDescription
            };
          }
          return activity;
        })
      );
    } else {
      // Add new activity
      const newActivity = {
        id: Math.floor(Math.random() * 1000),
        name: newActivityName,
        description: newActivityDescription
      };
      setActivities((prevActivities) => [...prevActivities, newActivity]);
    }

    setIsAssignFormOpen(false);
    setSelectedActivity(null);
    setNewActivityName('');
    setNewActivityDescription('');
  };

  return (
    <div className="manage-lesson-activities">
      <h2>Manage Lesson Activities</h2>
      <div className="activity-list">
        {activities.map((activity) => (
          <div className="activity-card" key={activity.Lesson_Activity_id}>
            <h3>{activity.ActivityName}</h3>
            <p>{activity.ActivityDescription}</p>
            <div className="buttonsinz">
              <button className="edit-button" onClick={() => handleEdit(activity.Lesson_Activity_id)}>
                Edit
              </button>
              <button className="delete-button" onClick={() => handleDelete(activity.Lesson_Activity_id)}>
                Delete
              </button>
              <button className="assign-button" onClick={() => handleAssign(activity.Lesson_Activity_id)}>
                Assign
              </button>
            </div>
          </div>
        ))}
      </div>
      {isAssignFormOpen && (
        <div className="assign-form">
          <h3>{selectedActivity ? 'Edit Activity' : 'Add Activity'}</h3>
          <form>
            <div className="form-group">
              <label htmlFor="activityName" className="custom-label">
                Name:
              </label>
              <input
                type="text"
                id="activityName"
                className="custom-input"
                value={newActivityName}
                onChange={(e) => setNewActivityName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="activityDescription" className="custom-label">
                Description:
              </label>
              <textarea
                id="activityDescription"
                className="custom-textarea"
                value={newActivityDescription}
                onChange={(e) => setNewActivityDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="courseDropdown" className="custom-label">
                Course:
              </label>
              <select
                id="courseDropdown"
                className="custom-select"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
              >
                <option value="">Select Course</option>
                {courses.map((course) => (
                  <option key={course.id} value={course.Course_id}>
                    {course.coursename}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="moduleDropdown" className="custom-label">
                Module:
              </label>
              <select
                id="moduleDropdown"
                className="custom-select"
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.target.value)}
              >
                <option value="">Select Module</option>
                {modules.map((module) => (
                  <option key={module.id} value={module.Module_id}>
                    {module.modulename}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="lessonDropdown" className="custom-label">
                Lesson:
              </label>
              <select
                id="lessonDropdown"
                className="custom-select"
                value={selectedLesson}
                onChange={(e) => setSelectedLesson(e.target.value)}
              >
                <option value="">Select Lesson</option>
                {lessons.map((lesson) => (
                  <option key={lesson.id} value={lesson.Lesson_id}>
                    {lesson.lessonname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-actions">
              <button type="button" className="custom-aqua-button" onClick={handleSaveAssign}>
                Save
              </button>
              <button type="button" className="custom-aqua-button" onClick={() => setIsAssignFormOpen(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <button className="add-button" onClick={handleAdd}>
        Add Activity
      </button>
    </div>
  );

};

export default ManageLessonActivities;
