import React from 'react'
import './EditProfile.css'
// import StudentNav from '../StudentNav'
import Footer from '../Footer'
import EditProfile from './EditProfile'

const AdminEdit = () =>
{
  return(
   <> 

      {/* Edit Profile of Admin */}
      <EditProfile/>

 
    </>
  )
}

export default AdminEdit
